import { EDiscFilterModels, EFiltrationTechnologyOptions, EMediaFilterModels, EScreenFilterModels } from "../../enums";

const { DISC_FILTERS, MEDIA_FILTER, SCREEN_FILTERS } = EFiltrationTechnologyOptions;
const { SANDSTORM_20, SANDSTORM_24, SANDSTORM_30, SANDSTORM_36, SANDSTORM_48, } = EMediaFilterModels;
const { SPINKLIN_2, SPINKLIN_3, APOLLO_ANGLE, APOLLO_TWINS, ALPHA_DISC_SINGLE_3, ALPHA_DISC_SINGLE_XL_3, ALPHA_DISC_SINGLE_XL_4, ALPHA_DISC_DUAL_XL, ALPHA_DISC_TRIO_XL } = EDiscFilterModels;
const { VERTICAL_2, VERTICAL_3, VERTICAL_3_JUMBO, VERTICAL_4, VERTICAL_4_JUMBO, VERTICAL_6, HORIZONTAL_4, HORIZONTAL_4_JUMBO, HORIZONTAL_6, HORIZONTAL_6_JUMBO, HORIZONTAL_8, HORIZONTAL_10 } = EScreenFilterModels;

export interface IDrawingPdfKeys {
    tech: "Media" | "Disc" | "Screen",
    model: string,
    type: string,
    subType?: string,
}

const mediaDrawingPdfKeys: { [model: number]: IDrawingPdfKeys } =
{
    [SANDSTORM_20]: { tech:"Media" ,model: 'SandStorm', type: '20' },
    [SANDSTORM_24]: { tech:"Media" ,model: 'SandStorm', type: '24' },
    [SANDSTORM_30]: { tech:"Media" ,model: 'SandStorm', type: '30' },
    [SANDSTORM_36]: { tech:"Media" ,model: 'SandStorm', type: '36' },
    [SANDSTORM_48]: { tech:"Media" ,model: 'SandStorm', type: '48' },
};

const discDrawingPdfKeys: { [model: number]: IDrawingPdfKeys } =
{
    [SPINKLIN_2]:             { tech:"Disc" ,model: 'SpinKlin',  type: '2'     },
    [SPINKLIN_3]:             { tech:"Disc" ,model: 'SpinKlin',  type: '3'     },
    [APOLLO_ANGLE]:           { tech:"Disc" ,model: 'Apollo',    type: 'Angle' },
    [APOLLO_TWINS]:           { tech:"Disc" ,model: 'Apollo',    type: 'Twins' },
    [ALPHA_DISC_SINGLE_3]:    { tech:"Disc" ,model: 'AlphaDisc', type: 'Single', subType: "3" },
    [ALPHA_DISC_SINGLE_XL_3]: { tech:"Disc" ,model: 'AlphaDisc', type: 'Single', subType: "XL3" },
    [ALPHA_DISC_SINGLE_XL_4]: { tech:"Disc" ,model: 'AlphaDisc', type: 'Single', subType: "XL4" },
    [ALPHA_DISC_DUAL_XL]:     { tech:"Disc" ,model: 'AlphaDisc', type: 'Dual',   subType: "XL" },
    [ALPHA_DISC_TRIO_XL]:     { tech:"Disc" ,model: 'AlphaDisc', type: 'Trio',   subType: "XL" },
};

const screenDrawingPdfKeys: { [model: number]: IDrawingPdfKeys } =
{
    [VERTICAL_2]:         { tech:"Screen" ,model: 'ScreenGuard', type: 'Vertical',   subType: "2" },
    [VERTICAL_3]:         { tech:"Screen" ,model: 'ScreenGuard', type: 'Vertical',   subType: "3" },
    [VERTICAL_3_JUMBO]:   { tech:"Screen" ,model: 'ScreenGuard', type: 'Vertical',   subType: "3Jumbo" },
    [VERTICAL_4]:         { tech:"Screen" ,model: 'ScreenGuard', type: 'Vertical',   subType: "4" },
    [VERTICAL_4_JUMBO]:   { tech:"Screen" ,model: 'ScreenGuard', type: 'Vertical',   subType: "4Jumbo" },
    [VERTICAL_6]:         { tech:"Screen" ,model: 'ScreenGuard', type: 'Vertical',   subType: "6" },
    [HORIZONTAL_4]:       { tech:"Screen" ,model: 'ScreenGuard', type: 'Horizontal', subType: "4" },
    [HORIZONTAL_4_JUMBO]: { tech:"Screen" ,model: 'ScreenGuard', type: 'Horizontal', subType: "4Jumbo" },
    [HORIZONTAL_6]:       { tech:"Screen" ,model: 'ScreenGuard', type: 'Horizontal', subType: "6" },
    [HORIZONTAL_6_JUMBO]: { tech:"Screen" ,model: 'ScreenGuard', type: 'Horizontal', subType: "6Jumbo" },
    [HORIZONTAL_8]:       { tech:"Screen" ,model: 'ScreenGuard', type: 'Horizontal', subType: "8" },
    [HORIZONTAL_10]:      { tech:"Screen" ,model: 'ScreenGuard', type: 'Horizontal', subType: "10" },
};

export const drawingPdfKeys: { [filtrationTechnologyOptions: number]: { [model: number]: IDrawingPdfKeys } } = {
    [MEDIA_FILTER]: mediaDrawingPdfKeys,
    [DISC_FILTERS]: discDrawingPdfKeys,
    [SCREEN_FILTERS]: screenDrawingPdfKeys
}