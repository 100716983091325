import { useSelector } from "react-redux";
import styles from "../../assets/styles/components/pages/welcome.module.scss";
import { COOKIES_LABEL, WELCOME_BUTTON, WELCOME_CONTENT_P_1, WELCOME_CONTENT_P_2, WELCOME_TITLE_LINE_1 } from "../../constats/languages/languagesKeys";
import { useSetLanguage } from "../../services/settingsService";
import { RootState } from "../../store";
import MyButton from "../generic/MyButton";
import MyPopup from "../generic/MyPopup";
import MySelect from "../generic/MySelect";
import logo from "../../assets/images/icons/logo-netafim.svg"
import welcome from "../../assets/images/icons/welcome-filterCongif-icon.svg"
type props = {
    onFinish: () => void;
}

const Welcome = ({ onFinish }: props) => {

    const setLanguage = useSetLanguage();
    const { language, currentIndex, languagesOptions } = useSelector((state: RootState) => state.settingsReducer);

    return (
        <main className={styles["welcome-container"]}>
            <div className={styles["hero-image"]}>
            <img src={logo} className={styles["logo-image"]} alt="logo Netafim" />
            <img src={welcome} className={styles["welcome-image"]} alt="welcome Netafim" />
                <div className={styles["language-picker"]}>
                    <MySelect currentIndex={currentIndex} setField={setLanguage} options={languagesOptions} isSearchable={false} />
                </div>
            </div>
            <div className={styles["homepage-contentainer"]}>
                <div className={styles["homepage-content"]}>
                    <div className={styles["language-picker"]}>
                        <MySelect currentIndex={currentIndex} setField={setLanguage} options={languagesOptions} isSearchable={false} />
                    </div>
                    <h1>{language[WELCOME_TITLE_LINE_1]}</h1>
                    <p >{language[WELCOME_CONTENT_P_1]}</p>
                    <p>{language[WELCOME_CONTENT_P_2]}</p>
                </div>                
                <MyButton label={language[WELCOME_BUTTON]} onClick={onFinish} width="max" widthTablet="large" shadow="grey" />
            </div>
        </main>
    );
}

export default Welcome;