import { DISC_FILTERS_TITLE, DISC_FILTER_TEXT, MEDIA_FILTER_TEXT, MEDIA_FILTER_TITLE, MODEL_LINK_ALPHA, MODEL_LINK_ALPHA_DESKTOP, MODEL_LINK_APOLLO, MODEL_LINK_APOLLO_DESKTOP, MODEL_LINK_SANDSTORM, MODEL_LINK_SANDSTORM_DESKTOP, MODEL_LINK_SCREENGUARD, MODEL_LINK_SCREENGUARD_DESKTOP, MODEL_LINK_SPINKLIN, MODEL_LINK_SPINKLIN_DESKTOP, SCREEN_FILTERS_TITLE, SCREEN_FILTER_TEXT } from "./languagesKeys";
import { EDiscFilterModels, EFiltrationTechnologyOptions, EMediaFilterModels, EScreenFilterModels } from "../../enums";


const { DISC_FILTERS, MEDIA_FILTER, SCREEN_FILTERS } = EFiltrationTechnologyOptions;
const { SANDSTORM_20, SANDSTORM_24, SANDSTORM_30, SANDSTORM_36, SANDSTORM_48, } = EMediaFilterModels;
const { SPINKLIN_2, SPINKLIN_3, APOLLO_ANGLE, APOLLO_TWINS, ALPHA_DISC_SINGLE_3, ALPHA_DISC_SINGLE_XL_3, ALPHA_DISC_SINGLE_XL_4, ALPHA_DISC_DUAL_XL, ALPHA_DISC_TRIO_XL } = EDiscFilterModels;
const { VERTICAL_2, VERTICAL_3, VERTICAL_3_JUMBO, VERTICAL_4, VERTICAL_4_JUMBO, VERTICAL_6, HORIZONTAL_4, HORIZONTAL_4_JUMBO, HORIZONTAL_6, HORIZONTAL_6_JUMBO, HORIZONTAL_8, HORIZONTAL_10 } = EScreenFilterModels;

export interface IUnitModelAndDiameter {
    unit: string,
    modelDdiameter: string,
    learnMoreLinkMessageKeyMobile: number,
    learnMoreLinkMessageKeyDesktop: number
}

const mediaFiltersUnitModelAndDiameter: { [model: number]: IUnitModelAndDiameter } =
{
    [SANDSTORM_20]: { unit: 'SandStorm™', modelDdiameter: '20"', learnMoreLinkMessageKeyMobile: MODEL_LINK_SANDSTORM, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SANDSTORM_DESKTOP },
    [SANDSTORM_24]: { unit: 'SandStorm™', modelDdiameter: '24"', learnMoreLinkMessageKeyMobile: MODEL_LINK_SANDSTORM, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SANDSTORM_DESKTOP },
    [SANDSTORM_30]: { unit: 'SandStorm™', modelDdiameter: '30"', learnMoreLinkMessageKeyMobile: MODEL_LINK_SANDSTORM, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SANDSTORM_DESKTOP },
    [SANDSTORM_36]: { unit: 'SandStorm™', modelDdiameter: '36"', learnMoreLinkMessageKeyMobile: MODEL_LINK_SANDSTORM, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SANDSTORM_DESKTOP },
    [SANDSTORM_48]: { unit: 'SandStorm™', modelDdiameter: '48"', learnMoreLinkMessageKeyMobile: MODEL_LINK_SANDSTORM, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SANDSTORM_DESKTOP },
};

const discFiltersUnitModelAndDiameter: { [model: number]: IUnitModelAndDiameter } =
{
    [SPINKLIN_2]:             { unit: 'SpinKlin™',  modelDdiameter: '2"',           learnMoreLinkMessageKeyMobile: MODEL_LINK_SPINKLIN, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SPINKLIN_DESKTOP },
    [SPINKLIN_3]:             { unit: 'SpinKlin™',  modelDdiameter: '3"',           learnMoreLinkMessageKeyMobile: MODEL_LINK_SPINKLIN, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SPINKLIN_DESKTOP },
    [APOLLO_ANGLE]:           { unit: 'Apollo™',    modelDdiameter: 'Angle',        learnMoreLinkMessageKeyMobile: MODEL_LINK_APOLLO,   learnMoreLinkMessageKeyDesktop: MODEL_LINK_APOLLO_DESKTOP },
    [APOLLO_TWINS]:           { unit: 'Apollo™',    modelDdiameter: 'Twins',        learnMoreLinkMessageKeyMobile: MODEL_LINK_APOLLO,   learnMoreLinkMessageKeyDesktop: MODEL_LINK_APOLLO_DESKTOP },
    [ALPHA_DISC_SINGLE_3]:    { unit: 'AlphaDisc™', modelDdiameter: 'Single',       learnMoreLinkMessageKeyMobile: MODEL_LINK_ALPHA,    learnMoreLinkMessageKeyDesktop: MODEL_LINK_ALPHA_DESKTOP },
    [ALPHA_DISC_SINGLE_XL_3]: { unit: 'AlphaDisc™', modelDdiameter: 'Single XL 3"', learnMoreLinkMessageKeyMobile: MODEL_LINK_ALPHA,    learnMoreLinkMessageKeyDesktop: MODEL_LINK_ALPHA_DESKTOP },
    [ALPHA_DISC_SINGLE_XL_4]: { unit: 'AlphaDisc™', modelDdiameter: 'Single XL 4"', learnMoreLinkMessageKeyMobile: MODEL_LINK_ALPHA,    learnMoreLinkMessageKeyDesktop: MODEL_LINK_ALPHA_DESKTOP },
    [ALPHA_DISC_DUAL_XL]:     { unit: 'AlphaDisc™', modelDdiameter: 'Dual XL',      learnMoreLinkMessageKeyMobile: MODEL_LINK_ALPHA,    learnMoreLinkMessageKeyDesktop: MODEL_LINK_ALPHA_DESKTOP },
    [ALPHA_DISC_TRIO_XL]:     { unit: 'AlphaDisc™', modelDdiameter: 'Trio XL',      learnMoreLinkMessageKeyMobile: MODEL_LINK_ALPHA,    learnMoreLinkMessageKeyDesktop: MODEL_LINK_ALPHA_DESKTOP },
};

const screenFiltersUnitModelAndDiameter: { [model: number]: IUnitModelAndDiameter } =
{
    [VERTICAL_2]:         { unit: 'ScreenGuard™', modelDdiameter: 'Vertical 2"',         learnMoreLinkMessageKeyMobile: MODEL_LINK_SCREENGUARD, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SCREENGUARD_DESKTOP },
    [VERTICAL_3]:         { unit: 'ScreenGuard™', modelDdiameter: 'Vertical 3"',         learnMoreLinkMessageKeyMobile: MODEL_LINK_SCREENGUARD, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SCREENGUARD_DESKTOP },
    [VERTICAL_3_JUMBO]:   { unit: 'ScreenGuard™', modelDdiameter: 'Vertical 3" Jumbo',   learnMoreLinkMessageKeyMobile: MODEL_LINK_SCREENGUARD, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SCREENGUARD_DESKTOP },
    [VERTICAL_4]:         { unit: 'ScreenGuard™', modelDdiameter: 'Vertical 4"',         learnMoreLinkMessageKeyMobile: MODEL_LINK_SCREENGUARD, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SCREENGUARD_DESKTOP },
    [VERTICAL_4_JUMBO]:   { unit: 'ScreenGuard™', modelDdiameter: 'Vertical 4" Jumbo',   learnMoreLinkMessageKeyMobile: MODEL_LINK_SCREENGUARD, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SCREENGUARD_DESKTOP },
    [VERTICAL_6]:         { unit: 'ScreenGuard™', modelDdiameter: 'Vertical 6"',         learnMoreLinkMessageKeyMobile: MODEL_LINK_SCREENGUARD, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SCREENGUARD_DESKTOP },
    [HORIZONTAL_4]:       { unit: 'ScreenGuard™', modelDdiameter: 'Horizontal 4"',       learnMoreLinkMessageKeyMobile: MODEL_LINK_SCREENGUARD, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SCREENGUARD_DESKTOP },
    [HORIZONTAL_4_JUMBO]: { unit: 'ScreenGuard™', modelDdiameter: 'Horizontal 4" Jumbo', learnMoreLinkMessageKeyMobile: MODEL_LINK_SCREENGUARD, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SCREENGUARD_DESKTOP },
    [HORIZONTAL_6]:       { unit: 'ScreenGuard™', modelDdiameter: 'Horizontal 6"',       learnMoreLinkMessageKeyMobile: MODEL_LINK_SCREENGUARD, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SCREENGUARD_DESKTOP },
    [HORIZONTAL_6_JUMBO]: { unit: 'ScreenGuard™', modelDdiameter: 'Horizontal 6" Jumbo', learnMoreLinkMessageKeyMobile: MODEL_LINK_SCREENGUARD, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SCREENGUARD_DESKTOP },
    [HORIZONTAL_8]:       { unit: 'ScreenGuard™', modelDdiameter: 'Horizontal 8"',       learnMoreLinkMessageKeyMobile: MODEL_LINK_SCREENGUARD, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SCREENGUARD_DESKTOP },
    [HORIZONTAL_10]:      { unit: 'ScreenGuard™', modelDdiameter: 'Horizontal 10"',      learnMoreLinkMessageKeyMobile: MODEL_LINK_SCREENGUARD, learnMoreLinkMessageKeyDesktop: MODEL_LINK_SCREENGUARD_DESKTOP },
};

export const filtretionTechnologyKey: { [model: number]: number } = {
    [MEDIA_FILTER]: MEDIA_FILTER_TITLE,
    [DISC_FILTERS]: DISC_FILTERS_TITLE,
    [SCREEN_FILTERS]: SCREEN_FILTERS_TITLE,
}

export const technologyFilterKey: { [model: number]: number } = {
    [MEDIA_FILTER]: MEDIA_FILTER_TEXT,
    [DISC_FILTERS]: DISC_FILTER_TEXT,
    [SCREEN_FILTERS]: SCREEN_FILTER_TEXT,
}

export const unitModelAndDiameterStrs: { [filtrationTechnologyOptions: number]: { [model: number]: IUnitModelAndDiameter } } = {
    [MEDIA_FILTER]: mediaFiltersUnitModelAndDiameter,
    [DISC_FILTERS]: discFiltersUnitModelAndDiameter,
    [SCREEN_FILTERS]: screenFiltersUnitModelAndDiameter
}