import { IInputParamsState } from "../store/slices/inputParamsSlice";
import { irrigationMethodScore, waterSourceScore, waterQualityScore } from '../constats/calculateData/filtrationTechnology'
import { IFiltrationTechnologyScore } from "../interfaces/filtrationTechnologyOption";
import { EBinaryOptions, EIrrigationMethodOptions, EMainWaterSourceOptions, ERangeOptions, ESuctionPointWaterSourceOptions } from "../enums/inputsAnswers.enum";
import { averageQualityConditions } from "../constats/calculateData/waterQuality";
import { IFiltrationScoreSums, IFiltrationTechnology, IFiltrationTechnologyRes } from "../interfaces/filtrationTechnologySums";
import { EIrrigationMethodGroup, EWaterQuality } from "../enums/waterQuality.enum";
import { IRecommendedModels } from "../interfaces/calculations";
import { systemPressureRanges } from "../constats/calculateData/systemPressureNotification";
import { IRecommendedManifold } from "../interfaces/manifoldConfiguration";
import { ALL_UNITS, recommendedManifold } from "../constats/calculateData/manifoldConfiguration";
import { IResultsKey } from "../interfaces/resultsData";
import { getStringKey, ModelsData } from "../constats/resultsData/models";
import { EFiltrationTechnologyOptions } from "../enums";
import { drawingPdfKeys, IDrawingPdfKeys } from "../constats/languages/constPdfName";
import { DRAWING_PDFS_PATH } from "../constats/links";

const { AVERAGE, POOR, IRON_REMOVAL } = EWaterQuality;
const { DISC_FILTERS, MEDIA_FILTER, SCREEN_FILTERS } = EFiltrationTechnologyOptions;
const { ALL_DRIPLINES, PIVOTS_OR_OPENS, MICRO_SPRINKLERS } = EIrrigationMethodGroup;
const { CANAL, RIVER } = ESuctionPointWaterSourceOptions;
const { HIGH } = ERangeOptions;

export const getFiltrationTechnology = (inputParams: IInputParamsState): IFiltrationTechnologyRes => {
    const { irrigationMethod, mainWaterSource, suctionPointWaterSource, sand, siltClay, tSS, organicMatters, isFeMnExist } = inputParams;

    let sumScore: IFiltrationScoreSums = {
        FiltersScoresArr: [
            { type: MEDIA_FILTER, score: 0 },
            { type: DISC_FILTERS, score: 0 },
            { type: SCREEN_FILTERS, score: 0 },
        ],
        hydroCycloneScore: 0,
    }

    sumScore = irrigationMethod !== EIrrigationMethodOptions.UNINITIALIZED ? updateScore(sumScore, irrigationMethodScore[irrigationMethod]) : sumScore;
    sumScore = mainWaterSource !== EMainWaterSourceOptions.UNINITIALIZED ? updateScore(sumScore, waterSourceScore.mainWaterSource[mainWaterSource]) : sumScore;
    sumScore = suctionPointWaterSource !== ESuctionPointWaterSourceOptions.UNINITIALIZED ? updateScore(sumScore, waterSourceScore.suctionPointWaterSource[suctionPointWaterSource]) : sumScore;
    sumScore = sand !== ERangeOptions.UNINITIALIZED ? updateScore(sumScore, waterQualityScore.Sand[sand]) : sumScore;
    sumScore = siltClay !== ERangeOptions.UNINITIALIZED ? updateScore(sumScore, waterQualityScore.SiltClay[siltClay]) : sumScore;
    sumScore = tSS !== ERangeOptions.UNINITIALIZED ? updateScore(sumScore, waterQualityScore.TSS[tSS]) : sumScore;
    sumScore = organicMatters !== EBinaryOptions.UNINITIALIZED ? updateScore(sumScore, waterQualityScore.PresenceOrganicMatters[organicMatters]) : sumScore;
    sumScore = isFeMnExist !== EBinaryOptions.UNINITIALIZED ? updateScore(sumScore, waterQualityScore.isFeMnExist[isFeMnExist]) : sumScore;

    const results: IFiltrationTechnologyRes = getScoreResults(sumScore, inputParams);
    return results;
};

const updateScore = (sumScore: IFiltrationScoreSums, value: IFiltrationTechnologyScore): IFiltrationScoreSums => {
    const arr = sumScore.FiltersScoresArr;
    arr.map((filter) => {
        let currVal = 0;
        switch (filter.type) {
            case MEDIA_FILTER:
                currVal = value.mediaFilterScore;
                break;
            case DISC_FILTERS:
                currVal = value.discFilterScore;
                break;
            case SCREEN_FILTERS:
                currVal = value.screenFilterScore;
                break;
            default:
                break;
        }
        filter.score += currVal;
        return filter
    })
    sumScore.hydroCycloneScore += value.hydroCycloneScore;
    return sumScore;
}

const getScoreResults = (sumScore: IFiltrationScoreSums, inputParams: IInputParamsState): IFiltrationTechnologyRes => {
    let res: IFiltrationTechnologyRes = { isHydro: false };

    sumScore.FiltersScoresArr.sort((a: IFiltrationTechnology, b: IFiltrationTechnology) => {
        return b.score - a.score
    })

    if (sumScore.hydroCycloneScore > 0) {
        res.isHydro = true
    }

    if (isIronExist(inputParams)) {
        res.firstOpt = EFiltrationTechnologyOptions.MEDIA_FILTER;
        res.secoundOpt = EFiltrationTechnologyOptions.UNINITIALIZED;
    } else {
        res.firstOpt = sumScore.FiltersScoresArr[0].type;
        res.secoundOpt = sumScore.FiltersScoresArr[1].type;
    }

    return res;
}

const isIronExist = (inputParams: IInputParamsState): boolean => {
    return inputParams.isFeMnExist === EBinaryOptions.YES && !isPivotOrOpenSprinklers(inputParams.irrigationMethod)
}

export const geWaterQuality = (inputParams: IInputParamsState): EWaterQuality => {
    if (isIronExist(inputParams)) {
        return IRON_REMOVAL;
    }

    let quality: EWaterQuality = POOR;
    const irrigationMethodGroup: EIrrigationMethodGroup = getIrrigationMethodGroup(inputParams.irrigationMethod);

    averageQualityConditions[irrigationMethodGroup].forEach((cond) => {
        if (cond.organicMatters === inputParams.organicMatters &&
            cond.sand === inputParams.sand &&
            cond.siltClay === inputParams.siltClay &&
            cond.tSS === inputParams.tSS) {
            quality = AVERAGE;
        }
    })

    if (irrigationMethodGroup === PIVOTS_OR_OPENS &&
        (inputParams.mainWaterSource === EMainWaterSourceOptions.WELL || inputParams.suctionPointWaterSource === ESuctionPointWaterSourceOptions.WELL)) {
        quality = AVERAGE;
    }

    return quality;


}

const getIrrigationMethodGroup = (method: EIrrigationMethodOptions): EIrrigationMethodGroup => {
    let group: EIrrigationMethodGroup = ALL_DRIPLINES;

    if (isPivotOrOpenSprinklers(method)) {
        group = PIVOTS_OR_OPENS;
    }
    else if (method === EIrrigationMethodOptions.MICRO_SPRINKLERS) {
        group = MICRO_SPRINKLERS;
    }

    return group;
}

const isPivotOrOpenSprinklers = (method: EIrrigationMethodOptions): boolean => {
    return [EIrrigationMethodOptions.PIVOT, EIrrigationMethodOptions.OPEN_FIELD_SPRINKLERS].includes(method)
}

export const getSystemPressureNotification = (recommendedModel: IRecommendedModels, pressure: number): IRecommendedModels => {
    let modelToReturn: IRecommendedModels = recommendedModel;
    const systemPressureRangesData = systemPressureRanges;

    const maxModelPressure: number = systemPressureRangesData[recommendedModel.technology][recommendedModel.model].maxPressure;
    const minModelPressureForBackFlush: number = systemPressureRangesData[recommendedModel.technology][recommendedModel.model].minBackFlushPressure;

    if (pressure > maxModelPressure) {
        modelToReturn.isPressureMaxNotification = true;
    } else if (pressure < minModelPressureForBackFlush) {
        modelToReturn.isPressureMinNotification = true;
    }

    return modelToReturn;
}

export const getRecommendedManifold = (recommendedModel: IRecommendedModels): number => {
    const manifoldsData = recommendedManifold;
    const manifoldOptions: IRecommendedManifold[] = manifoldsData[recommendedModel.technology][recommendedModel.model];
    let manifoldNumber: number = -1;

    manifoldOptions.forEach((manifold) => {
        if ((recommendedModel.units >= manifold.from && recommendedModel.units <= manifold.to) ||
            manifold.from === ALL_UNITS) {
            manifoldNumber = manifold.value;
        }
    })

    return manifoldNumber;
}

export const getModelData = (data: IRecommendedModels) => {
    const { technology, model, units, inletOutletDiameter } = data;
    const keyData: IResultsKey = { technology, model, units, inletOutletDiameter };

    const strKey: string = getStringKey(keyData);
    let modelToReturn = ModelsData[strKey];
    const drawingMoreFilePath = getDrawingPdfName(data);
    modelToReturn = { ...modelToReturn, modelPdfLink: `${DRAWING_PDFS_PATH}${drawingMoreFilePath}.pdf` }

    return modelToReturn;
}

const getDrawingPdfName = (recommendedModel: IRecommendedModels): string => {
    const { technology, model, units } = recommendedModel
    const keys: IDrawingPdfKeys = drawingPdfKeys[technology][model];
    const lastKey: string = keys.subType ? keys.subType : `${units}`;

    return `${keys.tech}_${keys.model}_${keys.type}_${lastKey}`
}

export const checkIsSendimentationReservoirRrecommend = (inputParams: IInputParamsState): boolean => {
    const { irrigationMethod, suctionPointWaterSource, siltClay } = inputParams;

    const irrigationCondition = !isPivotOrOpenSprinklers(irrigationMethod);
    const suctionPointCondition = [CANAL, RIVER].includes(suctionPointWaterSource);
    const siltClayCondition = siltClay === HIGH;

    return irrigationCondition && suctionPointCondition && siltClayCondition;
}

