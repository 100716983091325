import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import ResultCard from "../generic/ResultCard";
import styles from "../../assets/styles/components/pages/result-page.module.scss";
import animation from "../../assets/styles/global/animations.module.scss";
import React, { useEffect, useState } from "react";
import ResultNote from "../generic/ResultNote";
import { DISCLAIMER_TEXT, DISCLAIMER_TITLE, GOOD_TO_KNOW_TEXT_1, GOOD_TO_KNOW_TEXT_2, GOOD_TO_KNOW_TEXT_3, GOOD_TO_KNOW_TITLE, HYDROCYCLON_TEXT, HYDROCYCLON_TITLE, LOWER_HEADER_4, RESULTS_OPTION, RESULTS_RANK_1, RESULTS_RANK_1_SUB, RESULTS_RANK_2, RESULTS_RANK_2_SUB, RESULTS_RANK_OR, SEDIMENTATION_NOTE_LINK, SEDIMENTATION_NOTE_TEXT } from "../../constats/languages/languagesKeys";
import { EFiltrationTechnologyOptions } from "../../enums";
import { isNumberBeforeOptionTitle } from "../../services/languagesHelperService";
import { props as NoteProps } from "../generic/ResultNote"
import { setResultCard, setResultNode, setText, setTitle } from "../../store/slices/resultPdfSlice";
import ConditionsCard from "../generic/ConditionsCard";
import { getResultCardData } from "../../services/resultsCardTextService";
import { SEDIMENTATION_GUIDLINE } from "../../constats/links";

const { UNINITIALIZED } = EFiltrationTechnologyOptions

const Results = () => {
    const { language, unitsParams, languageKey } = useSelector((state: RootState) => state.settingsReducer);
    const {
        // CALCULATED FIELDS
        firstFiltrationTechnology,
        secoundFiltrationTechnology,
        isHydroCyclonRrecommend,
        isSendimentationReservoirRrecommend,
        waterQuality,
        recommendedModels1A,
        recommendedModels1B,
        recommendedModels2,
        // RESULTS
        results1A,
        results1B,
        results2,
    } = useSelector((state: RootState) => state.inputParamsReducer);
    const { firstOpt, or, secOpt, resultCardData, hydroCyclonNoteData, sedimentationNoteData, notesData } = useSelector((state: RootState) => state.resultPdfReducer);

    const dispatch = useDispatch();
    const [scheduleResults, setScheduleResults] = useState<number>(0);

    useEffect(() => {
        incrementScheduleResults(200, 1);
        incrementScheduleResults(400, 2);
        incrementScheduleResults(600, 3);
        incrementScheduleResults(800, 4);
    }, [])

    const incrementScheduleResults = (timeToWait: number, value: number) => {
        setTimeout(() => {
            setScheduleResults(value)
        }, timeToWait);
    }

    useEffect(() => {
        const currentHydroCyclonNoteData: NoteProps | null = getHydroCyclonResultsData();
        const currentSedimentationNoteData: NoteProps | null = getSedimentationResultsData();
        const notesData: NoteProps[] = getResultsNotesData();
        dispatch(setResultNode({ field: 'hydroCyclonNoteData', data: currentHydroCyclonNoteData }));
        dispatch(setResultNode({ field: 'sedimentationNoteData', data: currentSedimentationNoteData }));
        dispatch(setResultNode({ field: 'notesData', data: notesData }));
        setResultsCardsTexts();
        setResultsTitlesTexts();
    }, [language, unitsParams])

    const getHydroCyclonResultsData = (): NoteProps | null => {
        let result: NoteProps | null = isHydroCyclonRrecommend ? { title: language[HYDROCYCLON_TITLE], paragraph1: language[HYDROCYCLON_TEXT] } : null;
        return result
    }

    const getSedimentationResultsData = (): NoteProps | null => {
        let result: NoteProps | null = isSendimentationReservoirRrecommend ? { title: language[HYDROCYCLON_TITLE], paragraph1: language[SEDIMENTATION_NOTE_TEXT], linkText: language[SEDIMENTATION_NOTE_LINK], linkSrc: SEDIMENTATION_GUIDLINE } : null;
        return result
    }

    const getResultsNotesData = (): NoteProps[] => {
        let result: NoteProps[] = [
            { title: language[GOOD_TO_KNOW_TITLE], paragraph1: language[GOOD_TO_KNOW_TEXT_1], paragraph2: language[GOOD_TO_KNOW_TEXT_2], paragraph3: language[GOOD_TO_KNOW_TEXT_3] },
            { title: language[DISCLAIMER_TITLE], paragraph1: language[DISCLAIMER_TEXT] },
        ];

        return result
    }

    const setResultsCardsTexts = () => {
        const data1A = getResultCardData(firstFiltrationTechnology, recommendedModels1A, language, unitsParams, results1A, waterQuality)
        dispatch(setResultCard({ index: 1, data: data1A }));
        const data1B = getResultCardData(firstFiltrationTechnology, recommendedModels1B, language, unitsParams, results1B, waterQuality)
        dispatch(setResultCard({ index: 2, data: data1B }))
        const data2 = getResultCardData(secoundFiltrationTechnology, recommendedModels2, language, unitsParams, results2, waterQuality)
        dispatch(setResultCard({ index: 3, data: data2 }))
    }

    const setResultsTitlesTexts = () => {
        const pageTitle = language[LOWER_HEADER_4];
        dispatch(setText({ field: 'pageTitle', data: pageTitle }));
        const title1A = getTitleCardData(1);
        dispatch(setTitle({ field: 'firstOpt', data: title1A }));
        const title1B = (firstFiltrationTechnology !== UNINITIALIZED && recommendedModels1B) ? language[RESULTS_RANK_OR] : null;
        title1B && dispatch(setText({ field: 'or', data: title1B }))
        const title2 = getTitleCardData(3);
        dispatch(setTitle({ field: 'secOpt', data: title2 }))
    }

    const getTitleCardData = (titlePlace: number): { number: string, option: string, smallText: string } => {
        const res: { number: string, option: string, smallText: string } =
            titlePlace === 1 ?
                { number: language[RESULTS_RANK_1], option: language[RESULTS_OPTION], smallText: language[RESULTS_RANK_1_SUB] } :
                { number: language[RESULTS_RANK_2], option: language[RESULTS_OPTION], smallText: language[RESULTS_RANK_2_SUB] }
        return res;
    }

    const firstTitleClasses: string[] = [
        styles["title"],
        styles["color-green"],
    ];

    const subTitleClasses: string[] = [
        styles["sub-Title"],
        styles["color-green"],
    ];

    const secoundTitleClasses: string[] = [
        styles["title"],
        styles["color-orange"],
    ];

    return <>
        <div className={animation["slide-up-results"]}>
            <ConditionsCard />
        </div>
        {scheduleResults > 0 && <div className={animation["slide-up-results"]}>
            {isNumberBeforeOptionTitle(languageKey) ?
                <h1 className={firstTitleClasses.join(" ")}><span className={styles["bold"]}>{firstOpt?.number}<sup>{firstOpt?.smallText}</sup></span> {firstOpt?.option}</h1> :
                <h1 className={firstTitleClasses.join(" ")}>{firstOpt?.option} <span className={styles["bold"]}>{firstOpt?.number}<sup>{firstOpt?.smallText}</sup></span></h1>
            }
            {firstFiltrationTechnology !== UNINITIALIZED && <ResultCard place={1} technology={firstFiltrationTechnology} recommendedModel={recommendedModels1A} modelDetails={results1A} text={resultCardData[1]} />}
        </div>}
        {scheduleResults > 1 && <div className={animation["slide-up-results"]}>
            {(firstFiltrationTechnology !== UNINITIALIZED && recommendedModels1B) && <h6 className={subTitleClasses.join(" ")}><span className={styles["dashedLine"]} />{or}<span className={styles["dashedLine"]} /></h6>}
            {firstFiltrationTechnology !== UNINITIALIZED && recommendedModels1B && <ResultCard place={2} technology={firstFiltrationTechnology} recommendedModel={recommendedModels1B} modelDetails={results1B} text={resultCardData[2]} />}
        </div>}
        {scheduleResults > 2 && <div className={animation["slide-up-results"]}>
            {secoundFiltrationTechnology !== UNINITIALIZED && (isNumberBeforeOptionTitle(languageKey) ?
                <h1 className={secoundTitleClasses.join(" ")}><span className={styles["bold"]}>{secOpt?.number}<sup>{secOpt?.smallText}</sup></span> {secOpt?.option}</h1> :
                <h1 className={secoundTitleClasses.join(" ")}>{secOpt?.option} <span className={styles["bold"]}>{secOpt?.number}<sup>{secOpt?.smallText}</sup></span></h1>
            )}
            {secoundFiltrationTechnology !== UNINITIALIZED && <ResultCard place={3} technology={secoundFiltrationTechnology} recommendedModel={recommendedModels2} modelDetails={results2} text={resultCardData[3]} />}
        </div>}
        {scheduleResults > 3 && <div className={animation["slide-up-results"]}>
            {isHydroCyclonRrecommend && <ResultNote title={hydroCyclonNoteData?.title || ""} style={'blue'} paragraph1={hydroCyclonNoteData?.paragraph1 || ""} />}
            {isSendimentationReservoirRrecommend && <ResultNote title={sedimentationNoteData?.title || ""} style={'blue'} paragraph1={sedimentationNoteData?.paragraph1 || ""} linkText={sedimentationNoteData?.linkText} linkSrc={sedimentationNoteData?.linkSrc} />}
            <ResultNote title={notesData[0]?.title || ""} paragraph1={notesData[0]?.paragraph1 || ""} paragraph2={notesData[0]?.paragraph2} paragraph3={notesData[0]?.paragraph3} />
            <ResultNote title={notesData[1]?.title || ""} paragraph1={notesData[1]?.paragraph1 || ""} />
        </div>}
    </>
}

export default Results;

