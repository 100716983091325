import { UNINITIALIZED_VALUE } from "../constats/calculateData/global";
import { suctionPointWaterSourceArr } from "../constats/formQuestion/selectQuestionsStep2";
import { EMainWaterSourceOptions, ESuctionPointWaterSourceOptions } from "../enums";
import { IMySelectOption } from "../interfaces/selectQuestion";

const EMainWater = EMainWaterSourceOptions;
const ESuctionPoint = ESuctionPointWaterSourceOptions;

export const isFieldsFilled = (stepParams: any): boolean => {
    let isFilled = true
    Object.keys(stepParams).forEach((key) => {
        isFilled = stepParams[key] === UNINITIALIZED_VALUE ? false : isFilled;
    })
    return isFilled;
}

export const isOneFieldsOrMoreFilled = (stepParams: any): boolean => {
    let isFilled = false
    Object.keys(stepParams).forEach((key) => {
        isFilled = stepParams[key] !== UNINITIALIZED_VALUE || isFilled;
    })
    return isFilled;
}

export const isFieldsExist = (stepParams: any): boolean => {
    let isFilled = true
    Object.keys(stepParams).forEach((key) => {
        isFilled = stepParams[key] && isFilled
    })
    return isFilled;
}

export const getNextQuestionNumber = (stepParams: any, lastQuestion: number = -1): number | null => {
    let nextQuestion: number | null = null;

    Object.keys(stepParams).forEach((key, index) => {
        if (!nextQuestion && stepParams[key] === UNINITIALIZED_VALUE && index + 1 > lastQuestion) {
            nextQuestion = index + 1;
        }
    })

    return nextQuestion;
}

export const getUpdateSuctionPointOptionsArr = (mainValue: EMainWaterSourceOptions): IMySelectOption[] => {

    let arrToReturn: IMySelectOption[] = suctionPointWaterSourceArr;

    switch (mainValue) {
        case EMainWater.CANAL:
            arrToReturn = createArrByValues([ESuctionPoint.RESERVOIR, ESuctionPoint.LAGOON, ESuctionPoint.OPERATIONAL_POOL, ESuctionPoint.SEDIMENTATION_STRUCTURE, ESuctionPoint.WATER_STORAGE_TANK]);
            break;
        case EMainWater.LAKE:
            arrToReturn = createArrByValues([ESuctionPoint.RESERVOIR, ESuctionPoint.LAGOON, ESuctionPoint.OPERATIONAL_POOL, ESuctionPoint.SEDIMENTATION_STRUCTURE, ESuctionPoint.WATER_STORAGE_TANK]);
            break;
        case EMainWater.RIVER:
            arrToReturn = createArrByValues([ESuctionPoint.CANAL, ESuctionPoint.RESERVOIR, ESuctionPoint.LAGOON, ESuctionPoint.OPERATIONAL_POOL, ESuctionPoint.SEDIMENTATION_STRUCTURE, ESuctionPoint.WATER_STORAGE_TANK]);
            break;
        case EMainWater.RESERVOIR:
            arrToReturn = createArrByValues([ESuctionPoint.CANAL, ESuctionPoint.RESERVOIR, ESuctionPoint.LAGOON, ESuctionPoint.OPERATIONAL_POOL, ESuctionPoint.SEDIMENTATION_STRUCTURE, ESuctionPoint.WATER_STORAGE_TANK]);
            break;
        case EMainWater.LAGOON:
            arrToReturn = createArrByValues([ESuctionPoint.CANAL, ESuctionPoint.RESERVOIR, ESuctionPoint.LAGOON, ESuctionPoint.OPERATIONAL_POOL, ESuctionPoint.SEDIMENTATION_STRUCTURE, ESuctionPoint.WATER_STORAGE_TANK]);
            break;
        case EMainWater.WELL:
            arrToReturn = createArrByValues([ESuctionPoint.CANAL, ESuctionPoint.RESERVOIR, ESuctionPoint.LAGOON, ESuctionPoint.OPERATIONAL_POOL, ESuctionPoint.SEDIMENTATION_STRUCTURE, ESuctionPoint.WATER_STORAGE_TANK]);
            break;
        case EMainWater.TREATED_WASTEWATER:
            arrToReturn = createArrByValues([ESuctionPoint.CANAL, ESuctionPoint.RESERVOIR, ESuctionPoint.OPERATIONAL_POOL, ESuctionPoint.SEDIMENTATION_STRUCTURE, ESuctionPoint.WATER_STORAGE_TANK]);
            break;
        case EMainWater.FARM_EFFLUENCE:
            arrToReturn = createArrByValues([ESuctionPoint.RESERVOIR, ESuctionPoint.OPERATIONAL_POOL, ESuctionPoint.SEDIMENTATION_STRUCTURE, ESuctionPoint.WATER_STORAGE_TANK]);
            break;
        case EMainWater.INDUSTRIAL_RECYCLE_WATER:
            arrToReturn = createArrByValues([ESuctionPoint.CANAL, ESuctionPoint.RESERVOIR, ESuctionPoint.OPERATIONAL_POOL, ESuctionPoint.SEDIMENTATION_STRUCTURE, ESuctionPoint.WATER_STORAGE_TANK]);
            break;
        case EMainWater.NOT_APPLICABLE:
            arrToReturn = suctionPointWaterSourceArr
            break;
        default:
            arrToReturn = suctionPointWaterSourceArr;
            break;
    }

    return arrToReturn;
}

const removeArrItems = (valuesToRemove: number[]): IMySelectOption[] => {
    return suctionPointWaterSourceArr.filter(option => !valuesToRemove.includes(option.value));
}

const createArrByValues = (valuesToAdd: number[]): IMySelectOption[] => {
    return suctionPointWaterSourceArr.filter(option => valuesToAdd.includes(option.value));
}


