import styles from "../../assets/styles/components/generic/main-layout.module.scss"
import QuizContent from "./QuizContent";
import Sidebar from "./Sidebar";

const MainLayout = () => {
    return <div className={styles["responsive-container"]}>
        <Sidebar />
        <QuizContent />
    </div>
}

export default MainLayout;