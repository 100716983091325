import { EDiscFilterModels, EFiltrationTechnologyOptions, EMediaFilterModels, EScreenFilterModels } from "../../enums";
import { IRecommendedManifold } from "../../interfaces/manifoldConfiguration";

const { SANDSTORM_20, SANDSTORM_24, SANDSTORM_30, SANDSTORM_36, SANDSTORM_48, } = EMediaFilterModels;
const { SPINKLIN_2, SPINKLIN_3, APOLLO_ANGLE, APOLLO_TWINS, ALPHA_DISC_SINGLE_3, ALPHA_DISC_SINGLE_XL_3, ALPHA_DISC_SINGLE_XL_4, ALPHA_DISC_DUAL_XL, ALPHA_DISC_TRIO_XL } = EDiscFilterModels;
const { VERTICAL_2, VERTICAL_3, VERTICAL_3_JUMBO, VERTICAL_4, VERTICAL_4_JUMBO, VERTICAL_6, HORIZONTAL_4, HORIZONTAL_4_JUMBO, HORIZONTAL_6, HORIZONTAL_6_JUMBO, HORIZONTAL_8, HORIZONTAL_10 } = EScreenFilterModels;
const { DISC_FILTERS, MEDIA_FILTER, SCREEN_FILTERS } = EFiltrationTechnologyOptions;

export const ALL_UNITS = -1

const recommendedManifoldMediaFilters: { [model: number]: IRecommendedManifold[] } =
{
    [SANDSTORM_20]: [{ from: 2, to: 3, value: 3 },{ from: 4, to: 5, value: 4 }],
    [SANDSTORM_24]: [{ from: 2, to: 5, value: 4 },{ from: 6, to: 10, value: 6 }],
    [SANDSTORM_30]: [{ from: 2, to: 5, value: 6 },{ from: 6, to: 8, value: 8 }],
    [SANDSTORM_36]: [{ from: 2, to: 4, value: 6 },{ from: 5, to: 8, value: 8 }],
    [SANDSTORM_48]: [{ from: 3, to: 4, value: 8 },{ from: 5, to: 8, value: 10 },{ from: 9, to: 12, value: 12 }],
};

const recommendedManifoldDiscFilters: { [model: number]: IRecommendedManifold[] } =
{
    [SPINKLIN_2]:             [{ from: 0, to: 2, value: 3 },{ from: 3, to: 7, value: 4 }],
    [SPINKLIN_3]:             [{ from: 3, to: 6, value: 6 },{ from: 7, to: 8, value: 8 }],
    [APOLLO_ANGLE]:           [{ from: 3, to: 5, value: 6 },{ from: 6, to: 7, value: 8 }],    
    [APOLLO_TWINS]:           [{ from: 0, to: 3, value: 8 },{ from: 4, to: 5, value: 10 }, { from: 6, to: 8, value: 12 }],
    [ALPHA_DISC_SINGLE_3]:    [{ from: ALL_UNITS, to: ALL_UNITS, value: 3 }],
    [ALPHA_DISC_SINGLE_XL_3]: [{ from: ALL_UNITS, to: ALL_UNITS, value: 3 }],
    [ALPHA_DISC_SINGLE_XL_4]: [{ from: ALL_UNITS, to: ALL_UNITS, value: 4 }],
    [ALPHA_DISC_DUAL_XL]:     [{ from: ALL_UNITS, to: ALL_UNITS, value: 6 }],
    [ALPHA_DISC_TRIO_XL]:     [{ from: ALL_UNITS, to: ALL_UNITS, value: 8 }],
};

const recommendedManifoldScreenFilters: { [model: number]: IRecommendedManifold[] } =
{
    [VERTICAL_2]:         [{ from: ALL_UNITS, to: ALL_UNITS, value: 2 }],
    [VERTICAL_3]:         [{ from: ALL_UNITS, to: ALL_UNITS, value: 3 }],
    [VERTICAL_3_JUMBO]:   [{ from: ALL_UNITS, to: ALL_UNITS, value: 3 }],
    [VERTICAL_4]:         [{ from: ALL_UNITS, to: ALL_UNITS, value: 4 }],
    [VERTICAL_4_JUMBO]:   [{ from: ALL_UNITS, to: ALL_UNITS, value: 4 }],
    [VERTICAL_6]:         [{ from: ALL_UNITS, to: ALL_UNITS, value: 6 }],
    [HORIZONTAL_4]:       [{ from: ALL_UNITS, to: ALL_UNITS, value: 4 }],
    [HORIZONTAL_4_JUMBO]: [{ from: ALL_UNITS, to: ALL_UNITS, value: 4 }],
    [HORIZONTAL_6]:       [{ from: ALL_UNITS, to: ALL_UNITS, value: 6 }],
    [HORIZONTAL_6_JUMBO]: [{ from: ALL_UNITS, to: ALL_UNITS, value: 6 }],
    [HORIZONTAL_8]:       [{ from: ALL_UNITS, to: ALL_UNITS, value: 8 }],
    [HORIZONTAL_10]:      [{ from: ALL_UNITS, to: ALL_UNITS, value: 10 }],
};

export const recommendedManifold: { [filtrationTechnologyOptions: number]: { [model: number]: IRecommendedManifold[] } } = {    
    [DISC_FILTERS]: recommendedManifoldDiscFilters,
    [MEDIA_FILTER]: recommendedManifoldMediaFilters,
    [SCREEN_FILTERS]: recommendedManifoldScreenFilters
}