import { UNINITIALIZED_VALUE } from "../constats/calculateData/global";

export enum EFiltrationTechnologyOptions {
    UNINITIALIZED = UNINITIALIZED_VALUE,
    MEDIA_FILTER = 1,
    DISC_FILTERS,
    SCREEN_FILTERS
}

export enum EMediaFilterModels {
    UNINITIALIZED = UNINITIALIZED_VALUE,
    SANDSTORM_20 = 1,
    SANDSTORM_24,
    SANDSTORM_30,
    SANDSTORM_36,
    SANDSTORM_48,
}

export enum EScreenFilterModels {
    UNINITIALIZED = UNINITIALIZED_VALUE,
    VERTICAL_2 = 1,
    VERTICAL_3,
    VERTICAL_3_JUMBO,
    VERTICAL_4,
    VERTICAL_4_JUMBO,
    VERTICAL_6,
    HORIZONTAL_4,
    HORIZONTAL_4_JUMBO,
    HORIZONTAL_6,
    HORIZONTAL_6_JUMBO,
    HORIZONTAL_8,
    HORIZONTAL_10,
}

const { VERTICAL_2, VERTICAL_3, VERTICAL_3_JUMBO, VERTICAL_4, VERTICAL_4_JUMBO, VERTICAL_6, HORIZONTAL_4, HORIZONTAL_4_JUMBO, HORIZONTAL_6, HORIZONTAL_6_JUMBO, HORIZONTAL_8, HORIZONTAL_10 } = EScreenFilterModels;

export const Vertical = [
    VERTICAL_2,
    VERTICAL_3,
    VERTICAL_3_JUMBO,
    VERTICAL_4,
    VERTICAL_4_JUMBO,
    VERTICAL_6,
]
export const Horizontal = [
    HORIZONTAL_4,
    HORIZONTAL_4_JUMBO,
    HORIZONTAL_6,
    HORIZONTAL_6_JUMBO,
    HORIZONTAL_8,
    HORIZONTAL_10,
]

export enum EDiscFilterModels {
    UNINITIALIZED = UNINITIALIZED_VALUE,
    SPINKLIN_2 = 1,
    SPINKLIN_3,
    APOLLO_ANGLE,
    APOLLO_TWINS,
    ALPHA_DISC_SINGLE_3,
    ALPHA_DISC_SINGLE_XL_3,
    ALPHA_DISC_SINGLE_XL_4,
    ALPHA_DISC_DUAL_XL,
    ALPHA_DISC_TRIO_XL,
}

const { SPINKLIN_2, SPINKLIN_3, APOLLO_ANGLE, APOLLO_TWINS, ALPHA_DISC_SINGLE_3, ALPHA_DISC_SINGLE_XL_3, ALPHA_DISC_SINGLE_XL_4, ALPHA_DISC_DUAL_XL, ALPHA_DISC_TRIO_XL } = EDiscFilterModels;

export const AlphaDisc = [
    ALPHA_DISC_SINGLE_3,
    ALPHA_DISC_SINGLE_XL_3,
    ALPHA_DISC_SINGLE_XL_4,
    ALPHA_DISC_DUAL_XL,
    ALPHA_DISC_TRIO_XL,
]
export const Apollo = [
    APOLLO_TWINS,
    APOLLO_ANGLE,
]
export const SpinKlin = [
    SPINKLIN_2,
    SPINKLIN_3,
]

