
import { useEffect, useState } from "react";
import styles from "../../assets/styles/components/generic/condition-card.module.scss"
import animation from "../../assets/styles/global/animations.module.scss";
import plusIcon from "../../assets/images/icons/plus-icon.svg"
import minusIcon from "../../assets/images/icons/minus-icon.svg"
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { CONDITIONS_TITLE, CONDITIONS_WATER_SOURCE, CONDITIONS_SUCTION_POINT, CONDITIONS_SAND, CONDITIONS_SILT_CLAY, CONDITIONS_TSS, CONDITIONS_ORGANIC_MATTER, CONDITIONS_FE_MN, CONDITIONS_DROPDOWN } from "../../constats/languages/languagesKeys";
import { irrigationMethodArr } from "../../constats/formQuestion/selectQuestionsStep1";
import { getBinaryTitleKey, getOptionTitleKey } from "../../services/languagesHelperService";
import { mainWaterSourceArr, suctionPointWaterSourceArr } from "../../constats/formQuestion/selectQuestionsStep2";
import { sandArr, siltClayArr, tSSArr } from "../../constats/formQuestion/selectQuestionsStep3";
import { UNINITIALIZED_VALUE } from "../../constats/calculateData/global";
import { ConditionsCardData, setConditionsCard } from "../../store/slices/resultPdfSlice";

export type props = {
}

const ConditionsCard = ({ }: props) => {

    const { language, unitsParams } = useSelector((state: RootState) => state.settingsReducer);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [cardAnimation, setCardAnimation] = useState<"" | "open" | "close">("");
    const { flowRate, pressure, irrigationMethod, mainWaterSource, suctionPointWaterSource, sand, siltClay, tSS, organicMatters, isFeMnExist } = useSelector((state: RootState) => state.inputParamsReducer);
    const { conditionsCardData } = useSelector((state: RootState) => state.resultPdfReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        setConditionsCardData();
    }, [language, unitsParams]);

    const setConditionsCardData = () => {
        const irrigationMethodKey: number = getOptionTitleKey(irrigationMethodArr, irrigationMethod);
        const mainWaterSourceKey: number = getOptionTitleKey(mainWaterSourceArr, mainWaterSource);
        const suctionPointKey: number = getOptionTitleKey(suctionPointWaterSourceArr, suctionPointWaterSource);
        const sandKey: number = getOptionTitleKey(sandArr, sand);
        const siltClayKey: number = getOptionTitleKey(siltClayArr, siltClay);
        const tSSKey: number = getOptionTitleKey(tSSArr, tSS);
        const organicMattersKey: number = getBinaryTitleKey(organicMatters);
        const isFeMnExistKey: number = getBinaryTitleKey(isFeMnExist);

        const data: ConditionsCardData = {
            title: language[CONDITIONS_TITLE],
            flowRateAndPressure: `${(flowRate * unitsParams.flowrateFactor).toFixed(2)} ${unitsParams.flowrateLabel} | ${(pressure * unitsParams.pressureFactor).toFixed(2)} ${unitsParams.pressureLabel}`,
            irrigationMethodValue: `${language[irrigationMethodKey]}`,
            mainWaterSourceStr: `${language[CONDITIONS_WATER_SOURCE]}: `,
            mainWaterSourceValue: mainWaterSourceKey ? language[mainWaterSourceKey] : '-',
            suctionPointStr: `${language[CONDITIONS_SUCTION_POINT]}: `,
            suctionPointValue: suctionPointKey ? language[suctionPointKey] : '-',
            sandStr: `${language[CONDITIONS_SAND]}: `,
            sandValue: sandKey !== UNINITIALIZED_VALUE ? language[sandKey] : '-',
            siltClayStr: `${language[CONDITIONS_SILT_CLAY]}: `,
            siltClayValue: siltClayKey !== UNINITIALIZED_VALUE ? language[siltClayKey] : '-',
            tSSStr: `${language[CONDITIONS_TSS]}: `,
            tSSValue: tSSKey !== UNINITIALIZED_VALUE ? language[tSSKey] : '-',
            organicMattersStr: `${language[CONDITIONS_ORGANIC_MATTER]}: `,
            organicMattersValue: organicMattersKey !== UNINITIALIZED_VALUE ? language[organicMattersKey] : '-',
            isFeMnExistStr: `${language[CONDITIONS_FE_MN]}: `,
            isFeMnExistValue: isFeMnExistKey !== UNINITIALIZED_VALUE ? language[isFeMnExistKey] : '-',
        }
        dispatch(setConditionsCard({ data }))
    }


    const handleClick = () => {
        if (isOpen) {
            setCardAnimation("close");
            setTimeout(() => { setIsOpen(!isOpen) }, 200);
        } else {
            setCardAnimation("open");
            setIsOpen(!isOpen)
        }
    }


    const cardTitleClasses: string[] = [
        styles["card-title"],
        styles['color-blue'],
    ];

    const conditionCardClasses: string[] = [
        styles["condition-card-container"],
        styles['color-blue'],
    ];

    const summaryDetailsClasses: string[] = [
        styles['bold-values'],
        styles['summary-fields'],
    ];

    const detailsContainerClasses: string[] = [
        styles["details-container"],
        animation[`${cardAnimation}`],
    ];

    return (
        <div className={conditionCardClasses.join(" ")}>
            <div className={styles['pointer']} onClick={handleClick}>
                <div className={cardTitleClasses.join(" ")}>
                    {conditionsCardData?.title}
                </div>
                <div className={styles['summary-details-container']} >
                    <h5 className={summaryDetailsClasses.join(" ")}>{conditionsCardData?.flowRateAndPressure}</h5>
                    <h5 className={summaryDetailsClasses.join(" ")}>{conditionsCardData?.irrigationMethodValue}</h5>
                    <h4 className={styles['dropdown-text']}>{`${language[CONDITIONS_DROPDOWN]}`}<img className={styles['plus-icon']} src={isOpen ? minusIcon : plusIcon} /></h4>
                </div>
            </div>
            {isOpen &&
                <div className={detailsContainerClasses.join(" ")}>
                    <div className={styles['bullets-container']} >
                        <div className={styles['bullets-left-section']}>
                            <h5 className={styles['list-field']}>{conditionsCardData?.mainWaterSourceStr}<span className={styles['bold-values']}>{conditionsCardData?.mainWaterSourceValue}</span></h5>
                            <h5 className={styles['list-field']}>{conditionsCardData?.suctionPointStr}<span className={styles['bold-values']}>{conditionsCardData?.suctionPointValue}</span></h5>
                            <h5 className={styles['list-field']}>{conditionsCardData?.sandStr}<span className={styles['bold-values']}>{conditionsCardData?.sandValue}</span></h5>
                            <h5 className={styles['list-field']}>{conditionsCardData?.siltClayStr}<span className={styles['bold-values']}>{conditionsCardData?.siltClayValue}</span></h5>
                        </div>
                        <div className={styles['bullets-right-section']}>
                            <h5 className={styles['list-field']}>{conditionsCardData?.tSSStr}<span className={styles['bold-values']}>{conditionsCardData?.tSSValue}</span></h5>
                            <h5 className={styles['list-field']}>{conditionsCardData?.organicMattersStr}<span className={styles['bold-values']}>{conditionsCardData?.organicMattersValue}</span></h5>
                            <h5 className={styles['list-field']}>{conditionsCardData?.isFeMnExistStr}<span className={styles['bold-values']}>{conditionsCardData?.isFeMnExistValue}</span></h5>
                        </div>
                    </div>
                </div>}
        </div>
    )
}
export default ConditionsCard;

