export enum ELanguages {
    ENGLISH = 101,
    SPANISH = 102,
    FRENCH = 103,
    ITAILAN = 104,
    PORTUGUESE = 105,
    TURKISH = 106,
    GERMAN = 107,
}

export enum EUnits {
    METRIC = 109,
    US_UNITS = 110,
}