import Header from "../generic/Header";
import styles from "../../assets/styles/components/pages/main-page.module.scss";
import MainLayout from "../generic/MainLayout";

const MainPage = () => {
    return (
        <div className={styles["body-wrapper"]}>
            <Header />
            <MainLayout />
        </div>
    );
}

export default MainPage;