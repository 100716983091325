import { Page, Text, View, StyleSheet, Image, Link, Font } from "@react-pdf/renderer";
import font from "../../assets/fonts/Roboto-Regular.ttf"
import fontMedium from "../../assets/fonts/Roboto-Medium.ttf"

Font.register({ family: "Roboto-Regular", src: font });
Font.register({ family: "Roboto-Medium", src: fontMedium });

const styles = StyleSheet.create({
    resultCardContainer: {
        margin: " 16px 0",
        flexDirection: "column",
        display: "flex",
    },
    detailsContainer: {
        display: "flex",
        alignItems: "flex-start",
        width: "540px",
        flexDirection: "row",
    },
    leftDetails: {
        display: "flex",
        flexDirection: "column",
    },
    rightDetails: {
        display: "flex",
        flexDirection: "column",
        margin: "0 0 0 35px"
    },
    lineContainer: {
        display: "flex",
        flexDirection: "row",
        margin: " 5px 0",
    },
    title: {
        fontFamily: 'Roboto-Regular',
        display: "flex",
        fontSize: "16px",
        margin: "0 0 8px 0 ",
        fontWeight: "bold",
        color: "#0A7FFF",
    },
    text: {
        fontFamily: 'Roboto-Regular',
        display: "flex",
        color: "#2E2E2E",
        fontSize: "12px",
    },
    boldValue: {
        fontFamily: 'Roboto-Medium',
        display: "flex",
        fontWeight: "bold",
        color: "#2E2E2E",
        fontSize: "12px",

    },
});

const ConditionPdfCard = ({ cardData }) => {
    const {
        title,
        flowRateAndPressure,
        irrigationMethodValue,
        mainWaterSourceStr,
        mainWaterSourceValue,
        suctionPointStr,
        suctionPointValue,
        sandStr,
        sandValue,
        siltClayStr,
        siltClayValue,
        tSSStr,
        tSSValue,
        organicMattersStr,
        organicMattersValue,
        isFeMnExistStr,
        isFeMnExistValue,
    } = cardData;
    const myDoc = (
        <View style={styles.resultCardContainer}>
            <Text style={styles.title}>{title}</Text>
            <View>
                <Text style={styles.boldValue}>{flowRateAndPressure}</Text>
                <Text style={styles.boldValue}>{irrigationMethodValue}</Text>
                <View style={styles.detailsContainer}>
                    <View style={styles.leftDetails}>
                        <View style={styles.lineContainer}>
                            <Text style={styles.text}>{mainWaterSourceStr}</Text>
                            <Text style={styles.boldValue}>{mainWaterSourceValue}</Text>
                        </View>
                        <View style={styles.lineContainer}>
                            <Text style={styles.text}>{suctionPointStr}</Text>
                            <Text style={styles.boldValue}>{suctionPointValue}</Text>
                        </View>
                        <View style={styles.lineContainer}>
                            <Text style={styles.text}>{sandStr}</Text>
                            <Text style={styles.boldValue}>{sandValue}</Text>
                        </View>
                        <View style={styles.lineContainer}>
                            <Text style={styles.text}>{siltClayStr}</Text>
                            <Text style={styles.boldValue}>{siltClayValue}</Text>
                        </View>
                    </View>
                    <View style={styles.rightDetails}>
                        <View style={styles.lineContainer}>
                            <Text style={styles.text}>{tSSStr}</Text>
                            <Text style={styles.boldValue}>{tSSValue}</Text>
                        </View>
                        <View style={styles.lineContainer}>
                            <Text style={styles.text}>{organicMattersStr}</Text>
                            <Text style={styles.boldValue}>{organicMattersValue}</Text>
                        </View>
                        <View style={styles.lineContainer}>
                            <Text style={styles.text}>{isFeMnExistStr}</Text>
                            <Text style={styles.boldValue}>{isFeMnExistValue}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );

    return myDoc;
};

export default ConditionPdfCard;
