import styles from "../../assets/styles/components/generic/sidebar-item.module.scss"
import completeIcon from "../../assets/images/icons/sidebar-icon-complete.svg"
import currentIcon from "../../assets/images/icons/sidebar-icon-current.svg"
import remainIcon from "../../assets/images/icons/sidebar-icon-remain.svg"


type props = {
    state: "done" | "current" | "remain";
    title: string;
}

const SidebarItem = ({ state, title }: props) => {

    let icon: string = "";
    const labelClasses: string[] = [styles["label"]];

    if (state === "done") {
        icon = completeIcon;
    }
    if (state === "current") {
        icon = currentIcon;
        labelClasses.push(styles["current"])
    }
    if (state === "remain") {
        icon = remainIcon;
        labelClasses.push(styles["remain"])
    }



    return (<div className={styles["sidebar-item"]}>
        <img src={icon} alt={state + " icon"} className={styles["icon"]} />
        <span className={labelClasses.join(" ")}>{title}</span>
    </div>)

}

export default SidebarItem;