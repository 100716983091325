import React from "react";
import ResultPdfCard from "./ResultPdfCard";
import moment from 'moment';
import { Font } from "@react-pdf/renderer";
import { Page, Text, View, Document, StyleSheet, Image, Line, Link } from "@react-pdf/renderer";
import ResultPdfNote from "./ResultPdfNote";
import ConditionPdfCard from "./ConditionPdfCard";
import icon from "../../assets/images/icons/netafim-pdf.jpg"
import font from "../../assets/fonts/Roboto-Regular.ttf"
import fontMedium from "../../assets/fonts/Roboto-Medium.ttf"

Font.register({ family: "Roboto-Regular", src: font });
Font.register({ family: "Roboto-Medium", src: fontMedium });

const styles = StyleSheet.create({
  documentContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0",
    padding: "0",
    width: "540px",
  },
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "24",
    width: "540px",
  },
  greenTitleContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "10px 0 ",
    width: "540px",
    paddingBottom: "8px",
    color: "#00aa46",
    borderBottom: "1px solid #00aa46",
  },
  yellowTitleContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "10px 0 ",
    width: "540px",
    paddingBottom: "8px",
    color: "#f5be00",
    borderBottom: "1px solid #f5be00",
  },
  numberText: {
    fontFamily: 'Roboto-Medium',
    fontSize: "18px",
    display: "flex",
  },
  smallText: {
    fontFamily: 'Roboto-Medium',
    fontSize: "12px",
    display: "flex",
  },
  optionText: {
    fontFamily: 'Roboto-Regular',
    fontSize: "18px",
    display: "flex",
  },

  subTitle: {
    fontFamily: 'Roboto-Regular',
    display: "flex",
    fontSize: "20px",
    margin: "10px 0",
  },

  resultCardContainer: {
    margin: " 12px 0",
    flexDirection: "column",
    display: "flex",
  },

  lineContainer: {
    display: "flex",
    flexDirection: "row",
    margin: " 5px 0",
  },

  boldValue: {
    display: "flex",
    fontWeight: "bold",
    fontFamily: 'Roboto-Medium',
  },

  innerLineContainer: {
    display: "flex",
    flexDirection: "row",
    margin: " 5px 20px",
  },

  col: {
    display: "flex",
    flexDirection: "column",
    margin: " 5px 0",
  },

  link: {
    display: "flex",
    margin: " 5px 0",
    color: "blue",
    fontFamily: 'Roboto-Regular',
  },
  date: {
    fontFamily: 'Roboto-Regular',
    fontSize: "16px",
  },
  pageTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: "24px",
    display: "flex",
    color: "#0A7FFF",
    borderBottom: "1px solid #00487A",
    paddingBottom: "12px",
    width: "540px",
  },
  headerContainer: {
    width: "540px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "24px 0 5px 0",
    justifyContent: "space-between",
  },
  line: {
    width: "540px",
    borderBottom: " 0.5px solid #2E2E2E",
    display: "flex"
  },
  orContainer: {
    width: "540px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    height: "16px",
    alignItems: "center",
  },
  dashesLine: {
    borderBottom: "1px dashed #00aa46",
    margin: "auto 5px",
    display: "flex",
    width: "250px"
  },
  orTitle: {
    fontFamily: 'Roboto-Regular',
    display: "flex",
    fontSize: "18px",
    margin: "10px 0 ",
    color: "#00aa46",
  },
});

const PdfFile = ({ isNumberBeforeOptionTitle, pageTitle, firstOpt, or, secOpt, conditionsCardData, resultCardData, hydroCyclonNoteData, sedimentationNoteData, notesData }) => {

  const formattedDate = (moment(new Date())).format('DD-MMM-YYYY');

  return (
    <Document style={styles.documentContainer}>
      <Page size="A4" style={styles.pageContainer}>
        <View style={styles.headerContainer}>
          <Text style={styles.date}>{formattedDate}</Text>
          <Image style={{ width: "105px", height: "28px", background: "blue" }} src={icon}></Image>
        </View>
        <View>
          <Text style={styles.pageTitle}>{pageTitle}</Text>
          {conditionsCardData && <ConditionPdfCard cardData={conditionsCardData} />}
          {firstOpt && <View >
            {isNumberBeforeOptionTitle ?
              <View style={styles.greenTitleContainer}>
                <Text style={styles.numberText}>{firstOpt.number}</Text>
                <Text style={styles.smallText}>{firstOpt.smallText}</Text>
                <Text style={{ fontFamily: 'Roboto-Regular', fontSize: "18px", display: "flex", marginLeft: "5px" }}>{`${firstOpt.option}`}</Text>
              </View>
              : <View style={styles.greenTitleContainer}>
                <Text style={styles.optionText}>{firstOpt.option}</Text>
                <Text style={{ fontFamily: 'Roboto-Medium', fontSize: "18px", display: "flex", marginLeft: "5px" }}>{`${firstOpt.number}`}</Text>
                <Text style={styles.smallText}>{`${firstOpt.smallText}`}</Text>
              </View>}
          </View>}
          {firstOpt && resultCardData[1]?.technologyTitle && (<ResultPdfCard resultCardData={resultCardData[1]} titleColor={'Green'} />)}
          <View wrap={false}>
            {firstOpt && resultCardData[1]?.technologyTitle && or && resultCardData[2]?.technologyTitle &&
              <View style={styles.orContainer}>
                <View style={styles.dashesLine} ></View>
                <Text style={styles.orTitle}>{or}</Text>
                <View style={styles.dashesLine} ></View>
              </View>}
            {or && resultCardData[2]?.technologyTitle && (<ResultPdfCard resultCardData={resultCardData[2]} titleColor={'Green'} />)}
          </View>
          <View wrap={false}>
            {secOpt && resultCardData[3]?.technologyTitle &&
              <View >
                {isNumberBeforeOptionTitle ?
                  <View style={styles.yellowTitleContainer}>
                    <Text style={styles.numberText}>{secOpt.number}</Text>
                    <Text style={styles.smallText}>{secOpt.smallText}</Text>
                    <Text style={{ fontFamily: 'Roboto-Regular', fontSize: "18px", display: "flex", marginLeft: "5px" }}>{`${secOpt.option}`}</Text>
                  </View>
                  : <View style={styles.yellowTitleContainer}>
                    <Text style={styles.optionText}>{secOpt.option}</Text>
                    <Text style={{ fontFamily: 'Roboto-Medium', fontSize: "18px", display: "flex", marginLeft: "5px" }}>{`${secOpt.number}`}</Text>
                    <Text style={styles.smallText}>{`${secOpt.smallText}`}</Text>
                  </View>}
              </View>
            }
            {secOpt && resultCardData[3]?.technologyTitle && (<ResultPdfCard resultCardData={resultCardData[3]} titleColor={'Yellow'} />)}
          </View>
          <View style={styles.line}></View>
          <View wrap={false}>
            {hydroCyclonNoteData && <ResultPdfNote noteData={hydroCyclonNoteData} noteStyle={"Blue"} />}
          </View>
          <View wrap={false}>
            {sedimentationNoteData && <ResultPdfNote noteData={sedimentationNoteData} noteStyle={"Blue"} />}
          </View>
          <View wrap={false}>
            {notesData[0] && <ResultPdfNote noteData={notesData[0]} />}
          </View>
          <View wrap={false}>
            {notesData[1] && <ResultPdfNote noteData={notesData[1]} noteStyle={"Red"} />}
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default PdfFile;
