import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IRegistrationState {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    role: string | null;
    country: string | null;
    isAcceptTermsAndPolicy:boolean;
    isLikeToReceiveUpdates:boolean;
}

const initialState: IRegistrationState = {
    firstName: null,
    lastName: null,
    email: null,
    role: null,
    country: null,
    isAcceptTermsAndPolicy:false,
    isLikeToReceiveUpdates:false
};

export const registrationSlice = createSlice({
    name: "registration",
    initialState,
    reducers: {
        setTextFields: (state, action: PayloadAction<{ field: string, data: string | null }>) => {
            const { field, data } = action.payload;
            let tempState: any = state;
            tempState[field] = data;

            state = tempState
        },

        changeBooleanFields: (state, action: PayloadAction<{ field: string }>) => {
            const { field } = action.payload;
            let tempState: any = state;
            
            tempState[field] = !tempState[field];
            state = tempState
        },
    },
});

export const { setTextFields, changeBooleanFields } = registrationSlice.actions;

export const registrationReducer = registrationSlice.reducer;
