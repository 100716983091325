import { useDispatch, useSelector } from "react-redux";
import styles from "../../assets/styles/components/generic/quiz-content.module.scss"
import { FOOTER_CONTACT_NETAFIM, PAGE_FOOTER_3_2, PAGE_FOOTER_3_3, PAGE_FOOTER_4, PAGE_FOOTER_5_1 } from "../../constats/languages/languagesKeys";
import { CONTACT_NETAFIM_LINK } from "../../constats/links";
import { isFieldsExist, isFieldsFilled, isOneFieldsOrMoreFilled } from "../../services/formService";
import { readIsDetailsEntered, writeIsDetailsEntered } from "../../services/localStorageService";
import { getUniqueFileNameByEmail, uploadFileToS3 } from "../../services/uploadFilesService";
import { RootState } from "../../store";
import { calculateResult } from "../../store/slices/inputParamsSlice";
import { goToStep, nextStep, prevStep, quizStep } from "../../store/slices/quizStepsSlice";
import BottomNav, { props } from "./BottomNav";
import MyPopup from "./MyPopup";
import { getItemText } from "../../services/languagesHelperService";
import { ELanguages } from "../../enums/settings";

const QuizContent = () => {

    const steps: quizStep[] = useSelector((state: RootState) => state.quizStepsReducer.steps);
    const currentStep: number = useSelector((state: RootState) => state.quizStepsReducer.currentStep);
    const { language } = useSelector((state: RootState) => state.settingsReducer);
    const { firstName, lastName, email, role, country, isAcceptTermsAndPolicy, isLikeToReceiveUpdates } = useSelector((state: RootState) => state.registrationReducer);
    const { sand, siltClay, tSS, organicMatters, isFeMnExist } = useSelector((state: RootState) => state.inputParamsReducer);
    let hidePopUp: boolean = false;

    const dispatch = useDispatch();

    const nextStepHandler = () => {
        dispatch(nextStep({}));
    }

    const nextAndSkipStepHandler = () => {
        dispatch(calculateResult());
        dispatch(nextStep({ isJumpToResult: true }));
    }

    const lastStepNextHandler = () => {
        const roleStr = role ? getItemText(ELanguages.ENGLISH, Number(role)) : null;
        writeIsDetailsEntered(true);
        uploadFileToS3({ firstName, lastName, email, role: roleStr, country, isAcceptTermsAndPolicy, isLikeToReceiveUpdates }, getUniqueFileNameByEmail(email ? email : ''))
        dispatch(calculateResult());
        dispatch(nextStep({}));
    }

    const prevStepHandler = () => {
        dispatch(prevStep());
    }

    const resBackHandler = () => {
        dispatch(goToStep({ newStep: 2 }));
    }

    const contactNetafim = () => {
        window.location.href = CONTACT_NETAFIM_LINK;
    }

    const bottomNavProps: props = {
        rightButtonOnClick: nextStepHandler,
        hasLeftButton: true,
        leftButtonOnClick: prevStepHandler,
    }

    if (steps[currentStep].id === "Q1") {
        bottomNavProps.hasLeftButton = false;
    }

    if (steps[currentStep].required === false) {
        bottomNavProps.rightButtonTextKey = PAGE_FOOTER_3_2;
        bottomNavProps.rightButtonStyle = "secondary";
        bottomNavProps.rightButtonShadow = "none"
    }

    if (steps[currentStep].id === "Q3") {
        const isDetailsEntered = readIsDetailsEntered();
        if (isDetailsEntered) {
            bottomNavProps.rightButtonOnClick = nextAndSkipStepHandler;
        }
        if (isFieldsFilled({ sand, siltClay, tSS, organicMatters, isFeMnExist })) {
            bottomNavProps.rightButtonTextKey = PAGE_FOOTER_3_3;
            bottomNavProps.rightButtonShadow = "blue";
            bottomNavProps.rightButtonStyle = "primary";
        }
        if (isOneFieldsOrMoreFilled({ sand, siltClay, tSS, organicMatters, isFeMnExist })) {
            hidePopUp = true;
        }

    }

    if (steps[currentStep].id === "REG") {
        bottomNavProps.rightButtonTextKey = PAGE_FOOTER_4;
        bottomNavProps.hasLeftButton = false;
        bottomNavProps.rightButtonShadow = isFieldsExist({ firstName, lastName, email, isAcceptTermsAndPolicy }) ? "blue" : "none";
        bottomNavProps.rightButtonOnClick = lastStepNextHandler;
    }

    if (steps[currentStep].id === "RES") {
        bottomNavProps.isResults = true;
        bottomNavProps.rightButtonTextKey = FOOTER_CONTACT_NETAFIM;
        bottomNavProps.rightButtonOnClick = contactNetafim;
        bottomNavProps.rightButtonStyle = "primary";
        bottomNavProps.leftButtonOnClick = resBackHandler;
    }

    const contentClasses: string[] = [
        styles["content"],
    ];
    const pageTitleClasses: string[] = [
        styles["page-title"]
    ]

    steps[currentStep].id === "RES" && contentClasses.push(styles['small-padding']);
    steps[currentStep].id === "RES" && pageTitleClasses.push(styles['show-only-desktop']);

    return <main className={styles["main"]}>
        <div className={contentClasses.join(" ")}>
            {(steps[currentStep].h1Key || steps[currentStep].h2Key) &&
                <div className={pageTitleClasses.join(" ")}>
                    {steps[currentStep].h1Key && <h1 key={`1${currentStep}`} className={styles['title-bounce']}>{language[steps[currentStep].h1Key!]}</h1>}
                    {steps[currentStep].h2Key && <h2 key={`2${currentStep}`} className={styles['title-bounce']}>{language[steps[currentStep].h2Key!]}</h2>}
                </div>}
            {steps[currentStep].component}
        </div>
        <BottomNav {...bottomNavProps} />

        {steps[currentStep].popupTextKey && <MyPopup kind="button-note" label={language[steps[currentStep].popupTextKey!]} show={steps[currentStep].hasPopup === true && !hidePopUp} />}
    </main>

}

export default QuizContent;