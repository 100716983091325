
import { EDiscFilterModels, EFiltrationTechnologyOptions, EMediaFilterModels, EScreenFilterModels } from "../../enums/recommendedFilterModels.enum"
import { EWaterQuality } from "../../enums/waterQuality.enum"

const { AVERAGE, POOR, IRON_REMOVAL } = EWaterQuality;
const { SANDSTORM_20, SANDSTORM_24, SANDSTORM_30, SANDSTORM_36, SANDSTORM_48, } = EMediaFilterModels;
const { SPINKLIN_2, SPINKLIN_3, APOLLO_ANGLE, APOLLO_TWINS, ALPHA_DISC_SINGLE_3, ALPHA_DISC_SINGLE_XL_3, ALPHA_DISC_SINGLE_XL_4, ALPHA_DISC_DUAL_XL, ALPHA_DISC_TRIO_XL } = EDiscFilterModels;
const { VERTICAL_2, VERTICAL_3, VERTICAL_3_JUMBO, VERTICAL_4, VERTICAL_4_JUMBO, VERTICAL_6, HORIZONTAL_4, HORIZONTAL_4_JUMBO, HORIZONTAL_6, HORIZONTAL_6_JUMBO, HORIZONTAL_8, HORIZONTAL_10 } = EScreenFilterModels;
const { DISC_FILTERS, MEDIA_FILTER, SCREEN_FILTERS } = EFiltrationTechnologyOptions;

interface IFiltersModelScore  {
    [AVERAGE]:      { flowRate: number },
    [POOR]:         { flowRate: number },
    [IRON_REMOVAL]: { flowRate: number },
}

const recommendedConfigurationMediaFilters: { [model:number]: IFiltersModelScore } = 
{
    [SANDSTORM_20]: { [AVERAGE]: { flowRate: 12 }, [POOR]: { flowRate: 10 }, [IRON_REMOVAL]: { flowRate: 6 } },
    [SANDSTORM_24]: { [AVERAGE]: { flowRate: 17 }, [POOR]: { flowRate: 14 }, [IRON_REMOVAL]: { flowRate: 8 } },
    [SANDSTORM_30]: { [AVERAGE]: { flowRate: 27 }, [POOR]: { flowRate: 22 }, [IRON_REMOVAL]: { flowRate: 13 } },
    [SANDSTORM_36]: { [AVERAGE]: { flowRate: 40 }, [POOR]: { flowRate: 32 }, [IRON_REMOVAL]: { flowRate: 19 } },
    [SANDSTORM_48]: { [AVERAGE]: { flowRate: 68 }, [POOR]: { flowRate: 56 }, [IRON_REMOVAL]: { flowRate: 33 } },
};

const recommendedConfigurationDiscFilters: { [model:number]: IFiltersModelScore } = 
{
    [SPINKLIN_2]:             { [AVERAGE]: { flowRate: 15 },  [POOR]: { flowRate: 12 },  [IRON_REMOVAL]: { flowRate: -1 } },
    [SPINKLIN_3]:             { [AVERAGE]: { flowRate: 30 },  [POOR]: { flowRate: 24 },  [IRON_REMOVAL]: { flowRate: -1 } },
    [APOLLO_ANGLE]:           { [AVERAGE]: { flowRate: 35 },  [POOR]: { flowRate: 30 },  [IRON_REMOVAL]: { flowRate: -1 } },
    [APOLLO_TWINS]:           { [AVERAGE]: { flowRate: 70 },  [POOR]: { flowRate: 60 },  [IRON_REMOVAL]: { flowRate: -1 } },
    [ALPHA_DISC_SINGLE_3]:    { [AVERAGE]: { flowRate: 40 },  [POOR]: { flowRate: 35 },  [IRON_REMOVAL]: { flowRate: -1 } },
    [ALPHA_DISC_SINGLE_XL_3]: { [AVERAGE]: { flowRate: 60 },  [POOR]: { flowRate: 50 },  [IRON_REMOVAL]: { flowRate: -1 } },
    [ALPHA_DISC_SINGLE_XL_4]: { [AVERAGE]: { flowRate: 90 },  [POOR]: { flowRate: 80 },  [IRON_REMOVAL]: { flowRate: -1 } },
    [ALPHA_DISC_DUAL_XL]:     { [AVERAGE]: { flowRate: 180 }, [POOR]: { flowRate: 160 }, [IRON_REMOVAL]: { flowRate: -1 } },
    [ALPHA_DISC_TRIO_XL]:     { [AVERAGE]: { flowRate: 270 }, [POOR]: { flowRate: 240 }, [IRON_REMOVAL]: { flowRate: -1 } },
};

const recommendedConfigurationScreenFilters: { [model:number]: IFiltersModelScore } = 
{
    [VERTICAL_2]:         { [AVERAGE]: { flowRate: 25 },  [POOR]: { flowRate: 15 },  [IRON_REMOVAL]: { flowRate: -1 } },
    [VERTICAL_3]:         { [AVERAGE]: { flowRate: 35 },  [POOR]: { flowRate: 25 },  [IRON_REMOVAL]: { flowRate: -1 } },
    [VERTICAL_3_JUMBO]:   { [AVERAGE]: { flowRate: 50 },  [POOR]: { flowRate: 40 },  [IRON_REMOVAL]: { flowRate: -1 } },
    [VERTICAL_4]:         { [AVERAGE]: { flowRate: 75 },  [POOR]: { flowRate: 65 },  [IRON_REMOVAL]: { flowRate: -1 } },
    [VERTICAL_4_JUMBO]:   { [AVERAGE]: { flowRate: 80 },  [POOR]: { flowRate: 70 },  [IRON_REMOVAL]: { flowRate: -1 } },
    [VERTICAL_6]:         { [AVERAGE]: { flowRate: 100 }, [POOR]: { flowRate: 85 },  [IRON_REMOVAL]: { flowRate: -1 } },
    [HORIZONTAL_4]:       { [AVERAGE]: { flowRate: 80 },  [POOR]: { flowRate: 80 },  [IRON_REMOVAL]: { flowRate: -1 } },
    [HORIZONTAL_4_JUMBO]: { [AVERAGE]: { flowRate: 100 }, [POOR]: { flowRate: 100 }, [IRON_REMOVAL]: { flowRate: -1 } },
    [HORIZONTAL_6]:       { [AVERAGE]: { flowRate: 120 }, [POOR]: { flowRate: 120 }, [IRON_REMOVAL]: { flowRate: -1 } },
    [HORIZONTAL_6_JUMBO]: { [AVERAGE]: { flowRate: 150 }, [POOR]: { flowRate: 150 }, [IRON_REMOVAL]: { flowRate: -1 } },
    [HORIZONTAL_8]:       { [AVERAGE]: { flowRate: 250 }, [POOR]: { flowRate: 200 }, [IRON_REMOVAL]: { flowRate: -1 } },
    [HORIZONTAL_10]:      { [AVERAGE]: { flowRate: 300 }, [POOR]: { flowRate: 250 }, [IRON_REMOVAL]: { flowRate: -1 } },
};

export const recommendedConfiguration: { [filtrationTechnologyOptions:number]:{ [model:number]: IFiltersModelScore } } = {
    [DISC_FILTERS]:   recommendedConfigurationDiscFilters,
    [MEDIA_FILTER]:   recommendedConfigurationMediaFilters,
    [SCREEN_FILTERS]: recommendedConfigurationScreenFilters
}