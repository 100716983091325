import React, { useEffect, useState } from "react";
import MyInput from "../generic/MyInput";
import styles from "../../assets/styles/components/pages/registration-page.module.scss"
import animation from "../../assets/styles/global/animations.module.scss";
import MyCheckBox, { ICheckboxData } from "../generic/MyCheckBox";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setNextDisabled } from "../../store/slices/quizStepsSlice";
import { getValidationFunction } from "../../services/validationHelperService";
import MySelect from "../generic/MySelect";
import { IRegistrationState, setTextFields } from "../../store/slices/registrationSlice";
import { COUNTRIES, IOption } from "../../constats/calculateData/countries";
import { isFieldsExist } from "../../services/formService";
import { getRoleOptions } from "../../services/languagesHelperService";
import { CHECKBOX_1_POLICY, CHECKBOX_1_TERMS, CHECKBOX_1_TEXT_1, CHECKBOX_1_TEXT_2, CHECKBOX_1_TEXT_3, CHECKBOX_2, FIELD_1, FIELD_2, FIELD_3, MANDATORY_FIELD_ERROR, PATTERN_ERROR } from "../../constats/languages/languagesKeys";
import { PRIVACY_POLICY_LINK, TERMS_AND_CONDITIONS_LINK, TERMS_AND_CONDITIONS_LINK_FRANCE, PRIVACY_POLICY_LINK_FRANCE } from "../../constats/links";
import { ELanguages } from "../../enums/settings";
const Resigtration = () => {
    const state = useSelector((state: RootState) => state.registrationReducer);
    const { firstName, lastName, email, isAcceptTermsAndPolicy } = state;
    const { language , languageKey } = useSelector((state: RootState) => state.settingsReducer);
    const [currentRoleIndex, setCurrentRoleIndex] = useState(0);
    const [currentCountryIndex, setCurrentCountryIndex] = useState(0);
    const [roleOptions, setRoleOptions] = useState(getRoleOptions(language));

    const dispatch = useDispatch();
    const roleField: string = 'role';
    const roleFieldKey = roleField as keyof IRegistrationState;
    const countryField: string = 'country';
    const countryFieldKey = countryField as keyof IRegistrationState;
    const linkText: ICheckboxData[] = [
        { preLinkText: `${language[CHECKBOX_1_TEXT_1]} `, linkText: `${language[CHECKBOX_1_TERMS]}`, link: languageKey===ELanguages.FRENCH ? TERMS_AND_CONDITIONS_LINK_FRANCE : TERMS_AND_CONDITIONS_LINK },
        { preLinkText: ` ${language[CHECKBOX_1_TEXT_2]} `, linkText: `${language[CHECKBOX_1_POLICY]}`, link: languageKey===ELanguages.FRENCH ? PRIVACY_POLICY_LINK_FRANCE : PRIVACY_POLICY_LINK },
    ]
    const linkLastText: string = language[CHECKBOX_1_TEXT_3] === '' ? "." : ` ${language[CHECKBOX_1_TEXT_3]}.`

    useEffect(() => {
        checkNextDisabled();
    }, [[firstName, lastName, email, isAcceptTermsAndPolicy]])

    useEffect(() => {
        setRoleOptions(getRoleOptions(language))
    }, [language])

    const checkNextDisabled = () => {
        if (isFieldsExist({ firstName, lastName, email, isAcceptTermsAndPolicy })) {
            dispatch(setNextDisabled({ data: false }));
        } else {
            dispatch(setNextDisabled({ data: true }));
        }
    }

    const setRole = (value: string) => {
        const currentIndex = roleOptions.findIndex(x => x.value === value)
        setCurrentRoleIndex(currentIndex);
        dispatch(setTextFields({ field: roleField, data: value }));
    }

    const setCountry = (value: string) => {
        const currentIndex = COUNTRIES.findIndex(x => x.value === value)
        setCurrentCountryIndex(currentIndex);
        dispatch(setTextFields({ field: countryField, data: value }));
    }

    const selectClasses: string[] = [
        styles["select-picker"],
        styles[`state-${state[roleFieldKey] ? 'valid' : 'default'}`]
    ]

    const autoCompleteClasses: string[] = [
        styles["select-picker"],
        styles[`state-${state[countryFieldKey] ? 'valid' : 'default'}`]
    ]

    return <>
        <div className={animation["slide-up-question"]}>
            <div className={styles['inputs-container']}>
                {<MyInput field="firstName" placeholder={language[FIELD_1]} defaultErrorMessage={language[MANDATORY_FIELD_ERROR]} patternErrorMessage={language[PATTERN_ERROR]} validationCheck={getValidationFunction('name')} />}
                {<MyInput field="lastName" placeholder={language[FIELD_2]} defaultErrorMessage={language[MANDATORY_FIELD_ERROR]} patternErrorMessage={language[PATTERN_ERROR]} validationCheck={getValidationFunction('name')} />}
                {<MyInput field="email" placeholder={language[FIELD_3]} defaultErrorMessage={language[MANDATORY_FIELD_ERROR]} patternErrorMessage={language[PATTERN_ERROR]} validationCheck={getValidationFunction('email')} />}
                <div className={selectClasses.join(" ")}>
                    <MySelect setField={setRole} currentIndex={currentRoleIndex} options={roleOptions} isSearchable={false} />
                </div>
                <div className={autoCompleteClasses.join(" ")}>
                    <MySelect showButton={false} currentIndex={currentCountryIndex} setField={setCountry} options={COUNTRIES} isSearchable={true}/>
                </div>
            </div>
            <div className={styles['inputs-container']}>
                {<MyCheckBox field="isAcceptTermsAndPolicy" linkLastText={linkLastText} linkText={linkText} />}
                {<MyCheckBox field="isLikeToReceiveUpdates" text={language[CHECKBOX_2]} />}
            </div>
        </div>
    </>
}

export default Resigtration;