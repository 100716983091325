import { EUnits } from "../enums/settings";
import { IUnits } from "../interfaces/settings";

export const UNITS: { [model: number]: IUnits } =
{
    [EUnits.METRIC]: {
        flowrateLabel: 'm³/h',
        flowrateFactor: 1,
        pressureLabel: 'bar',
        pressureFactor: 1,
    },
    [EUnits.US_UNITS]: {
        flowrateLabel: 'gpm',
        flowrateFactor: 3.666,
        pressureLabel: 'PSI',
        pressureFactor: 14.5,
    },

}