import { EBinaryOptions, EIrrigationMethodOptions, EMainWaterSourceOptions, ERangeOptions, ESuctionPointWaterSourceOptions } from '../../enums/inputsAnswers.enum'
import { IMySelectOption } from '../../interfaces/selectQuestion';
import canalSelectedIcon from "../../assets/images/icons/select-icons/canal-selected-icon.svg"
import canalUnselectedIcon from "../../assets/images/icons/select-icons/canal-unselected-icon.svg"
import lakeSelectedIcon from "../../assets/images/icons/select-icons/lake-selected-icon.svg"
import lakeUnselectedIcon from "../../assets/images/icons/select-icons/lake-unselected-icon.svg"
import riverSelectedIcon from "../../assets/images/icons/select-icons/riverl-selected-icon.svg"
import riverUnselectedIcon from "../../assets/images/icons/select-icons/riverl-unselected-icon.svg"
import reservoirSelectedIcon from "../../assets/images/icons/select-icons/reservoir-selected-icon.svg"
import reservoirUnselectedIcon from "../../assets/images/icons/select-icons/reservoir-unselected-icon.svg"
import lagoonSelectedIcon from "../../assets/images/icons/select-icons/lagoon-selected-icon.svg"
import lagoonUnselectedIcon from "../../assets/images/icons/select-icons/lagoon-unselected-icon.svg"
import wellSelectedIcon from "../../assets/images/icons/select-icons/well-selected-icon.svg"
import wellUnselectedIcon from "../../assets/images/icons/select-icons/well-unselected-icon.svg"
import wastewaterSelectedIcon from "../../assets/images/icons/select-icons/wastewater-selected-icon.svg"
import wastewaterUnselectedIcon from "../../assets/images/icons/select-icons/wastewater-unselected-icon.svg"
import farmSelectedIcon from "../../assets/images/icons/select-icons/farm-selected-icon.svg"
import farmUnselectedIcon from "../../assets/images/icons/select-icons/farm-unselected-icon.svg"
import industrialSelectedIcon from "../../assets/images/icons/select-icons/industrial-selected-icon.svg"
import industrialUnselectedIcon from "../../assets/images/icons/select-icons/industrial-unselected-icon.svg"
import poolSelectedIcon from "../../assets/images/icons/select-icons/pool-selected-icon.svg"
import poolUnselectedIcon from "../../assets/images/icons/select-icons/pool-unselected-icon.svg"
import tankSelectedIcon from "../../assets/images/icons/select-icons/tank-selected-icon.svg"
import tankUnselectedIcon from "../../assets/images/icons/select-icons/tank-unselected-icon.svg"
import structureSelectedIcon from "../../assets/images/icons/select-icons/structure-selected-icon.svg"
import structureUnselectedIcon from "../../assets/images/icons/select-icons/structure-unselected-icon.svg"
import { ANSWER2_1_1, ANSWER2_1_10, ANSWER2_1_2, ANSWER2_1_3, ANSWER2_1_4, ANSWER2_1_5, ANSWER2_1_6, ANSWER2_1_7, ANSWER2_1_8, ANSWER2_1_9, ANSWER2_2_1, ANSWER2_2_10, ANSWER2_2_11, ANSWER2_2_12, ANSWER2_2_2, ANSWER2_2_3, ANSWER2_2_4, ANSWER2_2_5, ANSWER2_2_6, ANSWER2_2_7, ANSWER2_2_8, ANSWER2_2_9 } from '../languages/languagesKeys';

const { PIVOT, OPEN_FIELD_SPRINKLERS, MICRO_SPRINKLERS, DRIPPERLINE_MULTI_SEASON_LOW, DRIPPERLINE_MULTI_SEASON_HIGH, DRIPPERLINE_SINGLE_SEASON, ON_LINE_DRIPPERS_LOW, ON_LINE_DRIPPERS_HIGH } = EIrrigationMethodOptions;
const { HIGH, MEDIUM, LOW } = ERangeOptions;
const { YES, NO } = EBinaryOptions;
const EMainWater = EMainWaterSourceOptions;
const ESuctionPoint = ESuctionPointWaterSourceOptions;

export const mainWaterSourceArr: IMySelectOption[] = [
    { field: 'mainWaterSource', value: EMainWater.CANAL, titleKey: ANSWER2_1_1, selectedIcon: canalSelectedIcon, unSelectedIcon: canalUnselectedIcon },
    { field: 'mainWaterSource', value: EMainWater.LAKE, titleKey: ANSWER2_1_2, selectedIcon:lakeSelectedIcon, unSelectedIcon:lakeUnselectedIcon },
    { field: 'mainWaterSource', value: EMainWater.RIVER, titleKey: ANSWER2_1_3, selectedIcon:riverSelectedIcon, unSelectedIcon:riverUnselectedIcon },
    { field: 'mainWaterSource', value: EMainWater.RESERVOIR, titleKey: ANSWER2_1_4, selectedIcon:reservoirSelectedIcon, unSelectedIcon:reservoirUnselectedIcon },
    { field: 'mainWaterSource', value: EMainWater.LAGOON, titleKey: ANSWER2_1_5, selectedIcon:lagoonSelectedIcon, unSelectedIcon:lagoonUnselectedIcon },
    { field: 'mainWaterSource', value: EMainWater.WELL, titleKey: ANSWER2_1_6, selectedIcon:wellSelectedIcon, unSelectedIcon:wellUnselectedIcon },
    { field: 'mainWaterSource', value: EMainWater.TREATED_WASTEWATER, titleKey: ANSWER2_1_7, selectedIcon:wastewaterSelectedIcon, unSelectedIcon:wastewaterUnselectedIcon },
    { field: 'mainWaterSource', value: EMainWater.FARM_EFFLUENCE, titleKey: ANSWER2_1_8, selectedIcon:farmSelectedIcon, unSelectedIcon:farmUnselectedIcon },
    { field: 'mainWaterSource', value: EMainWater.INDUSTRIAL_RECYCLE_WATER, titleKey: ANSWER2_1_9, selectedIcon:industrialSelectedIcon, unSelectedIcon:industrialUnselectedIcon },
    { field: 'mainWaterSource', value: EMainWater.NOT_APPLICABLE, titleKey: ANSWER2_1_10},
]

export const suctionPointWaterSourceArr: IMySelectOption[] = [
    { field: 'suctionPointWaterSource', value: ESuctionPoint.CANAL, titleKey: ANSWER2_2_1, selectedIcon: canalSelectedIcon, unSelectedIcon: canalUnselectedIcon },
    { field: 'suctionPointWaterSource', value: ESuctionPoint.LAKE, titleKey: ANSWER2_2_2, selectedIcon:lakeSelectedIcon, unSelectedIcon:lakeUnselectedIcon },
    { field: 'suctionPointWaterSource', value: ESuctionPoint.RIVER, titleKey: ANSWER2_2_3, selectedIcon:riverSelectedIcon, unSelectedIcon:riverUnselectedIcon },
    { field: 'suctionPointWaterSource', value: ESuctionPoint.RESERVOIR, titleKey: ANSWER2_2_4, selectedIcon:reservoirSelectedIcon, unSelectedIcon:reservoirUnselectedIcon },
    { field: 'suctionPointWaterSource', value: ESuctionPoint.LAGOON, titleKey: ANSWER2_2_5, selectedIcon:lagoonSelectedIcon, unSelectedIcon:lagoonUnselectedIcon },
    { field: 'suctionPointWaterSource', value: ESuctionPoint.WELL, titleKey: ANSWER2_2_6, selectedIcon:wellSelectedIcon, unSelectedIcon:wellUnselectedIcon },
    { field: 'suctionPointWaterSource', value: ESuctionPoint.OPERATIONAL_POOL, titleKey: ANSWER2_2_7, selectedIcon:poolSelectedIcon, unSelectedIcon:poolUnselectedIcon },
    { field: 'suctionPointWaterSource', value: ESuctionPoint.WATER_STORAGE_TANK, titleKey: ANSWER2_2_8, selectedIcon:tankSelectedIcon, unSelectedIcon:tankUnselectedIcon },
    { field: 'suctionPointWaterSource', value: ESuctionPoint.SEDIMENTATION_STRUCTURE, titleKey: ANSWER2_2_9, selectedIcon:structureSelectedIcon, unSelectedIcon:structureUnselectedIcon },
    { field: 'suctionPointWaterSource', value: ESuctionPoint.TREATED_WASTEWATER, titleKey: ANSWER2_2_10, selectedIcon:wastewaterSelectedIcon, unSelectedIcon:wastewaterUnselectedIcon },
    { field: 'suctionPointWaterSource', value: ESuctionPoint.FARM_EFFLUENCE, titleKey: ANSWER2_2_11, selectedIcon:farmSelectedIcon, unSelectedIcon:farmUnselectedIcon },
    { field: 'suctionPointWaterSource', value: ESuctionPoint.INDUSTRIAL_RECYCLE_WATER, titleKey: ANSWER2_2_12, selectedIcon:industrialSelectedIcon, unSelectedIcon:industrialUnselectedIcon },
]