
// GENERAL
export const CONTACT_NETAFIM_LINK: string = "mailto:Products_Solutions@netafim.com"
export const TERMS_AND_CONDITIONS_LINK: string = "https://www.netafim.com/en/terms-and-conditions/"
export const TERMS_AND_CONDITIONS_LINK_FRANCE: string = "https://www.netafim.fr/termes-et-conditions/"
export const PRIVACY_POLICY_LINK: string = "https://www.netafim.com/en/privacy-policy/"
export const PRIVACY_POLICY_LINK_FRANCE: string ="https://www.netafim.fr/politique-de-confidentialite/"
export const DRAWING_PDFS_PATH: string = "https://filterconfig.netafim.com/pdf/"
export const SEDIMENTATION_GUIDLINE: string = "https://filterconfig.netafim.com/pdf/Sedimentation%20reservoir%20guidline.pdf"



