import { useDispatch, useSelector } from "react-redux";
import styles from "../../assets/styles/components/generic/settings-bar.module.scss"
import { ELanguages, EUnits } from "../../enums/settings";
import { getLanguegsOptions } from "../../services/languagesHelperService";
import { useSetLanguage } from "../../services/settingsService";
import { RootState } from "../../store";
import { setIsHide, setUnits } from "../../store/slices/settingsSlice";
import MySelect from "./MySelect";
import unselectedLogo from "../../assets/images/icons/select-icons/unselected-default-icon.svg"
import selectedLogo from "../../assets/images/icons/select-icons/selected-default-icon.svg"
import { LANGUAGE_CONVERSION_TITLE, UNITS_CONVERSION_METRIC, UNITS_CONVERSION_TITLE, UNITS_CONVERSION_US } from "../../constats/languages/languagesKeys";
import { useEffect, useRef } from "react";

const { METRIC, US_UNITS } = EUnits;


const SettingsBar = () => {

    const { units, currentIndex, languagesOptions, language, isHide } = useSelector((state: RootState) => state.settingsReducer);
    const setLanguage = useSetLanguage();
    const dispatch = useDispatch();

    const handleUnitsClick = (units: EUnits) => {
        dispatch(setUnits({ data: units }));
    }

    const handleClose = (value: boolean) => {
        dispatch(setIsHide({ value: true }));
    }

    const barClasses = [styles["settings-bar"]];
    isHide && barClasses.push(styles["hide"]);

    const dimClasses = [styles["dim"]];
    if (isHide) {
        dimClasses.push(styles["none"])
    }

    return <>
        <div className={barClasses.join(" ")} onClick={(e) => e.stopPropagation()}>
            <h3 className={styles['sub-title']}>{language[UNITS_CONVERSION_TITLE]}</h3>
            <div className={styles['radio-options-containet']}>
                <h3 onClick={() => handleUnitsClick(METRIC)} className={styles['option-containet']}><img className={styles['icon-containet']} src={units === METRIC ? selectedLogo : unselectedLogo} />{language[UNITS_CONVERSION_METRIC]}</h3>
                <h3 onClick={() => handleUnitsClick(US_UNITS)} className={styles['option-containet']}><img className={styles['icon-containet']} src={units === US_UNITS ? selectedLogo : unselectedLogo} />{language[UNITS_CONVERSION_US]}</h3>
            </div>
            <h3 className={styles['sub-title']}>{language[LANGUAGE_CONVERSION_TITLE]}</h3>
            <MySelect setField={setLanguage} currentIndex={currentIndex} options={languagesOptions} isSearchable={false}/>
        </div>
        <div className={dimClasses.join(" ")} onClick={() => handleClose(true)}></div>
    </>

}

export default SettingsBar;