import styles from "../../assets/styles/components/generic/header.module.scss"
import TopHeader from "./TopHeader";
import BottomHeader from "./BottomHeader";

const Header = () => {

    return (<header className={styles["header"]}>
        <TopHeader />
        <BottomHeader />
    </header>)

}
export default Header;