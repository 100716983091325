
import { EWaterQuality } from "../enums/waterQuality.enum";
import { ICalculationFunctions, IRecommendedModels } from "../interfaces/calculations";
import { recommendedConfiguration } from "../constats/calculateData/recommendedConfiguration";
import { AlphaDisc, Apollo, EDiscFilterModels, EFiltrationTechnologyOptions, EMediaFilterModels, EScreenFilterModels, Horizontal, SpinKlin, Vertical } from "../enums";
import { UNINITIALIZED_VALUE } from "../constats/calculateData/global";

const { AVERAGE, POOR, IRON_REMOVAL } = EWaterQuality;
const { SANDSTORM_20, SANDSTORM_24, SANDSTORM_30, SANDSTORM_36, SANDSTORM_48, } = EMediaFilterModels;
const { SPINKLIN_2, SPINKLIN_3, APOLLO_ANGLE, APOLLO_TWINS, ALPHA_DISC_SINGLE_3, ALPHA_DISC_SINGLE_XL_3, ALPHA_DISC_SINGLE_XL_4, ALPHA_DISC_DUAL_XL, ALPHA_DISC_TRIO_XL } = EDiscFilterModels;
const { VERTICAL_2, VERTICAL_3, VERTICAL_3_JUMBO, VERTICAL_4, VERTICAL_4_JUMBO, VERTICAL_6, HORIZONTAL_4, HORIZONTAL_4_JUMBO, HORIZONTAL_6, HORIZONTAL_6_JUMBO, HORIZONTAL_8, HORIZONTAL_10 } = EScreenFilterModels;
const { DISC_FILTERS, MEDIA_FILTER, SCREEN_FILTERS } = EFiltrationTechnologyOptions;

export const calcFilterModelsGeneric = (technology: EFiltrationTechnologyOptions, flowRate: number, waterQuality: EWaterQuality): IRecommendedModels[] => {
    const { getFilterModels, calcFilterModelsUnits, sortModelsByPriority } = getFilterModelsCalculationFunctions(technology);

    const filterModels = getFilterModels(flowRate, waterQuality);

    let tempModelsArr: IRecommendedModels[] = [];
    let tempUnits: number = 0, currFlowRate: number;

    filterModels.forEach((model) => {
        tempUnits = UNINITIALIZED_VALUE;
        if (technology !== UNINITIALIZED_VALUE && model !== UNINITIALIZED_VALUE) {
            currFlowRate = recommendedConfiguration[technology][model][waterQuality]?.flowRate;
            if (currFlowRate) {
                tempUnits = calcFilterModelsUnits(currFlowRate, flowRate, model, waterQuality);
            }
            if (tempUnits !== UNINITIALIZED_VALUE) {
                tempModelsArr.push(
                    {
                        technology: technology,
                        model: model,
                        units: tempUnits,
                        inletOutletDiameter: UNINITIALIZED_VALUE,
                        isPressureMaxNotification: false,
                        isPressureMinNotification: false
                    });
            }
        }
    })

    const sortedArr = tempModelsArr.sort(sortModelsByPriority);

    return sortedArr;
}

const getFilterModelsCalculationFunctions = (technology: EFiltrationTechnologyOptions): ICalculationFunctions => {
    let getFilterModels: (flowRate: number, waterQuality: number) => number[];
    let calcFilterModelsUnits: (modelFlowRate: number, userFlowRate: number, model: number, waterQuality: number) => number;
    let sortModelsByPriority: (a: IRecommendedModels, b: IRecommendedModels) => number;

    if (technology === MEDIA_FILTER) {
        getFilterModels = getMediaFilterModels;
        calcFilterModelsUnits = getMediaFilterModelUnits;
        sortModelsByPriority = sortMediaFilter;
    } else if (technology === DISC_FILTERS) {
        getFilterModels = getDiscFilterModels;
        calcFilterModelsUnits = getDiscFilterModelUnits;
        sortModelsByPriority = sortDiscFilter;
    } else if (technology === SCREEN_FILTERS) {
        getFilterModels = getScreenFilterModels;
        calcFilterModelsUnits = getScreenFilterModelUnits;
        sortModelsByPriority = sortScreenFilter;
    } else {
        throw new Error('ERROR: Filtration Technology Options is wrong');
    }

    return { getFilterModels, calcFilterModelsUnits, sortModelsByPriority };
}

// ------------------ MEDIA FILTER ------------------ //
const getMediaFilterModels = (flowRate: number, waterQuality: number): number[] => {
    let filterModels: number[] = [];

    switch (waterQuality) {
        case AVERAGE:
            if (flowRate >= 15 && flowRate <= 45) {
                filterModels = [SANDSTORM_20];
            } else if (flowRate >= 46 && flowRate <= 150) {
                filterModels = [SANDSTORM_24, SANDSTORM_30, SANDSTORM_36];
            } else if (flowRate >= 151 && flowRate <= 200) {
                filterModels = [SANDSTORM_36];
            } else if (flowRate >= 201 && flowRate <= 300) {
                filterModels = [SANDSTORM_36, SANDSTORM_48];
            } else if (flowRate >= 301 && flowRate <= 840) {
                filterModels = [SANDSTORM_48];
            }
            break;
        case POOR:
            if (flowRate >= 15 && flowRate <= 45) {
                filterModels = [SANDSTORM_20];
            } else if (flowRate >= 46 && flowRate <= 150) {
                filterModels = [SANDSTORM_24, SANDSTORM_30, SANDSTORM_36];
            } else if (flowRate >= 151 && flowRate <= 200) {
                filterModels = [SANDSTORM_36];
            } else if (flowRate >= 201 && flowRate <= 300) {
                filterModels = [SANDSTORM_36, SANDSTORM_48];
            } else if (flowRate >= 301 && flowRate <= 840) {
                filterModels = [SANDSTORM_48];
            }
            break;
        case IRON_REMOVAL:
            if (flowRate >= 12 && flowRate <= 30) {
                filterModels = [SANDSTORM_20];
            } else if (flowRate >= 31 && flowRate <= 104) {
                filterModels = [SANDSTORM_24, SANDSTORM_30, SANDSTORM_36];
            } else if (flowRate >= 105 && flowRate <= 152) {
                filterModels = [SANDSTORM_36];
            } else if (flowRate >= 153 && flowRate <= 396) {
                filterModels = [SANDSTORM_48];
            }
            break;

        default:
            break;
    }

    return filterModels;
}

const getMediaFilterModelUnits = (modelFlowRate: number, userFlowRate: number, model: EMediaFilterModels, waterQuality: number): number => {
    let unitsNumber: number = 0;

    unitsNumber = Math.ceil(userFlowRate / modelFlowRate);
    if (waterQuality === IRON_REMOVAL) {
        let min: number = 0, max: number = 0;
        switch (model) {
            case SANDSTORM_20:
                min = 2
                max = 5
                break;
            case SANDSTORM_24:
                min = 4
                max = 10
                break;
            case SANDSTORM_30:
                min = 4
                max = 8
                break;
            case SANDSTORM_36:
                min = 4
                max = 8
                break;
            case SANDSTORM_48:
                min = 5
                max = 12
                break;
            default:
                break;
        }
        unitsNumber = unitsNumber < min ? min : unitsNumber;
        unitsNumber = unitsNumber > max ? UNINITIALIZED_VALUE : unitsNumber;
    } else {
        unitsNumber = unitsNumber < 3 ? 3 : unitsNumber;
        if (model === SANDSTORM_48) {
            unitsNumber = unitsNumber > 12 ? UNINITIALIZED_VALUE : unitsNumber;
        } else {
            unitsNumber = unitsNumber > 8 ? UNINITIALIZED_VALUE : unitsNumber;
        }
    }

    return unitsNumber;
}

const sortMediaFilter = (a: IRecommendedModels, b: IRecommendedModels) => {
    return b.units - a.units
}

// ------------------ DISC FILTERS ------------------ //
const getDiscFilterModels = (flowRate: number, waterQuality: number): number[] => {
    let filterModels: number[] = [];

    switch (waterQuality) {
        case AVERAGE:
            if (flowRate >= 10 && flowRate <= 90) {
                filterModels = [...filterModels, SPINKLIN_2];
            } if (flowRate >= 10 && flowRate <= 40) {
                filterModels = [...filterModels, ALPHA_DISC_SINGLE_3];
            } if (flowRate >= 41 && flowRate <= 60) {
                filterModels = [...filterModels, ALPHA_DISC_SINGLE_XL_3];
            } if (flowRate >= 61 && flowRate <= 90) {
                filterModels = [...filterModels, ALPHA_DISC_SINGLE_XL_4];
            } if (flowRate >= 91 && flowRate <= 180) {
                filterModels = [...filterModels, SPINKLIN_3];
            } if (flowRate >= 91 && flowRate <= 180) {
                filterModels = [...filterModels, ALPHA_DISC_DUAL_XL];
            } if (flowRate >= 105 && flowRate <= 210) {
                filterModels = [...filterModels, APOLLO_ANGLE];
            } if (flowRate >= 181 && flowRate <= 270) {
                filterModels = [...filterModels, ALPHA_DISC_TRIO_XL];
            } if (flowRate >= 211 && flowRate <= 560) {
                filterModels = [...filterModels, APOLLO_TWINS];
            }
            break;
        case POOR:
            if (flowRate >= 10 && flowRate <= 84) {
                filterModels = [...filterModels, SPINKLIN_2];
            } if (flowRate >= 10 && flowRate <= 35) {
                filterModels = [...filterModels, ALPHA_DISC_SINGLE_3];
            } if (flowRate >= 36 && flowRate <= 50) {
                filterModels = [...filterModels, ALPHA_DISC_SINGLE_XL_3];
            } if (flowRate >= 51 && flowRate <= 80) {
                filterModels = [...filterModels, ALPHA_DISC_SINGLE_XL_4];
            } if (flowRate >= 72 && flowRate <= 144) {
                filterModels = [...filterModels, SPINKLIN_3];
            } if (flowRate >= 81 && flowRate <= 160) {
                filterModels = [...filterModels, ALPHA_DISC_DUAL_XL];
            } if (flowRate >= 91 && flowRate <= 210) {
                filterModels = [...filterModels, APOLLO_ANGLE];
            } if (flowRate >= 161 && flowRate <= 240) {
                filterModels = [...filterModels, ALPHA_DISC_TRIO_XL];
            } if (flowRate >= 181 && flowRate <= 480) {
                filterModels = [...filterModels, APOLLO_TWINS];
            }
            break;

        default:
            break;
    }

    return filterModels;
}

const getDiscFilterModelUnits = (modelFlowRate: number, userFlowRate: number, model: EDiscFilterModels, waterQuality: number): number => {
    let unitsNumber: number = 0;

    unitsNumber = Math.ceil(userFlowRate / modelFlowRate);

    if (!AlphaDisc.includes(model)) {
        unitsNumber = unitsNumber <= 3 ? unitsNumber + 1 : unitsNumber;
    }

    return unitsNumber;
}

const sortDiscFilter = (a: IRecommendedModels, b: IRecommendedModels) => {
    let res: number = 0;

    if (areDiscModelsOfTheSameType(a, b)) {
        res = a.units - b.units
    } else if (AlphaDisc.includes(a.model) || AlphaDisc.includes(b.model)) {
        res = AlphaDisc.includes(a.model) ? -1 : 1;
    } else if (Apollo.includes(a.model) || Apollo.includes(b.model)) {
        res = Apollo.includes(a.model) ? -1 : 1;
    }

    return res

}
const areDiscModelsOfTheSameType = (a: IRecommendedModels, b: IRecommendedModels): boolean => {
    const isAlphaDisc = AlphaDisc.includes(a.model) && AlphaDisc.includes(b.model);
    const isApollo = Apollo.includes(a.model) && Apollo.includes(b.model);
    const isSpinKlin = SpinKlin.includes(a.model) && SpinKlin.includes(b.model);

    return isAlphaDisc || isApollo || isSpinKlin;
}
// ------------------ SCREEN FILTERS ------------------ //

const getScreenFilterModels = (flowRate: number, waterQuality: number): number[] => {
    let filterModels: number[] = [];

    switch (waterQuality) {
        case AVERAGE:
            if (flowRate >= 12 && flowRate <= 25) {
                filterModels = [...filterModels, VERTICAL_2];
            } if (flowRate >= 26 && flowRate <= 35) {
                filterModels = [...filterModels, VERTICAL_3];
            } if (flowRate >= 36 && flowRate <= 50) {
                filterModels = [...filterModels, VERTICAL_3_JUMBO];
            } if (flowRate >= 51 && flowRate <= 75) {
                filterModels = [...filterModels, VERTICAL_4];
            } if (flowRate >= 61 && flowRate <= 80) {
                filterModels = [...filterModels, VERTICAL_4_JUMBO];
            } if (flowRate >= 81 && flowRate <= 100) {
                filterModels = [...filterModels, VERTICAL_6];
            } if (flowRate >= 50 && flowRate <= 80) {
                filterModels = [...filterModels, HORIZONTAL_4];
            } if (flowRate >= 81 && flowRate <= 100) {
                filterModels = [...filterModels, HORIZONTAL_4_JUMBO];
            } if (flowRate >= 101 && flowRate <= 120) {
                filterModels = [...filterModels, HORIZONTAL_6];
            } if (flowRate >= 121 && flowRate <= 150) {
                filterModels = [...filterModels, HORIZONTAL_6_JUMBO];
            } if (flowRate >= 151 && flowRate <= 250) {
                filterModels = [...filterModels, HORIZONTAL_8];
            } if (flowRate >= 251 && flowRate <= 300) {
                filterModels = [...filterModels, HORIZONTAL_10];
            }
            break;
        case POOR:
            if (flowRate >= 12 && flowRate <= 15) {
                filterModels = [...filterModels, VERTICAL_2];
            } if (flowRate >= 16 && flowRate <= 25) {
                filterModels = [...filterModels, VERTICAL_3];
            } if (flowRate >= 26 && flowRate <= 40) {
                filterModels = [...filterModels, VERTICAL_3_JUMBO];
            } if (flowRate >= 41 && flowRate <= 65) {
                filterModels = [...filterModels, VERTICAL_4];
            } if (flowRate >= 51 && flowRate <= 70) {
                filterModels = [...filterModels, VERTICAL_4_JUMBO];
            } if (flowRate >= 71 && flowRate <= 85) {
                filterModels = [...filterModels, VERTICAL_6];
            } if (flowRate >= 50 && flowRate <= 75) {
                filterModels = [...filterModels, HORIZONTAL_4];
            } if (flowRate >= 76 && flowRate <= 90) {
                filterModels = [...filterModels, HORIZONTAL_4_JUMBO];
            } if (flowRate >= 91 && flowRate <= 120) {
                filterModels = [...filterModels, HORIZONTAL_6];
            } if (flowRate >= 121 && flowRate <= 150) {
                filterModels = [...filterModels, HORIZONTAL_6_JUMBO];
            } if (flowRate >= 151 && flowRate <= 250) {
                filterModels = [...filterModels, HORIZONTAL_8];
            } if (flowRate >= 251 && flowRate <= 300) {
                filterModels = [...filterModels, HORIZONTAL_10];
            }
            break;
        default:
            break;
    }

    return filterModels;
}

const getScreenFilterModelUnits = (modelFlowRate: number, userFlowRate: number, model: EDiscFilterModels, waterQuality: number): number => {
    let unitsNumber: number = 0;
    unitsNumber = Math.ceil(userFlowRate / modelFlowRate);

    return unitsNumber;
}

const sortScreenFilter = (a: IRecommendedModels, b: IRecommendedModels) => {
    let res: number = 0;

    if (areScreenModelsOfTheSameType(a, b)) {
        res = a.units - b.units
    }
    else { // (Vertical.includes(a.model) || Vertical.includes(b.model)) 
        res = Vertical.includes(a.model) ? -1 : 1;
    }

    return res

}

const areScreenModelsOfTheSameType = (a: IRecommendedModels, b: IRecommendedModels): boolean => {
    const isVertical = Vertical.includes(a.model) && Vertical.includes(b.model);
    const isHorizontal = Horizontal.includes(a.model) && Horizontal.includes(b.model);

    return isVertical || isHorizontal;
}

