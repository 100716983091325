
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../assets/styles/components/generic/my-checkbox.module.scss"
import activeLogo from "../../assets/images/icons/checkbox-active.svg"
import inactiveLogo from "../../assets/images/icons/checkbox-inactive.svg"
import { changeBooleanFields, IRegistrationState, registrationReducer } from "../../store/slices/registrationSlice";
import { RootState } from "../../store";

export type props = {
    field: string;
    text?: string;
    linkLastText?: string
    linkText?: ICheckboxData[];
}

export interface ICheckboxData {
    preLinkText: string;
    linkText: string;
    link: string;
}

const MyCheckBox = ({ field, text, linkText, linkLastText }: props) => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.registrationReducer);
    const fieldKey = field as keyof IRegistrationState;

    const handleChange = () => {
        dispatch(changeBooleanFields({ field }));
    }

    return (
        <div className={styles['checkbox-container']} onClick={handleChange}>
            <img className={styles['checkbox-icon']} src={state[fieldKey] ? activeLogo : inactiveLogo} />
            {text && <h4>{text}</h4>}
            {linkText?.length == 2 &&
                <h4>{linkText[0].preLinkText}<a target="_blank" rel="noopener noreferrer" href={linkText[0].link} onClick={(e) => { e.stopPropagation() }}>{linkText[0].linkText}</a>
                    {linkText[1].preLinkText}<a target="_blank" rel="noopener noreferrer" href={linkText[1].link} onClick={(e) => { e.stopPropagation() }}>{linkText[1].linkText}</a>
                    {linkLastText}
                </h4>
            }
        </div>
    )
}
export default MyCheckBox;