import { useEffect, useState } from "react";
import questionStyles from "../../assets/styles/components/generic/question-container.module.scss"
import selectStyles from "../../assets/styles/components/generic/my-select.module.scss"
import { RootState } from "../../store";
import { useDispatch, useSelector } from 'react-redux';
import { IInputParamsState, setGeneral } from "../../store/slices/inputParamsSlice";
import vLogo from "../../assets/images/icons/v-icon-circle.svg"
import editLogo from "../../assets/images/icons/edit-icon.svg"
import unselectedLogo from "../../assets/images/icons/select-icons/unselected-default-icon.svg"
import selectedLogo from "../../assets/images/icons/select-icons/selected-default-icon.svg"
import { IMySelectOption } from "../../interfaces/selectQuestion";
import { UNINITIALIZED_VALUE } from "../../constats/calculateData/global";

export type props = {
    field: string,
    deselectOption:boolean,
    title: string,
    questionNumber: number,
    valuesArr: IMySelectOption[],
    isPicturesIcons: boolean,
    uninitValue?: any,
    currentOpenQuestion: number | null;
    openQuestion: (type: 'newQuestion' | 'lastQuestion', value: number) => void;
    closeQuestion: (currentQuestion: number) => void;
    setValue?: (data: number) => void;
}

const SelectQuestion = ({ field, deselectOption, title, questionNumber, valuesArr, isPicturesIcons, uninitValue, currentOpenQuestion, openQuestion, closeQuestion, setValue }: props) => {

    const state = useSelector((state: RootState) => state.inputParamsReducer);
    const { language } = useSelector((state: RootState) => state.settingsReducer);
    const dispatch = useDispatch();
    const [isSet, setIsSet] = useState(false);
    const [selectedValue, setSelectedValue] = useState<IMySelectOption | null>(null);
    const [selectedValuesArr, setselectedValuesArr] = useState<IMySelectOption[]>(valuesArr);
    const iconTypes: 'default' | 'pictures' = isPicturesIcons ? 'pictures' : 'default';
    const fieldKey = field as keyof IInputParamsState;
    const unInitValue = uninitValue ? uninitValue : UNINITIALIZED_VALUE;

    useEffect(() => {
        setIsSet(state[fieldKey] !== unInitValue);
        setselectedValuesArr(setValuesArr());
    }, [])

    useEffect(() => {
        if (field === 'suctionPointWaterSource' && state[fieldKey] === unInitValue) {
            setselectedValuesArr(setValuesArr());
            openQuestion('newQuestion', questionNumber)
            setIsSet(false);
            setSelectedValue(null)
            setselectedValuesArr(initValuesArr());
        }
    }, [valuesArr])

    const setValuesArr = (): IMySelectOption[] => {
        return valuesArr.map((option) => {
            let returnOption = option;
            returnOption.isSelected = option.value === state[fieldKey];

            if (option.isSelected) {
                setSelectedValue(option);
                openQuestion('lastQuestion', questionNumber)
            }

            if (!isPicturesIcons) {
                returnOption.unSelectedIcon = unselectedLogo;
                returnOption.selectedIcon = selectedLogo;
            }

            return returnOption;
        })
    }

    const initValuesArr = (): IMySelectOption[] => {
        return valuesArr.map((option) => {
            let returnOption = option;
            returnOption.isSelected = false;
            return returnOption;
        })
    }

    const handleSelection = (option: IMySelectOption) => {
        let data: number = option.value;
        updateSelectedValuesArr(option);
        setTimeout(() => {
            openQuestion('lastQuestion', questionNumber);
            if (deselectOption && selectedValue?.value === option.value) {
                setSelectedValue(null)
                data = UNINITIALIZED_VALUE
                setIsSet(false);
            }
            else {
                setIsSet(true);
                setSelectedValue(option)
            }
            if (setValue) {
                setValue(data)
            } else {
                dispatch(setGeneral({ field, data }));
            }
        }, 200);
    }

    const updateSelectedValuesArr = (newValue: IMySelectOption) => {
        const newArr = valuesArr.map((option) => {
            if(option.isSelected && option.value === newValue.value && deselectOption) {
                option.isSelected = false
            } else {
                option.isSelected = option.value === newValue.value;
            }
            return option
        })
        setselectedValuesArr(newArr);
    }

    const handleEditClicked = () => {
        currentOpenQuestion === questionNumber ? closeQuestion(questionNumber) : openQuestion('newQuestion', questionNumber);
    }

    const questionClasses: string[] = [
        questionStyles['input-question-container'],
    ];

    const iconClasses: string[] = [
        questionStyles['icon-circel'],
        questionStyles[`${isSet ? 'v-circle' : 'number-icon'}`],
    ];

    const valuesArrClasses: string[] = [
        selectStyles['select-container'],
        selectStyles[`${selectedValuesArr.length <= 5 ? 'one-column' : 'two-column'}`],
    ];

    return (
        <div className={questionClasses.join(" ")} onClick={handleEditClicked}>
            <div className={questionStyles['titles-container']}>
                {isSet ?
                    <div className={iconClasses.join(" ")}>
                        <img className={questionStyles['v-icon']} src={vLogo}></img>
                    </div> :
                    <div className={iconClasses.join(" ")}>{questionNumber}</div>
                }
                <h3 className={questionStyles["question-title"]} >{title}</h3>
            </div>
            <div className={questionStyles['value-container']}>
                {currentOpenQuestion === questionNumber &&
                    <div className={valuesArrClasses.join(" ")}>
                        {selectedValuesArr.map((option) => {
                            return (
                                <div key={option.value} className={[selectStyles['option-container'], selectStyles[`option-${option.isSelected ? 'selected' : 'unselected'}`]].join(" ")}
                                    onClick={(e) => { handleSelection(option); e.stopPropagation() }}>
                                    {option.selectedIcon && <img src={option.isSelected ? option.selectedIcon : option.unSelectedIcon}
                                        className={option.isSelected ? selectStyles[`selected-${iconTypes}`] : selectStyles[`unselected-${iconTypes}`]} />}
                                    <h3 className={selectStyles['option-title']}>{language[option.titleKey]}</h3>
                                </div>)
                        })}
                    </div>}
                {!(currentOpenQuestion === questionNumber) && isSet &&
                    <div className={questionStyles['results-container']}>
                        <h3 className={questionStyles['results-value']}>{language[selectedValue?.titleKey!]}</h3>
                        <img className={questionStyles['edit-icon']} src={editLogo}></img>
                    </div>
                }
            </div>
        </div>
    );
}

export default SelectQuestion;