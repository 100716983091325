import { useEffect, useState } from "react";
import questionStyles from "../../assets/styles/components/generic/question-container.module.scss"
import binaryQuestionStyles from "../../assets/styles/components/generic/binary-question.module.scss"
import { RootState } from "../../store";
import { useDispatch, useSelector } from 'react-redux';
import { IInputParamsState, setGeneral } from "../../store/slices/inputParamsSlice";
import vLogo from "../../assets/images/icons/v-icon-circle.svg"
import editLogo from "../../assets/images/icons/edit-icon.svg"
import alertLogo from "../../assets/images/icons/alert-icon-blue.svg"
import { UNINITIALIZED_VALUE } from "../../constats/calculateData/global";
import { IMySelectOption } from "../../interfaces/selectQuestion";
import { EBinaryOptions } from "../../enums";
import { ANSWER3_4_1, ANSWER3_4_2 } from "../../constats/languages/languagesKeys";

const { YES, NO } = EBinaryOptions;

export type props = {
  field: string,
  title: string,
  questionNumber: number,
  message?: string,
  currentOpenQuestion: number | null;
  openQuestion: (type: 'newQuestion' | 'lastQuestion', value: number) => void;
  closeQuestion: (newQuestion: number) => void;
}


const BinaryQuestion = ({ field, title, questionNumber, currentOpenQuestion, openQuestion, closeQuestion, message }: props) => {

  const state = useSelector((state: RootState) => state.inputParamsReducer);
  const { language } = useSelector((state: RootState) => state.settingsReducer);
  const dispatch = useDispatch();
  const [isSet, setIsSet] = useState<boolean>();
  const fieldKey = field as keyof IInputParamsState;

  const yesButton: IMySelectOption = { field: field, value: YES, titleKey: ANSWER3_4_1 };
  const noButton: IMySelectOption = { field: field, value: NO, titleKey: ANSWER3_4_2 };

  useEffect(() => {
    setIsSet(state[fieldKey] !== UNINITIALIZED_VALUE);
  }, [])

  const questionClasses: string[] = [
    questionStyles['input-question-container'],
    questionStyles[`${isSet ? 'value-set' : 'value-unset'}`],
];

  const iconClasses: string[] = [
      questionStyles['icon-circel'],
      questionStyles[`${isSet ? 'v-circle' : 'number-icon'}`],
  ];

  const handleEditClicked = () => {
    currentOpenQuestion === questionNumber ? closeQuestion(questionNumber) : openQuestion('newQuestion', questionNumber);
}

  const handleButtonClick = (option: IMySelectOption) => {
    let selectedOption = state[fieldKey] === option.value;
    dispatch(
      setGeneral({
        field,
        data: selectedOption ? UNINITIALIZED_VALUE : option.value,
      })
    );

    setTimeout(() => {
      selectedOption ? setIsSet(false) : setIsSet(true);
      openQuestion("lastQuestion", questionNumber);
    }, 200);
  };

  const yesButtonClasses: string[] = [
    binaryQuestionStyles["button"],
    state[fieldKey] === YES ? binaryQuestionStyles['state-selected'] : binaryQuestionStyles['state-unselected'],
  ];

  const noButtonClasses: string[] = [
    binaryQuestionStyles["button"],
    state[fieldKey] === NO ? binaryQuestionStyles['state-selected'] : binaryQuestionStyles['state-unselected'],
  ];

  return (
    <div className={questionClasses.join(" ")} onClick={handleEditClicked}>
        <div className={questionStyles['titles-container']} >
            {isSet ?
                <div className={iconClasses.join(" ")}>
                    <img className={questionStyles['v-icon']} src={vLogo}></img>
                </div> :
                <div className={iconClasses.join(" ")}><h3>{questionNumber}</h3></div>
            }
            <h3 className={questionStyles["question-title"]} >{title}</h3>
        </div>
        <div className={questionStyles['value-container']}>
            {currentOpenQuestion === questionNumber &&
                <div>
                    {message && <div className={binaryQuestionStyles['message-container']}>
                        <img className={binaryQuestionStyles['alert-icon']} src={alertLogo} />
                        <h3 className={binaryQuestionStyles['message-text']}>{message}</h3>
                    </div>}
                    <div className={binaryQuestionStyles['buttons-contaier']}>
                        <div className={yesButtonClasses.join(" ")} onClick={(e) => { handleButtonClick(yesButton); e.stopPropagation() }}>{language[yesButton.titleKey]}</div>
                        <div className={binaryQuestionStyles["space"]} />
                        <div className={noButtonClasses.join(" ")} onClick={(e) => { handleButtonClick(noButton); e.stopPropagation() }} >{language[noButton.titleKey]}</div>
                    </div>
                </div>}
            {!(currentOpenQuestion === questionNumber) && isSet &&
                <div onClick={handleEditClicked} className={questionStyles['results-container']}>
                    <h3 className={questionStyles['results-value']}>{state[fieldKey] ? language[yesButton.titleKey] : language[noButton.titleKey]}</h3>
                    <img className={questionStyles['edit-icon']} src={editLogo}></img>
                </div>
            }
        </div>
      </div>
    );
  }

export default BinaryQuestion;
