import React, { useState } from 'react';
import './App.css';
import Welcome from './components/pages/Welcome';
import MainPage from './components/pages/MainPage';
import TempDevView from './components/pages/TempDevView';
import { useSelector } from 'react-redux';
import { RootState } from './store';

function App() {

  const [isWelcomeScreen, setIsWelcomeScreen] = useState<boolean>(true);
  const [isMainPage, setIsMainPage] = useState<boolean>(false);

  const welcomeScreenFinishHandler = () => {
    setIsWelcomeScreen(false);
    setIsMainPage(true);
  }

  return (
    <>
      { isWelcomeScreen && <Welcome onFinish={welcomeScreenFinishHandler} />}
      { isMainPage && <MainPage />}

    </>
  );
}

export default App;
