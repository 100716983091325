import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UNINITIALIZED_VALUE } from "../../constats/calculateData/global";
import { RootState } from "../../store";
import animation from "../../assets/styles/global/animations.module.scss";
import { irrigationMethodArr } from "../../constats/formQuestion/selectQuestionsStep1";
import SelectQuestion from "../questions/SelectQuestion";
import InputQuestion from "../questions/InputQuestion";
import { setNextDisabled } from "../../store/slices/quizStepsSlice";
import { getNextQuestionNumber, isFieldsFilled } from "../../services/formService";
import { EUnits } from "../../enums/settings";
import { QUESTION1_1, QUESTION1_2, QUESTION1_2_ERROR_LETTERS, QUESTION1_2_ERROR_M, QUESTION1_2_ERROR_US, QUESTION1_3 } from "../../constats/languages/languagesKeys";

const QuizStep1 = () => {

    const { language, units, unitsParams } = useSelector((state: RootState) => state.settingsReducer);
    const { flowRate, pressure, irrigationMethod } = useSelector((state: RootState) => state.inputParamsReducer);
    const dispatch = useDispatch();
    const [isQuestion1Set, setIsQuestion1Set] = useState(false);
    const [isQuestion2Set, setIsQuestion2Set] = useState(false);
    const [currentOpenQuestion, setCurrentOpenQuestion] = useState<number | null>(1);
    const minPressure = units === EUnits.US_UNITS ? 29 : 2;
    const maxPressure = units === EUnits.US_UNITS ? 232 : 16;
    const errorMessageKey = units === EUnits.US_UNITS ? QUESTION1_2_ERROR_US : QUESTION1_2_ERROR_M;

    useEffect(() => {
        checkNextDisabled();
    }, [])

    useEffect(() => {
        isFieldsFilled({ flowRate }) ? setIsQuestion1Set(true) : setIsQuestion1Set(false)
        isFieldsFilled({ pressure }) ? setIsQuestion2Set(true) : setIsQuestion2Set(false)
        checkNextDisabled();

    }, [flowRate, pressure, irrigationMethod])

    const openQuestion = (type: 'newQuestion' | 'lastQuestion', value: number): void => {
        switch (type) {
            case 'newQuestion':
                setCurrentOpenQuestion(value);
                break;
            case 'lastQuestion':
                const nextQuestion: number | null = getNextQuestionNumber({ flowRate, pressure, irrigationMethod }, value);
                setCurrentOpenQuestion(nextQuestion && nextQuestion !== currentOpenQuestion ? nextQuestion : null);
                break;

            default:
                break;
        }
    }

    const closeQuestion = (currentQuestion: number): void => {
        const nextQuestion: number | null = getNextQuestionNumber({ flowRate, pressure, irrigationMethod });
        setCurrentOpenQuestion(currentQuestion === nextQuestion ? null : nextQuestion);
    }

    const checkNextDisabled = () => {
        if (isFieldsFilled({ flowRate, pressure, irrigationMethod })) {
            dispatch(setNextDisabled({ data: false }));
        } else {
            dispatch(setNextDisabled({ data: true }));
        }
    }

    return (
        <>
            <div className={animation["slide-up-question"]}>
                {<InputQuestion openQuestion={openQuestion} errorMessage={language[QUESTION1_2_ERROR_LETTERS]} closeQuestion={closeQuestion} currentOpenQuestion={currentOpenQuestion} title={`${language[QUESTION1_1]} (${unitsParams.flowrateLabel})?`} factor={unitsParams.flowrateFactor} field="flowRate" questionNumber={1} uninitValue={UNINITIALIZED_VALUE} ></InputQuestion>}
                {<InputQuestion errorMessage={language[errorMessageKey]} min={minPressure} max={maxPressure} openQuestion={openQuestion} closeQuestion={closeQuestion} currentOpenQuestion={currentOpenQuestion} title={`${language[QUESTION1_2]} (${unitsParams.pressureLabel})?`} factor={unitsParams.pressureFactor} field="pressure" questionNumber={2} uninitValue={UNINITIALIZED_VALUE} ></InputQuestion>}
            </div>
            {isQuestion1Set && isQuestion2Set && <div className={animation["slide-up-question"]}>
                <SelectQuestion deselectOption={false} openQuestion={openQuestion} closeQuestion={closeQuestion} currentOpenQuestion={currentOpenQuestion} valuesArr={irrigationMethodArr} title={`${language[QUESTION1_3]}?`} field="irrigationMethod" questionNumber={3} uninitValue={UNINITIALIZED_VALUE} isPicturesIcons={false} />
            </div>}
        </>
    );
}

export default QuizStep1;