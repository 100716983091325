import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UNINITIALIZED_VALUE } from "../../constats/calculateData/global";
import { EIrrigationMethodOptions, EMainWaterSourceOptions, ESuctionPointWaterSourceOptions, ERangeOptions, EFiltrationTechnologyOptions, EWaterQuality, EBinaryOptions } from '../../enums';
import { IRecommendedModels } from "../../interfaces/calculations";
import { IModelData } from "../../interfaces/resultsData";
import { calcFilterModelsGeneric } from "../../services/calcModelsGenericService";
import { checkIsSendimentationReservoirRrecommend, getFiltrationTechnology, getModelData, getRecommendedManifold, getSystemPressureNotification, geWaterQuality } from "../../services/calculationService";

export interface IInputParamsState {
  // USER INPUTS
  flowRate: number;
  pressure: number;
  irrigationMethod: EIrrigationMethodOptions;
  mainWaterSource: EMainWaterSourceOptions;
  suctionPointWaterSource: ESuctionPointWaterSourceOptions;
  sand: ERangeOptions,
  siltClay: ERangeOptions,
  tSS: ERangeOptions,
  organicMatters: EBinaryOptions;
  isFeMnExist: EBinaryOptions;

  // CALCULATED FIELDS
  firstFiltrationTechnology: EFiltrationTechnologyOptions;
  secoundFiltrationTechnology: EFiltrationTechnologyOptions;
  isHydroCyclonRrecommend: boolean;
  isSendimentationReservoirRrecommend: boolean;
  waterQuality?: EWaterQuality;
  recommendedModels1A?: IRecommendedModels;
  recommendedModels1B?: IRecommendedModels;
  recommendedModels2?: IRecommendedModels;

  // RESULTS
  results1A?: IModelData | null;
  results1B?: IModelData | null;
  results2?: IModelData | null;

}

const initialState: IInputParamsState = {
  flowRate: UNINITIALIZED_VALUE,
  pressure: UNINITIALIZED_VALUE,
  irrigationMethod: EIrrigationMethodOptions.UNINITIALIZED,
  mainWaterSource: EMainWaterSourceOptions.UNINITIALIZED,
  suctionPointWaterSource: ESuctionPointWaterSourceOptions.UNINITIALIZED,
  sand: ERangeOptions.UNINITIALIZED,
  siltClay: ERangeOptions.UNINITIALIZED,
  tSS: ERangeOptions.UNINITIALIZED,
  organicMatters: EBinaryOptions.UNINITIALIZED,
  isFeMnExist: EBinaryOptions.UNINITIALIZED,

  firstFiltrationTechnology: EFiltrationTechnologyOptions.UNINITIALIZED,
  secoundFiltrationTechnology: EFiltrationTechnologyOptions.UNINITIALIZED,
  isHydroCyclonRrecommend: false,
  isSendimentationReservoirRrecommend: false
};

export const inputParamsSlice = createSlice({
  name: "inputParams",
  initialState,
  reducers: {
    calculateResult: (state) => {
      // STEP 1
      const { firstOpt, secoundOpt, isHydro } = getFiltrationTechnology(state);
      state.firstFiltrationTechnology = firstOpt ? firstOpt : EFiltrationTechnologyOptions.UNINITIALIZED;
      state.secoundFiltrationTechnology = secoundOpt ? secoundOpt : EFiltrationTechnologyOptions.UNINITIALIZED;
      state.isHydroCyclonRrecommend = isHydro;
      // STEP 2
      state.waterQuality = geWaterQuality(state);
      // STEP 3
      let firstRecommendedModelsArr: IRecommendedModels[] = [], secRecommendedModelsArr: IRecommendedModels[] = [];

      if (state.firstFiltrationTechnology !== EFiltrationTechnologyOptions.UNINITIALIZED) {
        firstRecommendedModelsArr = calcFilterModelsGeneric(state.firstFiltrationTechnology, state.flowRate, state.waterQuality);
      }
      if (state.secoundFiltrationTechnology !== EFiltrationTechnologyOptions.UNINITIALIZED) {
        secRecommendedModelsArr = calcFilterModelsGeneric(state.secoundFiltrationTechnology, state.flowRate, state.waterQuality);
      }

      if (firstRecommendedModelsArr[0]) {
        state.recommendedModels1A = firstRecommendedModelsArr[0];
        // STEP 4
        state.recommendedModels1A.inletOutletDiameter = getRecommendedManifold(state.recommendedModels1A);
        if (state.recommendedModels1A.inletOutletDiameter === -1) {
          state.recommendedModels1A = undefined;
        }
        else {
          // STEP 5
          state.recommendedModels1A = getSystemPressureNotification(state.recommendedModels1A, state.pressure);
          // RESULTS
          state.results1A = getModelData(state.recommendedModels1A);
        }
        if (firstRecommendedModelsArr[1]) {
          state.recommendedModels1B = firstRecommendedModelsArr[1];
          // STEP 4       
          state.recommendedModels1B.inletOutletDiameter = getRecommendedManifold(state.recommendedModels1B);
          if (state.recommendedModels1B.inletOutletDiameter === -1) {
            state.recommendedModels1B = undefined;
          }
          else {
            // STEP 5        
            state.recommendedModels1B = getSystemPressureNotification(state.recommendedModels1B, state.pressure);
            // RESULTS        
            state.results1B = getModelData(state.recommendedModels1B);
          }
        }
      }

      if (secRecommendedModelsArr[0]) {
        state.recommendedModels2 = secRecommendedModelsArr[0];
        // STEP 4
        state.recommendedModels2.inletOutletDiameter = getRecommendedManifold(state.recommendedModels2);
        if (state.recommendedModels2.inletOutletDiameter === -1) {
          state.recommendedModels1B = undefined;
        }
        else {
          // STEP 5
          state.recommendedModels2 = getSystemPressureNotification(state.recommendedModels2, state.pressure);
          // RESULTS
          state.results2 = getModelData(state.recommendedModels2);
        }
      }

      state.isSendimentationReservoirRrecommend = checkIsSendimentationReservoirRrecommend(state);
    },

    setGeneral: (state, action: PayloadAction<{ field: string, data: number }>) => {
      const { field, data } = action.payload;
      let tempState: any = state;
      tempState[field] = data;

      state = tempState
    },

    setGeneralText: (state, action: PayloadAction<{ field: string, data: string | null }>) => {
      const { field, data } = action.payload;
      let tempState: any = state;

      tempState[field] = data;
      state = tempState
    },

    setTempInputs: (state) => {
      state.flowRate = 50;
      state.pressure = 5;
      state.irrigationMethod = EIrrigationMethodOptions.DRIPPERLINE_SINGLE_SEASON;
      state.mainWaterSource = EMainWaterSourceOptions.NOT_APPLICABLE;
      state.suctionPointWaterSource = ESuctionPointWaterSourceOptions.TREATED_WASTEWATER;
      state.sand = ERangeOptions.MEDIUM;
      state.siltClay = ERangeOptions.UNINITIALIZED;
      state.tSS = ERangeOptions.UNINITIALIZED;
      state.organicMatters = EBinaryOptions.YES;
      state.isFeMnExist = EBinaryOptions.YES;;
    },

    resetInputParams(state) {
      return { ...initialState };
    },
  },
});

export const { setGeneral, setGeneralText, setTempInputs, calculateResult, resetInputParams } = inputParamsSlice.actions;

export const inputParamsReducer = inputParamsSlice.reducer;
