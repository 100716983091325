
import { useState } from "react";
import styles from "../../assets/styles/components/generic/result-card.module.scss"
import animation from "../../assets/styles/global/animations.module.scss";
import { IRecommendedModels } from "../../interfaces/calculations";
import { IModelData } from "../../interfaces/resultsData";
import plusIcon from "../../assets/images/icons/plus-icon.svg"
import minusIcon from "../../assets/images/icons/minus-icon.svg"
import alertIcon from "../../assets/images/icons/alert-icon.svg"
import learnMoreIcon from "../../assets/images/icons/learn-more-icon.svg"
import drawingMoreIcon from "../../assets/images/icons/drawing-icon.svg"
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { getUnitModelAndDiameterStr } from "../../services/languagesHelperService";
import { RESULTS_CARD_TEXT_6 } from "../../constats/languages/languagesKeys";
import { IUnitModelAndDiameter } from "../../constats/languages/constText";
import { IResultCardData } from "../../store/slices/resultPdfSlice";

export type props = {
    place: number,
    technology: number,
    recommendedModel: IRecommendedModels | undefined,
    modelDetails: IModelData | null | undefined,
    text: IResultCardData
}

const ResultCard = ({ place, technology, recommendedModel, modelDetails, text }: props) => {

    const { language, unitsParams } = useSelector((state: RootState) => state.settingsReducer);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [cardAnimation, setCardAnimation] = useState<"" | "open" | "close">("");
    const { waterQuality } = useSelector((state: RootState) => state.inputParamsReducer);
    const { learnMoreLinkMessageKeyMobile }: IUnitModelAndDiameter = recommendedModel ? getUnitModelAndDiameterStr(recommendedModel.technology, recommendedModel.model) : { unit: '', modelDdiameter: '', learnMoreLinkMessageKeyMobile: -1, learnMoreLinkMessageKeyDesktop: -1 };
    const { isPressureMaxNotification, isPressureMinNotification } = recommendedModel ? recommendedModel : { isPressureMaxNotification: false, isPressureMinNotification: false };

    const handleClick = () => {
        if (isOpen) {
            setCardAnimation("close");
            setTimeout(() => { setIsOpen(!isOpen) }, 200);
        } else {
            setCardAnimation("open");
            setIsOpen(!isOpen)
        }
    }

    const handleLinkClicked = (link?: string) => {
        link && window.open(
            link,
            '_blank'
        );
    }

    const technologyBoxClasses: string[] = [
        styles["technology-title-container"],
        styles[`color-${place < 3 ? 'green' : 'orange'}`],
    ];

    const resultCardClasses: string[] = [
        styles["result-card-container"],
        styles[`color-${place < 3 ? 'green' : 'orange'}`],
    ];

    const detailsContainerClasses: string[] = [
        styles["details-container"],
        animation[`${cardAnimation}`],
    ];

    return (
        <div className={resultCardClasses.join(" ")}>
            <div className={styles['pointer']} onClick={recommendedModel && modelDetails ? handleClick : () => { }}>
                <div className={technologyBoxClasses.join(" ")}>
                    {text?.technologyTitle}
                </div>
                {recommendedModel && modelDetails ?
                    <div className={styles['summary-details-container']} >
                        <h2 className={styles['h2-class']}>{text?.title}</h2>
                        <h5 className={styles['h5-class']}>{text?.subTitle}</h5>
                        <h4 className={styles['h4-class']}>{language[RESULTS_CARD_TEXT_6]}<img className={styles['plus-icon']} src={isOpen ? minusIcon : plusIcon} /></h4>
                    </div> :
                    <h2 className={styles['h2-class']}>{text?.contactNetafim}</h2>
                }
            </div>
            {isOpen &&
                <div className={detailsContainerClasses.join(" ")}>
                    <div className={styles['bullets-container']} >
                        <div className={styles['bullets-left-section']}>
                            {modelDetails?.maxFlowRate[waterQuality!] && <h5 className={styles['list-dot']}>{text?.systemFlowRate}<span className={styles['span-class']}>{text?.systemFlowRateValue}</span></h5>}
                            {modelDetails?.maxPressure && <h5 className={styles['list-dot']}>{text?.maxPressure}<span className={styles['span-class']}>{text?.maxPressureValue}</span></h5>}
                        </div>
                        <div className={styles['bullets-right-section']}>
                            {modelDetails?.backFlushingFlowRate && <h5 className={styles['list-dot']}>{text?.backFlushingFlowRate}<span className={styles['span-class']}>{text?.backFlushingFlowRateValue}</span></h5>}
                            {modelDetails?.backFlushingFlowRateMediaFilter && <h5 className={styles['list-dot']}>{text?.backFlushingFlowRate}</h5>}
                            {modelDetails?.backFlushingFlowRateMediaFilter?.withBasalt && <h5 className={[styles['list-dot'], styles['inner-dot']].join(" ")}>{text?.backFlushingFlowRateBasalt}<span className={styles['span-class']}>{text?.backFlushingFlowRateBasaltValue}</span></h5>}
                            {modelDetails?.backFlushingFlowRateMediaFilter?.withSilica && <h5 className={[styles['list-dot'], styles['inner-dot']].join(" ")}>{text?.backFlushingFlowRateSilica}<span className={styles['span-class']}>{text?.backFlushingFlowRateSilicaValue}</span></h5>}
                            {modelDetails?.minBackFlushPressure && <h5 className={styles['list-dot']}>{text?.minPressure}<span className={styles['span-class']}>{text?.minPressureValue}</span></h5>}
                        </div>
                    </div>
                    {(isPressureMaxNotification || isPressureMinNotification) &&
                        <div className={styles['alert-message-container']}>
                            <img className={styles['alert-icon']} src={alertIcon} />
                            <h5 className={styles['alert-text']}>{text?.pressureAlert}</h5>
                        </div>}
                    <span className={styles['span-class']}>{text?.tagLine}</span>
                    <div className={styles['links-container']}>
                        <h4 className={[styles['right-space'], styles['h4-class'], styles['mobile-text']].join(" ")} onClick={() => { handleLinkClicked(modelDetails?.modelNetafimWebsiteLink) }} >{language[learnMoreLinkMessageKeyMobile]}<img className={styles['links-icon']} src={learnMoreIcon} /></h4>
                        <h4 className={[styles['right-space'], styles['h4-class'], styles['desktop-text'], styles['pointer']].join(" ")} onClick={() => { handleLinkClicked(text?.learnMoreLink) }} >{text?.learnMore}<img className={styles['links-icon']} src={learnMoreIcon} /></h4>
                        <h4 className={[styles['left-space'], styles['h4-class'], styles['pointer']].join(" ")} onClick={() => { handleLinkClicked(text?.drawingMoreLink) }} >{text?.drawingMore}<img className={styles['links-icon']} src={drawingMoreIcon} /></h4>
                    </div>
                </div>}

        </div>
    )
}
export default ResultCard;

