import styles from "../../assets/styles/components/generic/bottom-nav.module.scss"
import MyButton from "./MyButton";
import { props as buttonProps } from "./MyButton"
import pdfIcon from "../../assets/images/icons/pdf-icon.svg"
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { PAGE_FOOTER_1, BACK_BUTTON } from "../../constats/languages/languagesKeys";
import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfFile from "../pdfs/PdfFile";
import { isNumberBeforeOptionTitle } from "../../services/languagesHelperService";
import { getFormattedFileName } from "../../services/uploadFilesService";

export type props = {
    rightButtonStyle?: buttonProps["type"];
    rightButtonTextKey?: number;
    rightButtonDiabled?: boolean;
    rightButtonShadow?: buttonProps["shadow"];
    rightButtonOnClick: () => void;
    hasLeftButton: boolean;
    leftButtonStyle?: buttonProps["type"];
    leftButtonTextKey?: number;
    leftButtonDiabled?: boolean;
    leftButtonOnClick?: () => void;
    isResults?: boolean;
    resultsTextKey?: number;

}

const BottomNav = ({
    rightButtonStyle = "primary",
    rightButtonTextKey = PAGE_FOOTER_1,
    rightButtonDiabled = false,
    rightButtonShadow = "grey",
    rightButtonOnClick,
    hasLeftButton,
    leftButtonStyle = "back",
    leftButtonTextKey = BACK_BUTTON,
    leftButtonDiabled = false,
    leftButtonOnClick = () => { },
    isResults = false,
    resultsTextKey }: props) => {

    const { currentStep, steps } = useSelector((state: RootState) => state.quizStepsReducer);
    const { language, languageKey } = useSelector((state: RootState) => state.settingsReducer);
    const { pageTitle, firstOpt, or, secOpt, resultCardData, conditionsCardData, hydroCyclonNoteData, sedimentationNoteData, notesData } = useSelector((state: RootState) => state.resultPdfReducer);
    const fileName:string = getFormattedFileName();
    
    return (
        <div className={styles["bottom-nav"]}>
            <MyButton disabled={steps[currentStep].nextDisabled} label={language[rightButtonTextKey]} type={rightButtonStyle} width="max" widthTablet={language[rightButtonTextKey].length > 12 ? "min" : "small"} onClick={rightButtonOnClick} grow={2} shadow={rightButtonShadow} />
            {isResults && <div className={styles['pdf-wrapper']}>
                <PDFDownloadLink document={<PdfFile isNumberBeforeOptionTitle={isNumberBeforeOptionTitle(languageKey)} pageTitle={pageTitle} firstOpt={firstOpt} or={or} secOpt={secOpt} conditionsCardData={conditionsCardData} resultCardData={resultCardData} hydroCyclonNoteData={hydroCyclonNoteData} sedimentationNoteData={sedimentationNoteData} notesData={notesData} />}
                    fileName={fileName}>
                    <div className={styles["pdf"]}>
                        <img src={pdfIcon} className={styles["pdf"]} alt="Export to pdf" />
                    </div>
                </PDFDownloadLink>
            </div>}
            {hasLeftButton && <MyButton label={language[leftButtonTextKey]} type={leftButtonStyle} width="max" widthTablet="min" onClick={leftButtonOnClick} grow={1} />}
        </div>
    )

}

export default BottomNav;