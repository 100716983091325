import { UNINITIALIZED_VALUE } from "../constats/calculateData/global";

export enum EIrrigationMethodOptions {
    UNINITIALIZED = UNINITIALIZED_VALUE,
    PIVOT = 1,
    OPEN_FIELD_SPRINKLERS,
    MICRO_SPRINKLERS,
    DRIPPERLINE_MULTI_SEASON_LOW,
    DRIPPERLINE_MULTI_SEASON_HIGH,
    DRIPPERLINE_SINGLE_SEASON,
    ON_LINE_DRIPPERS_LOW,
    ON_LINE_DRIPPERS_HIGH,
}

export enum EMainWaterSourceOptions {
    UNINITIALIZED = UNINITIALIZED_VALUE,
    CANAL = 1,
    LAKE,
    RIVER,
    RESERVOIR,
    LAGOON,
    WELL,
    TREATED_WASTEWATER,
    FARM_EFFLUENCE,
    INDUSTRIAL_RECYCLE_WATER,
    NOT_APPLICABLE,
}

export enum ESuctionPointWaterSourceOptions {
    UNINITIALIZED = UNINITIALIZED_VALUE,
    CANAL = 1,
    LAKE,
    RIVER,
    RESERVOIR,
    LAGOON,
    WELL,
    OPERATIONAL_POOL,
    WATER_STORAGE_TANK,
    SEDIMENTATION_STRUCTURE,
    TREATED_WASTEWATER,
    FARM_EFFLUENCE,
    INDUSTRIAL_RECYCLE_WATER
}

export enum ERangeOptions {
    UNINITIALIZED = UNINITIALIZED_VALUE,
    LOW = 1,
    MEDIUM,
    HIGH,
}

export enum EBinaryOptions {
    UNINITIALIZED = UNINITIALIZED_VALUE,
    NO = 0,
    YES,
}