import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOption } from "../../constats/calculateData/countries";
import { UNITS } from "../../constats/settings";
import { ELanguages, EUnits } from "../../enums/settings";
import { IUnits } from "../../interfaces/settings";
import { getLanguege, getLanguegsOptions, ILenguageItem } from "../../services/languagesHelperService";

export interface ISettingsState {
    language: { [model: number]: string }
    languageKey: ELanguages;
    units: EUnits;
    unitsParams: IUnits;
    currentIndex: number;
    languagesOptions: IOption[];
    isHide: boolean;
}

const initialState: ISettingsState = {
    language: getLanguege(ELanguages.ENGLISH),
    languageKey: ELanguages.ENGLISH,
    currentIndex: 0,
    languagesOptions: getLanguegsOptions(getLanguege(ELanguages.ENGLISH)),
    units: EUnits.METRIC,
    unitsParams: UNITS[EUnits.METRIC],
    isHide: true
};

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setUnits: (state, action: PayloadAction<{ data: number }>) => {
            const { data } = action.payload;

            state.units = data;
            state.unitsParams = UNITS[data];
        },

        setLanguege: (state, action: PayloadAction<{ languageKey: ELanguages }>) => {
            const { languageKey } = action.payload;
            const currentIndex = state.languagesOptions.findIndex(x => x.value === languageKey);
            state.language = getLanguege(languageKey);
            state.languageKey = languageKey;
            state.languagesOptions = getLanguegsOptions(state.language);
            state.currentIndex = currentIndex;
        },

        setIsHide: (state, action: PayloadAction<{ value: boolean }>) => {
            const { value } = action.payload;
            state.isHide = value;
        },
    },
});

export const { setUnits, setLanguege, setIsHide } = settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;
