import styles from "../../assets/styles/components/generic/bottom-header.module.scss"
import CircleProgBar from "./CircleProgBar";
import completeCheck from "../../assets/images/icons/complete-check.svg"
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { quizStep, nextStep } from "../../store/slices/quizStepsSlice";
import pdfIcon from "../../assets/images/icons/pdf-icon.svg"
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfFile from "../pdfs/PdfFile";
import { isNumberBeforeOptionTitle } from "../../services/languagesHelperService";
import { getFormattedFileName } from "../../services/uploadFilesService";

const ButtomHeader = () => {

    const steps: quizStep[] = useSelector((state: RootState) => state.quizStepsReducer.steps);
    const currentStep: number = useSelector((state: RootState) => state.quizStepsReducer.currentStep);
    const { language, languageKey } = useSelector((state: RootState) => state.settingsReducer);
    const { pageTitle, firstOpt, or, secOpt, conditionsCardData, resultCardData, hydroCyclonNoteData, sedimentationNoteData, notesData } = useSelector((state: RootState) => state.resultPdfReducer);
    const fileName: string = getFormattedFileName();

    let totalStepsToShow = 0;
    steps.forEach(element => {
        !element.hiddenProgress && totalStepsToShow++;
    });

    return (!steps[currentStep].hiddenProgress ? <div className={styles["bottom-header"]}>

        {currentStep < steps.length - 1 ? <CircleProgBar step={currentStep + 1} ofSteps={totalStepsToShow - 1} /> : <div className={styles["complete-circle"]}><img src={completeCheck} alt="Complete check" /></div>}
        <div className={styles["progression-text"]}>
            <span className={styles["top-text"]}>{language[steps[currentStep].titleKey]}</span>
            {currentStep < steps.length - 1 && <span className={styles["bottom-text"]}>{language[steps[currentStep + 1].titleKey]}</span>}
        </div>
        {steps[currentStep].id === "RES" &&
            <PDFDownloadLink document={<PdfFile isNumberBeforeOptionTitle={isNumberBeforeOptionTitle(languageKey)} pageTitle={pageTitle} firstOpt={firstOpt} or={or} secOpt={secOpt} conditionsCardData={conditionsCardData} resultCardData={resultCardData} hydroCyclonNoteData={hydroCyclonNoteData} sedimentationNoteData={sedimentationNoteData} notesData={notesData} />}
                fileName={fileName}>
                <div className={styles["pdf"]}>
                    <img src={pdfIcon} className={styles["pdf"]} alt="Export to pdf" />
                </div>
            </PDFDownloadLink>
        }
    </div> : <></>);
}

export default ButtomHeader;