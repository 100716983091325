import { IOption } from "../constats/calculateData/countries";
import { ELanguages } from "../enums/settings";
import { filtretionTechnologyKey, IUnitModelAndDiameter, technologyFilterKey, unitModelAndDiameterStrs } from "../constats/languages/constText"
import { LENGUAGES } from "../constats/languages/languages";
import { ERoleOption } from "../enums/roleOption";
import { UNINITIALIZED_VALUE } from "../constats/calculateData/global";
import { IMySelectOption } from "../interfaces/selectQuestion";
import { ANSWER3_4_1, ANSWER3_4_2 } from "../constats/languages/languagesKeys";
import { EBinaryOptions } from "../enums";

const { UNINITIALIZED, NO, YES } = EBinaryOptions
export interface ILenguageItem {
    itemName: string,
    id: number,
    text: string,
}

export const getLanguege = (languageKey: ELanguages): { [model: number]: string } => {
    let x: { [model: number]: string } = []
    LENGUAGES[languageKey].forEach((item: ILenguageItem) => { x[item.id] = item.text });
    return x
};

export const getLanguegsOptions = (language: any) => {
    const languegsOptions: IOption[] = [];
    for (const value in ELanguages) {
        if (isNaN(Number(value)) === false && +value != UNINITIALIZED_VALUE) {
            languegsOptions.push({ value: +value, label: language[+value] })
        } else if (+value === UNINITIALIZED_VALUE) {
            languegsOptions.push({ value: null, label: language[+value] })
        }
    }
    return languegsOptions
}

export const getRoleOptions = (role: any) => {
    const roleOptions: IOption[] = [];
    for (const value in ERoleOption) {
        if (isNaN(Number(value)) === false) {
            roleOptions.push({ value: +value, label: role[+value] })
        }
    }
    return roleOptions
}

export const getUnitModelAndDiameterStr = (technology: number, model: number,): IUnitModelAndDiameter => {
    return unitModelAndDiameterStrs[technology][model];
}

export const getfiltretionTechnologyKey = (technology: number,): number => {
    return filtretionTechnologyKey[technology];
}

export const getTechnologyFilterTextKey = (technology: number): number => {
    return technologyFilterKey[technology];
}

export const isNumberBeforeOptionTitle = (languageKey: ELanguages,): boolean => {
    return languageKey !== ELanguages.SPANISH && languageKey !== ELanguages.GERMAN;
}

export const getItemText = (languageKey: ELanguages, itemKey: number): string => {
    const item = LENGUAGES[languageKey].find((item: ILenguageItem) => item.id === itemKey);
    return item.text;
}

export const getOptionTitleKey = (selectOptions: IMySelectOption[], value: number): number => {
    const keyItem = selectOptions.find((option: IMySelectOption) => option.value === value);
    const key = keyItem ? keyItem.titleKey : -1;

    return key;
}

export const getBinaryTitleKey = (value: number): number => {
    let res;
    switch (value) {
        case YES:
            res = ANSWER3_4_1;
            break;
        case NO:
            res = ANSWER3_4_2;
            break;
        default:
            res = UNINITIALIZED_VALUE;
            break;
    }
    return res
}

