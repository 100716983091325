import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { inputParamsReducer } from "./slices/inputParamsSlice";
import quizStepsSlice from "./slices/quizStepsSlice";
import { registrationReducer } from "./slices/registrationSlice";
import { resultPdfReducer } from "./slices/resultPdfSlice";
import { settingsReducer } from "./slices/settingsSlice";

export const store = configureStore({
  reducer: {
    inputParamsReducer: inputParamsReducer,
    quizStepsReducer: quizStepsSlice,
    registrationReducer: registrationReducer,
    settingsReducer: settingsReducer,
    resultPdfReducer: resultPdfReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
