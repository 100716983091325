import { useEffect, useState } from 'react';
import Select from 'react-select';
import "../../assets/styles/components/generic/my-popup.module.scss";
import { IOption } from '../../constats/calculateData/countries';
import styles from "../../assets/styles/components/generic/my-select.module.scss";

type props = {
    setField: ((value: string) => void),
    options: IOption[],
    showButton?: boolean,
    currentIndex: number,
    isSearchable:boolean
}

/*** LIBRARY FOR SELECT:
     https://react-select.com/home
 ***/

const MySelect = ({ setField, options, currentIndex, showButton = true , isSearchable}: props) => {

    const [currentValue, setCurrentValue] = useState(options[currentIndex]);

    useEffect(() => {
        setCurrentValue(options[currentIndex]);
    }, [options, currentIndex])

    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            borderRadius: 0,
            backgroundColor: '#ffffff',
            border: `${currentValue.value ? '1px solid #0082fd' : '1px solid #bababa'}`,
            padding: ''
        }),
        menu: (base: any) => ({
            ...base,
            borderRadius: 0,
            hyphens: "auto",
            wordWrap: "break-word",
        }),
        menuList: (base: any) => ({
            ...base,
            padding: 0,
            borderRadius: 0,
        }),
        indicatorsContainer: (base: any, state: any) => ({
            ...base,
            display: `${showButton ? 'block' : 'none !important'}`,
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
        }),
        singleValue: (base: any) => ({
            ...base,
            margin: ' 0 8px;'
        }),
    };

    const customTheme = (theme: any) => {
        return {
            ...theme,
            colors: {
                ...theme.colors,
                primary: '#0A7FFF',
                primary25: '#F4F7FB',
            }
        }
    }

    const handleChange = (newOption: any) => {
        const { value, label } = newOption;
        setCurrentValue(options[currentIndex]);
        setField(value);
    }

    return (
        <Select value={currentValue} isSearchable={isSearchable} onChange={(value) => handleChange(value)} options={options} defaultValue={options[0]} components={{
            IndicatorSeparator: () => null
        }} styles={customStyles}
            theme={customTheme} />
    );
}

export default MySelect;