import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { props as NoteProps } from "../../components/generic/ResultNote"
export interface IResultPdfState {
    pageTitle: string | null;
    firstOpt: { number: string, option: string, smallText: string } | null;
    or: string | null;
    secOpt: { number: string, option: string, smallText: string } | null;
    conditionsCardData: ConditionsCardData | null;
    resultCardData: { [index: number]: IResultCardData };
    hydroCyclonNoteData: NoteProps | null;
    sedimentationNoteData: NoteProps | null;
    notesData: NoteProps[];
}

export interface ConditionsCardData {
    title: string,
    flowRateAndPressure: string,
    irrigationMethodValue: string,
    mainWaterSourceStr: string,
    mainWaterSourceValue: string,
    suctionPointStr: string,
    suctionPointValue: string,
    sandStr: string,
    sandValue: string,
    siltClayStr: string,
    siltClayValue: string,
    tSSStr: string,
    tSSValue: string,
    organicMattersStr: string,
    organicMattersValue: string,
    isFeMnExistStr: string,
    isFeMnExistValue: string,
}

export interface IResultCardData {
    technologyTitle: string,
    isContactNetafim: boolean,
    title?: string,
    subTitle?: string,
    contactNetafim?: string,
    contactNetafimLink?: string,
    contactNetafimLinkSrc?: string,
    systemFlowRate?: string,
    systemFlowRateValue?: string,
    maxPressure?: string,
    maxPressureValue?: string,
    backFlushingFlowRate?: string,
    isBackFlushingFlowRateMediaFilter: boolean,
    backFlushingFlowRateValue?: string,
    backFlushingFlowRateBasalt?: string,
    backFlushingFlowRateBasaltValue?: string,
    backFlushingFlowRateSilica?: string,
    backFlushingFlowRateSilicaValue?: string,
    minPressure?: string,
    minPressureValue?: string,
    pressureAlert?: string,
    tagLine?: string,
    learnMore?: string,
    learnMoreLink?: string,
    drawingMore?: string,
    drawingMoreLink?: string,
}

const initialState: IResultPdfState = {
    pageTitle: null,
    firstOpt: null,
    or: null,
    secOpt: null,
    conditionsCardData: null,
    resultCardData: {},
    hydroCyclonNoteData: null,
    sedimentationNoteData: null,
    notesData: []
}
export const resultPdfSlice = createSlice({
    name: "resultPdf",
    initialState,
    reducers: {
        setText: (state, action: PayloadAction<{ field: string, data: string }>) => {
            const { field, data } = action.payload;
            let tempState: any = state;
            tempState[field] = data;

            state = tempState
        },
        setTitle: (state, action: PayloadAction<{ field: string, data: { number: string, option: string, smallText: string } }>) => {
            const { field, data } = action.payload;
            let tempState: any = state;
            tempState[field] = data;

            state = tempState
        },
        setResultCard: (state, action: PayloadAction<{ index: number, data: IResultCardData }>) => {
            const { index, data } = action.payload;
            let tempState: IResultPdfState = state;
            tempState.resultCardData[index] = data;

            state = tempState
        },
        setConditionsCard: (state, action: PayloadAction<{ data: ConditionsCardData }>) => {
            const { data } = action.payload;
            state.conditionsCardData = data;
        },
        setResultNode: (state, action: PayloadAction<{ field: string, data: NoteProps | NoteProps[] | null }>) => {
            const { field, data } = action.payload;
            let tempState: any = state;
            tempState[field] = data;

            state = tempState
        },
    },
});

export const { setText, setResultCard, setResultNode, setTitle, setConditionsCard } = resultPdfSlice.actions;

export const resultPdfReducer = resultPdfSlice.reducer;
