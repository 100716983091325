import React, { useState } from "react";
import { useSelector } from "react-redux";
import { UNINITIALIZED_VALUE } from "../../constats/calculateData/global";
import { sandArr, siltClayArr, tSSArr } from "../../constats/formQuestion/selectQuestionsStep3";
import { IRON_DISCLAIMER, QUESTION3_1, QUESTION3_2, QUESTION3_3, QUESTION3_4, QUESTION3_5 } from "../../constats/languages/languagesKeys";
import { getNextQuestionNumber } from "../../services/formService";
import animation from "../../assets/styles/global/animations.module.scss";
import { RootState } from "../../store";
import BinaryQuestion from "../questions/BinaryQuestion";
import SelectQuestion from "../questions/SelectQuestion";

const QuizStep3 = () => {

    const { language } = useSelector((state: RootState) => state.settingsReducer);
    const [currentOpenQuestion, setCurrentOpenQuestion] = useState<number | null>(1);
    const { sand, siltClay, tSS, organicMatters, isFeMnExist } = useSelector((state: RootState) => state.inputParamsReducer);

    const openQuestion = (type: 'newQuestion' | 'lastQuestion', value: number): void => {

        switch (type) {
            case 'newQuestion':
                setCurrentOpenQuestion(value);
                break;
            case 'lastQuestion':
                const nextQuestion: number | null = getNextQuestionNumber({ sand, siltClay, tSS, organicMatters, isFeMnExist }, value);
                setCurrentOpenQuestion(nextQuestion && nextQuestion !== currentOpenQuestion ? nextQuestion : null);
                break;

            default:
                break;
        }
    }

    const closeQuestion = (currentQuestion: number): void => {
        setCurrentOpenQuestion(null);
    }

    const message = language[IRON_DISCLAIMER];
    return (
        <>
            <div className={animation["slide-up-question"]}>
                {<SelectQuestion title={language[QUESTION3_1]} deselectOption={true} field="sand" questionNumber={1} valuesArr={sandArr} uninitValue={UNINITIALIZED_VALUE} isPicturesIcons={false} openQuestion={openQuestion} closeQuestion={closeQuestion} currentOpenQuestion={currentOpenQuestion} ></SelectQuestion>}
                {<SelectQuestion title={language[QUESTION3_2]} deselectOption={true} field="siltClay" questionNumber={2} valuesArr={siltClayArr} uninitValue={UNINITIALIZED_VALUE} isPicturesIcons={false} openQuestion={openQuestion} closeQuestion={closeQuestion} currentOpenQuestion={currentOpenQuestion} ></SelectQuestion>}
                {<SelectQuestion title={language[QUESTION3_3]} deselectOption={true} field="tSS" questionNumber={3} valuesArr={tSSArr} uninitValue={UNINITIALIZED_VALUE} isPicturesIcons={false} openQuestion={openQuestion} closeQuestion={closeQuestion} currentOpenQuestion={currentOpenQuestion} ></SelectQuestion>}
                {<BinaryQuestion title={language[QUESTION3_4]} field="organicMatters" questionNumber={4} openQuestion={openQuestion} closeQuestion={closeQuestion} currentOpenQuestion={currentOpenQuestion} ></BinaryQuestion>}
                {<BinaryQuestion title={language[QUESTION3_5]} field="isFeMnExist" questionNumber={5} message={message} openQuestion={openQuestion} closeQuestion={closeQuestion} currentOpenQuestion={currentOpenQuestion} ></BinaryQuestion>}
            </div>
        </>
    );
}

export default QuizStep3;