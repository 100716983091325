import { EBinaryOptions, ERangeOptions } from "../../enums/inputsAnswers.enum";
import { EIrrigationMethodGroup } from "../../enums/waterQuality.enum";
import { IWaterQualityConditions } from "../../interfaces/waterQualityConditions";

const { HIGH, MEDIUM, LOW } = ERangeOptions;
const { ALL_DRIPLINES, PIVOTS_OR_OPENS, MICRO_SPRINKLERS } = EIrrigationMethodGroup;
const { YES, NO, UNINITIALIZED} = EBinaryOptions

export const averageQualityConditions: IWaterQualityConditions = {
    [ALL_DRIPLINES]: [
        { sand: LOW, siltClay: LOW, tSS: LOW, organicMatters: NO },
        { sand: LOW, siltClay: LOW, tSS: MEDIUM, organicMatters: NO },
    ],
    [PIVOTS_OR_OPENS]: [
        { sand: LOW, siltClay: LOW, tSS: LOW, organicMatters: UNINITIALIZED },
        { sand: LOW, siltClay: LOW, tSS: MEDIUM, organicMatters: UNINITIALIZED },
        { sand: LOW, siltClay: MEDIUM, tSS: LOW, organicMatters: UNINITIALIZED },
        { sand: LOW, siltClay: MEDIUM, tSS: MEDIUM, organicMatters: UNINITIALIZED },
        { sand: LOW, siltClay: LOW, tSS: LOW, organicMatters: NO },
        { sand: LOW, siltClay: LOW, tSS: MEDIUM, organicMatters: NO },
        { sand: LOW, siltClay: MEDIUM, tSS: LOW, organicMatters: NO },
        { sand: LOW, siltClay: MEDIUM, tSS: MEDIUM, organicMatters: NO },
    ],
    [MICRO_SPRINKLERS]: [
        { sand: LOW, siltClay: LOW, tSS: LOW, organicMatters: NO },
        { sand: LOW, siltClay: MEDIUM, tSS: LOW, organicMatters: NO },
    ],
};



