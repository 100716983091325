
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import inputStyles from "../../assets/styles/components/generic/my-input.module.scss"
import { IRegistrationState, setTextFields } from "../../store/slices/registrationSlice";

export type props = {
    field: string,
    placeholder: string,
    defaultValue?: string
    defaultErrorMessage?: string,
    patternErrorMessage?: string,
    validationCheck?: ((data: string) => boolean) | null,
}

const MyInput = ({ field, placeholder, defaultValue, defaultErrorMessage = 'ERROR', patternErrorMessage, validationCheck }: props) => {

    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState(defaultValue ? defaultValue : '');
    const [status, setStatus] = useState('default');
    const [isDirty, setIsDirty] = useState(false);

    const uninitValue = null;
    const errorMessage = inputValue !== '' && patternErrorMessage ? patternErrorMessage : defaultErrorMessage;

    useEffect(() => {
        inputValue !== uninitValue && handleNewValue(inputValue);
    }, [])

    const handleChange = (strData: string) => {
        setInputValue(strData);
    }

    const handleNewValue = (data: string) => {
        if (!isDirty) {
            setIsDirty(true);
            return;
        }

        const isValid: boolean = validationCheck ? validationCheck(data) : true;

        if (isValid) {
            dispatch(setTextFields({ field, data }));
            setStatus('valid');
        } else {
            dispatch(setTextFields({ field, data: uninitValue }));
            setStatus('invalid');
        }
    }

    const inputClasses: string[] = [
        inputStyles["my-input"],
        inputStyles[`state-${status}`],
    ];

    return (
        <div className={inputStyles['input-container']}>
            <input value={inputValue} placeholder={placeholder}
                onChange={(value) => handleChange(value.target.value)}
                onBlur={(value) => handleNewValue(value.target.value)}
                className={inputClasses.join(" ")}
                autoComplete="new-password"
            ></input>
            {status === 'invalid' && <span className={'error-message'}>{errorMessage}</span>}
        </div>
    )
}
export default MyInput;

