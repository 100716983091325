import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IOption } from "../constats/calculateData/countries";
import { ELanguages } from "../enums/settings";
import { RootState } from "../store";
import { resetInputParams } from "../store/slices/inputParamsSlice";
import { resetSteps } from "../store/slices/quizStepsSlice";
import { setLanguege } from "../store/slices/settingsSlice";

export const useSetLanguage = () => {
    const dispatch = useDispatch();

    return useCallback((value: string) => {
        const newValue = Number(value);
        dispatch(setLanguege({ languageKey: newValue }));
    }, [])
}

export const useHandleRestart = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(resetSteps());
        dispatch(resetInputParams());
    }, [])
}
