import { EBinaryOptions, EIrrigationMethodOptions, EMainWaterSourceOptions, ERangeOptions, ESuctionPointWaterSourceOptions } from '../../enums/inputsAnswers.enum'
import { IMySelectOption } from '../../interfaces/selectQuestion';
import { ANSWER1_3_1, ANSWER1_3_2, ANSWER1_3_3, ANSWER1_3_4, ANSWER1_3_5, ANSWER1_3_6, ANSWER1_3_7, ANSWER1_3_8 } from '../languages/languagesKeys';

const { PIVOT, OPEN_FIELD_SPRINKLERS, MICRO_SPRINKLERS, DRIPPERLINE_MULTI_SEASON_LOW, DRIPPERLINE_MULTI_SEASON_HIGH, DRIPPERLINE_SINGLE_SEASON, ON_LINE_DRIPPERS_LOW, ON_LINE_DRIPPERS_HIGH } = EIrrigationMethodOptions;

export const irrigationMethodArr: IMySelectOption[] = [
    { field: 'irrigationMethodArr', value: DRIPPERLINE_MULTI_SEASON_LOW, titleKey: ANSWER1_3_4 },
    { field: 'irrigationMethodArr', value: DRIPPERLINE_MULTI_SEASON_HIGH, titleKey: ANSWER1_3_5 },
    { field: 'irrigationMethodArr', value: DRIPPERLINE_SINGLE_SEASON, titleKey: ANSWER1_3_6 },
    { field: 'irrigationMethodArr', value: MICRO_SPRINKLERS, titleKey: ANSWER1_3_3 },
    { field: 'irrigationMethodArr', value: ON_LINE_DRIPPERS_LOW, titleKey: ANSWER1_3_7 },
    { field: 'irrigationMethodArr', value: ON_LINE_DRIPPERS_HIGH, titleKey: ANSWER1_3_8 },
    { field: 'irrigationMethodArr', value: OPEN_FIELD_SPRINKLERS, titleKey: ANSWER1_3_2 },
    { field: 'irrigationMethodArr', value: PIVOT, titleKey: ANSWER1_3_1 },
]

