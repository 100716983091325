import styles from "../../assets/styles/components/generic/my-button.module.scss";

export type props = {
    label: string;
    disabled?: boolean;
    onClick: () => void;
    type?: "primary" | "secondary" | "back";
    width?: "min" | "small" | "medium" | "large" | "max"; /* Optional property to set button width for mobile width & up, default is "max" (100% width) */
    widthTablet?: "min" | "small" | "medium" | "large" | "max"; /* Optional property to set button width for tablet width & up */
    widthDesktop?: "min" | "small" | "medium" | "large" | "max"; /* Optional property to set button width for desktop width & up */
    squeezed?: boolean;
    grow?: number;
    shadow?: "grey" | "blue" | "none";
}

const MyButton = ({ label, disabled, onClick, type = "primary", width = "max", widthTablet, widthDesktop, squeezed = false, grow, shadow }: props) => {

    const buttonClasses: string[] = [
        styles["button-" + type],
        styles[width],
    ];
    widthTablet && buttonClasses.push(styles["tablet-" + widthTablet]);
    widthDesktop && buttonClasses.push(styles["desktop-" + widthDesktop]);
    squeezed && buttonClasses.push(styles["squeezed"]);
    grow && buttonClasses.push(styles["grow-" + grow]);
    shadow && buttonClasses.push(styles["shadow-" + shadow])

    return (
        <button className={buttonClasses.join(" ")} disabled={disabled} onClick={onClick}>{label}</button>
    );
}

export default MyButton;