
import { useEffect } from "react";
import styles from "../../assets/styles/components/generic/result-note.module.scss"
import learnMoreIcon from "../../assets/images/icons/learn-more-icon.svg"

export type props = {
    title: string,
    paragraph1: string,
    paragraph2?: string,
    paragraph3?: string,
    style?: 'blue' | 'default',
    linkText?: string,
    linkSrc?: string,
}

const ResultNote = ({ title, paragraph1, paragraph2, paragraph3, style, linkText, linkSrc }: props) => {

    const titleContainerClasses: string[] = [
        styles['title'],
        styles[`title-${style}`],
    ];

    const paragraphClasses: string[] = [
        styles["paragraph-text"],
        styles[`paragraph-${style}`],
    ];

    return (
        <div className={styles['result-note-container']}>
            <h3 className={titleContainerClasses.join(" ")}>{title}</h3>
            <h4 className={paragraphClasses.join(" ")}>{paragraph1}</h4>
            { paragraph2 && <h4 className={paragraphClasses.join(" ")}>{paragraph2}</h4>}
            { paragraph3 && <h4 className={paragraphClasses.join(" ")}>{paragraph3}</h4>}
            { linkText && <a className={styles['link']} target="_blank" rel="noopener noreferrer" href={linkSrc}>{linkText}<img className={styles['links-icon']} src={learnMoreIcon} /></a>}
        </div>
    )
}
export default ResultNote;

