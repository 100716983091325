import styles from "../../assets/styles/components/generic/circle-prog-bar.module.scss"
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useEffect, useState } from "react";

/* 
  Using library react-circular-progressbar
  https://www.npmjs.com/package/react-circular-progressbar
*/

type props = {
	step: number;
	ofSteps: number;
}

const CircleProgBar = ({ step, ofSteps }: props) => {

	const customStyle = {

		path: {
			stroke: '#f5be00'
		},
		trail: {
			stroke: '#DCDDDD'
		}
	}

	return (
		<div className={styles["prog-bar-wrapper"]}>
			<CircularProgressbar value={step} maxValue={ofSteps} className={styles["prog-bar"]} styles={customStyle} strokeWidth={8} />
			<div className={styles["prog-bar-label"]}>
				{step} <span>/</span> {ofSteps}
			</div>
		</div>
	)
}

export default CircleProgBar;