
export const getValidationFunction = (type: 'email' | 'name'): ((data: string) => boolean) | null => {

    let validationFunction: ((data: string) => boolean) | null;

    switch (type) {
        case 'email':
            validationFunction = checkEmail;
            break;

        case 'name':
            validationFunction = validateNotNumbersOrSpecial;
            break;

        default:
            validationFunction = null;
            break;
    }

    return validationFunction;
}

const checkEmail = (prop: string): boolean => {
    var re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    return re.test(prop);
}

const validateNotNumbersOrSpecial = (prop: any) => {
    return !(/[\d+/-]/g.test(prop)) && prop !== ''
}