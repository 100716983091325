import { useEffect, useRef, useState } from "react";
import questionStyles from "../../assets/styles/components/generic/question-container.module.scss"
import inputStyles from "../../assets/styles/components/generic/my-input.module.scss"
import { RootState } from "../../store";
import { useDispatch, useSelector } from 'react-redux';
import { IInputParamsState, setGeneral } from "../../store/slices/inputParamsSlice";
import vLogo from "../../assets/images/icons/v-icon-circle.svg"
import editLogo from "../../assets/images/icons/edit-icon.svg"
import { UNINITIALIZED_VALUE } from "../../constats/calculateData/global";

export type props = {
    field: string,
    title: string,
    questionNumber: number,
    uninitValue: any,
    max?: number,
    min?: number,
    factor: number,
    errorMessage?: string,
    currentOpenQuestion: number | null;
    openQuestion: (type: 'newQuestion' | 'lastQuestion', value: number) => void;
    closeQuestion: (currentQuestion: number) => void;
}

const InputQuestion = ({ field, title, questionNumber, uninitValue, max, min, errorMessage = "ERROR", factor, currentOpenQuestion, openQuestion, closeQuestion }: props) => {

    const state = useSelector((state: RootState) => state.inputParamsReducer);
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [status, setStatus] = useState('default');
    const fieldKey = field as keyof IInputParamsState;

    useEffect(() => {
        if (state[fieldKey] !== UNINITIALIZED_VALUE) {
            setInputValue(`${(state[fieldKey] as number * factor).toFixed(2)}`);
            setIsActive(false);
        } else {
            setInputValue('')
            setIsActive(true)
        }
    }, [factor, state[fieldKey]])

    const questionClasses: string[] = [
        questionStyles['input-question-container'],
        questionStyles[`${state[fieldKey] === UNINITIALIZED_VALUE ? 'value-unset' : 'value-set'}`],
    ];

    const iconClasses: string[] = [
        questionStyles['icon-circel'],
        questionStyles[`${state[fieldKey] === UNINITIALIZED_VALUE ? 'number-icon' : 'v-circle'}`],
    ];

    const handleEditClicked = () => {
        currentOpenQuestion === questionNumber ? closeQuestion(questionNumber) : openQuestion('newQuestion', questionNumber);
        setIsActive(!isActive);
    }

    const handleKeyUp = (event: any) => {
        if (event.key === 'Enter') {
            handleNewValue(event.target.value);
        }
    }

    const handleChange = (strData: string) => {
        setInputValue(strData);
    }

    const handleNewValue = (strData: string) => {
        let data: number = Number(strData);
        data = data / factor;
        const isValid: boolean = data ? checkValidations(data) : false;

        if (isValid) {
            dispatch(setGeneral({ field, data }));
            setIsActive(false)
            setStatus('valid');
            closeQuestion(questionNumber);
            openQuestion('lastQuestion', questionNumber);
        } else {
            dispatch(setGeneral({ field, data: uninitValue }));
            setIsActive(true)
            setStatus('invalid');
            openQuestion('newQuestion', questionNumber);
        }
    }

    const checkValidations = (data: number): boolean => {
        const minCond: boolean = min ? data >= min / factor : true;
        const maxCond: boolean = max ? data <= max / factor : true;

        return minCond && maxCond && !!data;
    }

    const inputClasses: string[] = [
        inputStyles["my-input"],
        inputStyles[`state-${status}`],
    ];

    return (
        <div className={questionClasses.join(" ")} onClick={handleEditClicked}>
            <div className={questionStyles['titles-container']} >
                {state[fieldKey] === UNINITIALIZED_VALUE ? <div className={iconClasses.join(" ")}>{questionNumber}</div> :
                    <div className={iconClasses.join(" ")}>
                        <img className={questionStyles['v-icon']} src={vLogo}></img>
                    </div>
                }
                <h3 className={questionStyles["question-title"]} >{title}</h3>
            </div>
            <div className={questionStyles['value-container']}>
                {currentOpenQuestion === questionNumber ?
                    <div className={inputStyles['input-container']}>
                        <input onKeyUp={handleKeyUp} onClick={(e) => { e.stopPropagation() }} value={inputValue} onChange={(value) => handleChange(value.target.value)}
                            onBlur={(value) => handleNewValue(value.target.value)}
                            className={inputClasses.join(" ")}
                        ></input>
                        {status === 'invalid' && <span className={'error-message'}>{errorMessage}</span>}
                    </div> :
                    state[fieldKey] !== UNINITIALIZED_VALUE && <div onClick={handleEditClicked} className={questionStyles['results-container']}>
                        <h3 className={questionStyles['results-value']}>{(state[fieldKey] as number * factor).toFixed(2)}</h3>
                        <img className={questionStyles['edit-icon']} src={editLogo}></img>
                    </div>
                }
            </div>
        </div>
    );
}

export default InputQuestion;