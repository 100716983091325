import { UNINITIALIZED_VALUE } from "../constats/calculateData/global";

export enum EIrrigationMethodGroup {
    UNINITIALIZED = UNINITIALIZED_VALUE,
    ALL_DRIPLINES = 1,
    PIVOTS_OR_OPENS,
    MICRO_SPRINKLERS,
}

export enum EWaterQuality {
    POOR = 1,
    AVERAGE,
    IRON_REMOVAL
}