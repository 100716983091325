import { ELanguages } from "../../enums/settings";
import English from '../../assets/languages/English.json';
import Spanish from '../../assets/languages/Spanish.json';
import French from '../../assets/languages/French.json';
import Italian from '../../assets/languages/Italian.json';
import Portuguese from '../../assets/languages/Portuguese.json';
import German from '../../assets/languages/German.json';
import Turkish from '../../assets/languages/Turkish.json';

const { ENGLISH, SPANISH, FRENCH, ITAILAN, PORTUGUESE, GERMAN, TURKISH } = ELanguages;

export const LENGUAGES: { [model:number]: any } = {
    [ENGLISH]:English,
    [SPANISH]:Spanish,
    [FRENCH]:French,
    [ITAILAN]:Italian,
    [PORTUGUESE]:Portuguese,
    [GERMAN]:German,
    [TURKISH]:Turkish,
}
