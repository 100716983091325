import styles from "../../assets/styles/components/generic/top-header.module.scss"
import netafimLogo from "../../assets/images/icons/netafim-logo.svg"
import settingsIcon from "../../assets/images/icons/settings-icon.svg"
import SettingsBar from "./SettingsBar"
import { useState } from "react"
import { useHandleRestart } from "../../services/settingsService"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store"
import { RESTART } from "../../constats/languages/languagesKeys"
import { setIsHide } from "../../store/slices/settingsSlice"

const TopHeader = () => {

    const { language, isHide } = useSelector((state: RootState) => state.settingsReducer);
    const dispatch = useDispatch();

    const toggleSettings = () => {
        dispatch(setIsHide({ value: !isHide }));
    }

    const handleRestart = useHandleRestart();

    return (<div className={styles["top-header-wrapper"]}>
        <div className={styles["responsive-container"]}>
            <div className={styles["top-header-content"]}>
                <button className={styles["exit"] + " " + styles["desktop-hide"]} onClick={handleRestart}>{language[RESTART]}</button>
                <div className={styles["logo-container"]}><img src={netafimLogo} alt="Netafim logo" className={styles["top-logo"]} /></div>
                <img src={settingsIcon} alt="settings" className={styles["settings-icon"]} onClick={toggleSettings} />
                <button className={styles["exit"] + " " + styles["mobile-tablet-hide"]} onClick={handleRestart}>{language[RESTART]}</button>
            </div>
        </div>
        <SettingsBar />
    </div>)
}

export default TopHeader;