import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UNINITIALIZED_VALUE } from "../../constats/calculateData/global";
import { mainWaterSourceArr, suctionPointWaterSourceArr } from "../../constats/formQuestion/selectQuestionsStep2";
import { QUESTION2_1, QUESTION2_2 } from "../../constats/languages/languagesKeys";
import { isFieldsFilled, getUpdateSuctionPointOptionsArr, getNextQuestionNumber } from "../../services/formService";
import animation from "../../assets/styles/global/animations.module.scss";
import { RootState } from "../../store";
import { setGeneral } from "../../store/slices/inputParamsSlice";
import { setNextDisabled } from "../../store/slices/quizStepsSlice";
import SelectQuestion from "../questions/SelectQuestion";

const QuizStep2 = () => {
    const { language } = useSelector((state: RootState) => state.settingsReducer);
    const { mainWaterSource, suctionPointWaterSource } = useSelector((state: RootState) => state.inputParamsReducer);
    const dispatch = useDispatch();
    const [isQuestion1Set, setIsQuestion1Set] = useState(false);
    const [updatedSuctionPointOptionsArr, setUpdatedSuctionPointOptionsArr] = useState(suctionPointWaterSourceArr);
    const [currentOpenQuestion, setCurrentOpenQuestion] = useState<number | null>(1);

    useEffect(() => {
        checkNextDisabled();
        setUpdatedSuctionPointOptionsArr(getUpdateSuctionPointOptionsArr(mainWaterSource));
    }, [])

    useEffect(() => {
        if (isFieldsFilled({ mainWaterSource })) {
            setIsQuestion1Set(true);
        } else {
            setIsQuestion1Set(false)
        }
    }, [mainWaterSource])

    useEffect(() => {
        checkNextDisabled();
    }, [suctionPointWaterSource, mainWaterSource])

    const setMainWaterSource = (data: number) => {
        dispatch(setGeneral({ field: 'mainWaterSource', data: data }));
        dispatch(setGeneral({ field: 'suctionPointWaterSource', data: UNINITIALIZED_VALUE }));
        setUpdatedSuctionPointOptionsArr(getUpdateSuctionPointOptionsArr(data));
    }

    const checkNextDisabled = () => {
        if (isFieldsFilled({ mainWaterSource, suctionPointWaterSource })) {
            dispatch(setNextDisabled({ data: false }));
        } else {
            dispatch(setNextDisabled({ data: true }));
        }
    }

    const openQuestion = (type: 'newQuestion' | 'lastQuestion', value: number): void => {
        switch (type) {
            case 'newQuestion':
                setCurrentOpenQuestion(value);
                break;
            case 'lastQuestion':
                const nextQuestion: number | null = getNextQuestionNumber({ mainWaterSource, suctionPointWaterSource }, value);
                setCurrentOpenQuestion(nextQuestion && nextQuestion !== currentOpenQuestion ? nextQuestion : null);
                break;

            default:
                break;
        }
    }

    const closeQuestion = (currentQuestion: number): void => {
        const nextQuestion: number | null = getNextQuestionNumber({ mainWaterSource, suctionPointWaterSource });
        setCurrentOpenQuestion(currentQuestion === nextQuestion ? null : nextQuestion);
    }

    return (
        <>
            {<div className={animation["slide-up-question"]}>
                <SelectQuestion deselectOption={false} setValue={setMainWaterSource} openQuestion={openQuestion} closeQuestion={closeQuestion} currentOpenQuestion={currentOpenQuestion} title={`${language[QUESTION2_1]} ?`} field="mainWaterSource" questionNumber={1} valuesArr={mainWaterSourceArr} uninitValue={UNINITIALIZED_VALUE} isPicturesIcons={true} ></SelectQuestion>
            </div>}
            {isQuestion1Set && <div className={animation["slide-up-question"]}>
                <SelectQuestion deselectOption={false} openQuestion={openQuestion} closeQuestion={closeQuestion} currentOpenQuestion={currentOpenQuestion} title={`${language[QUESTION2_2]} ?`} field="suctionPointWaterSource" questionNumber={2} valuesArr={updatedSuctionPointOptionsArr} uninitValue={UNINITIALIZED_VALUE} isPicturesIcons={true}></SelectQuestion>
            </div>}
        </>
    );
}

export default QuizStep2;
