import { Page, Text, View, StyleSheet, Image, Link,Font } from "@react-pdf/renderer";
import registerFonts from "./PdfFile"
import font from "../../assets/fonts/Roboto-Regular.ttf"
import fontMedium from "../../assets/fonts/Roboto-Medium.ttf"
import learnMoreIcon from "../../assets/images/icons/learn-more.jpg"

Font.register({ family: "Roboto-Regular", src: font });
Font.register({ family: "Roboto-Medium", src: fontMedium }); 

const styles = StyleSheet.create({
    title: {        
        fontFamily: 'Roboto-Medium',
        display: "flex",
        fontSize: "14px",
        margin: "0 0 8px 0 ",
        fontWeight: "bold",
        color: "#444444",
    },
    titleRed: {
        fontFamily: 'Roboto-Medium',
        display: "flex",
        fontSize: "14px",
        margin: "0 0 8px 0 ",
        fontWeight: "bold",
        color: "#FD0000",
    },
    titleBlue: {
        fontFamily: 'Roboto-Medium',
        display: "flex",
        fontSize: "14px",
        margin: "0 0 8px 0 ",
        fontWeight: "bold",
        color: "#0A7FFF",
    },
    paragraphText: {
        fontFamily: 'Roboto-Regular',
        display: "flex",
        fontSize: "12px",
        margin: "0 ",
        // lineHeight: "20px"
    },
    paragraphTextBold: {
        fontFamily: 'Roboto-Medium',
        display: "flex",
        fontSize: "12px",
        margin: "0 ",
        fontWeight: "bold",
        // lineHeight: "20px"
    },
    link: {
        display: "flex",
        margin: "0",
        fontSize: "12px",
        textDecoration: "none",
        color: "#00487A",
        // lineHeight: "20px"
    },
    resultCardContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "24px 0",
        width: "540px",
    },
    linkContainer: {
        display: "flex",
        flexDirection: "row",
        padding: "0 0 5px 0",
        borderBottom: "1.5px solid #0A7FFF",
        margin: "8px 0px 0px",
    }

});

const ResultPdfNote = ({ noteData, noteStyle }) => {    
    const { title, paragraph1, paragraph2, paragraph3, linkText, linkSrc, } = noteData;
    const titleColor = noteStyle ? noteStyle : "";
    const paragraphWeight = noteStyle === "Blue" ? "Bold" : "";

    const myDoc = (
        <View style={styles.resultCardContainer}>
            <Text style={styles[`title${titleColor}`]}>{title}</Text>
            <Text style={styles[`paragraphText${paragraphWeight}`]}>{paragraph1}</Text>
            {paragraph2 && <Text style={styles[`paragraphText${paragraphWeight}`]}>{paragraph2}</Text>}
            {paragraph3 && <Text style={styles[`paragraphText${paragraphWeight}`]}>{paragraph3}</Text>}
            {linkText &&
                <View style={{ display: "flex", flexDirection: "row" }}>
                    <View style={styles.linkContainer}>
                        <Link style={styles.link} src={linkText}>
                            {linkText}
                        </Link>
                        <Image style={{ width: "18px", height: "20px", background: "blue", marginLeft: "8px" }} src={learnMoreIcon}></Image>
                    </View>
                </View>
            }
        </View>
    );

    return myDoc;
};

export default ResultPdfNote;
