import { S3 } from "aws-sdk";
import moment from 'moment';

const AWS_ACCESS_KEY = 'AKIASXIM2VUF5BL2UMNV'
const AWS_SECRET_KEY = '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'
const AWS_REGION = 'eu-west-1'
const AWS_S3_BUCKET = 'configusers'

const ENV = window.location.href === "https://filterconfig.netafim.com/" ? 'production' : 'dev';

const s3 = new S3({
    accessKeyId: AWS_ACCESS_KEY,
    secretAccessKey: AWS_SECRET_KEY,
    region: AWS_REGION,
});

export const uploadFileToS3 = async (fileDataObj: any, fileName: string) => {
    const params: any = {
        Bucket: AWS_S3_BUCKET,
        Key: ENV + "/" + fileName,
        Body: JSON.stringify(fileDataObj),
        ContentType: "application/json",
        ContentDisposition: "JSON",
    };
    try {
        const uploadedData = await s3.putObject(params).promise();
        if (!uploadedData) {
            console.log("Error: upload file to S3 failed");
            throw "Error: upload file to S3 failed"
        }
        else {
            console.log("file uploaded successfully");
            console.log(`file folder: ${ENV}`);
            console.log(`file name: ${fileName}`);
        }
    } catch (error) {
        console.log(error)
        throw error;
    }

}

export const getUniqueFileNameByEmail = (email: string): string => {
    const formattedDate = (moment(new Date())).format('DD-MMM-YYYY HH:mm:ss');
    return `${email} ${formattedDate}`;
}

export const getFormattedFileName = (): string => {
    const formattedDate = (moment(new Date())).format('DD-MMM-YYYY');
    return `Netafim™ Filter-Configurator-Results ${formattedDate}`;
}