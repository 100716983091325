import styles from "../../assets/styles/components/generic/my-popup.module.scss";
import MyButton from "./MyButton";
import closeIcon from "../../assets/images/icons/close-icon.svg";
import blueAlertIcon from "../../assets/images/icons/blue-alert-icon.svg";
import { useEffect, useState } from "react";
import { POPUP_ACCEPT } from "../../constats/languages/languagesKeys";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

type props = {
    kind: "cookies" | "button-note";
    label?: string;
    buttonTextKey?: number;
    show?: boolean;
}

const MyPopup = ({ kind, label, buttonTextKey = POPUP_ACCEPT, show = true }: props) => {

    const [isDisplayed, setIsDisplayed] = useState(false);
    const { language } = useSelector((state: RootState) => state.settingsReducer);

    const closePopup = () => {
        setIsDisplayed(false);
    }

    const openPopup = () => {
        setIsDisplayed(true)
    }

    useEffect(() => {
        setTimeout(() => setIsDisplayed(show), 500);
    }, [show])

    return show ? (
        <div className={styles["popup-" + kind] + " " + (!isDisplayed ? styles["closed"] : styles["open"])}>
            {kind === "cookies" && <script id="CookieDeclaration" src='https://consent.cookiebot.com/22d60cc6-ce29-4e82-b955-ac4f1bdd0394/cd.js' type="text/javascript" async></script>}
            <div className={styles["close"]} onClick={closePopup}>
                <img src={closeIcon} alt="close" className={styles["close-icon"]} />
            </div>
            <div className={styles["label"]}>{label}</div>
            {kind === "cookies" && <MyButton label={language[buttonTextKey]} width="small" onClick={closePopup} squeezed={true} />}
            {kind === "button-note" && <img src={blueAlertIcon} alt="Alert icon" />}
        </div>
    ) : <></>;
}

export default MyPopup;