import { IFiltrationTechnologyScore } from '../../interfaces/filtrationTechnologyOption';
import { EIrrigationMethodOptions, EMainWaterSourceOptions, ESuctionPointWaterSourceOptions, ERangeOptions, EBinaryOptions } from '../../enums/inputsAnswers.enum'

const { PIVOT, OPEN_FIELD_SPRINKLERS, MICRO_SPRINKLERS, DRIPPERLINE_MULTI_SEASON_LOW, DRIPPERLINE_MULTI_SEASON_HIGH, DRIPPERLINE_SINGLE_SEASON, ON_LINE_DRIPPERS_LOW, ON_LINE_DRIPPERS_HIGH } = EIrrigationMethodOptions;
const { HIGH, MEDIUM, LOW } = ERangeOptions;
const { YES, NO } = EBinaryOptions;
const EMainWater = EMainWaterSourceOptions;
const ESuctionPoint = ESuctionPointWaterSourceOptions;

export const irrigationMethodScore: { [key: number]: IFiltrationTechnologyScore } =
{
    [PIVOT]:                         { mediaFilterScore: 0,    discFilterScore: 0.7,  screenFilterScore: 1.05, hydroCycloneScore: 0 },
    [OPEN_FIELD_SPRINKLERS]:         { mediaFilterScore: 0,    discFilterScore: 0.7,  screenFilterScore: 1.05, hydroCycloneScore: 0 },
    [MICRO_SPRINKLERS]:              { mediaFilterScore: 0.35, discFilterScore: 1.05, screenFilterScore: 0.7,  hydroCycloneScore: 0 },
    [DRIPPERLINE_MULTI_SEASON_LOW]:  { mediaFilterScore: 1.05, discFilterScore: 0.7,  screenFilterScore: 0.35, hydroCycloneScore: 0 },
    [DRIPPERLINE_MULTI_SEASON_HIGH]: { mediaFilterScore: 0.7,  discFilterScore: 1.05, screenFilterScore: 0.35, hydroCycloneScore: 0 },
    [DRIPPERLINE_SINGLE_SEASON]:     { mediaFilterScore: 0.35, discFilterScore: 0.7,  screenFilterScore: 1.05, hydroCycloneScore: 0 },
    [ON_LINE_DRIPPERS_LOW]:          { mediaFilterScore: 1.05, discFilterScore: 0.7,  screenFilterScore: 0.35, hydroCycloneScore: 0 },
    [ON_LINE_DRIPPERS_HIGH]:         { mediaFilterScore: 0.35, discFilterScore: 1.05, screenFilterScore: 0.7,  hydroCycloneScore: 0 },
}

export const waterSourceScore: { mainWaterSource: { [key: number]: IFiltrationTechnologyScore }, suctionPointWaterSource: { [key: number]: IFiltrationTechnologyScore } } = {
    mainWaterSource:
    {
        [EMainWater.CANAL]:                    { mediaFilterScore: 0.16, discFilterScore: 0.24, screenFilterScore: 0.08, hydroCycloneScore: 0 },
        [EMainWater.LAKE]:                     { mediaFilterScore: 0.16, discFilterScore: 0.16, screenFilterScore: 0.08, hydroCycloneScore: 0 },
        [EMainWater.RIVER]:                    { mediaFilterScore: 0.16, discFilterScore: 0.24, screenFilterScore: 0.08, hydroCycloneScore: 0 },
        [EMainWater.RESERVOIR]:                { mediaFilterScore: 0.24, discFilterScore: 0.16, screenFilterScore: 0.08, hydroCycloneScore: 0 },
        [EMainWater.LAGOON]:                   { mediaFilterScore: 0.24, discFilterScore: 0.16, screenFilterScore: 0.08, hydroCycloneScore: 0 },
        [EMainWater.WELL]:                     { mediaFilterScore: 0.24, discFilterScore: 0.08, screenFilterScore: 0.24, hydroCycloneScore: 0.96 },
        [EMainWater.TREATED_WASTEWATER]:       { mediaFilterScore: 0.24, discFilterScore: 0.16, screenFilterScore: 0.08, hydroCycloneScore: 0 },
        [EMainWater.FARM_EFFLUENCE]:           { mediaFilterScore: 0.24, discFilterScore: 0.16, screenFilterScore: 0.08, hydroCycloneScore: 0 },
        [EMainWater.INDUSTRIAL_RECYCLE_WATER]: { mediaFilterScore: 0.24, discFilterScore: 0.16, screenFilterScore: 0.08, hydroCycloneScore: 0 },
        [EMainWater.NOT_APPLICABLE]:           { mediaFilterScore: 0,    discFilterScore: 0,    screenFilterScore: 0,    hydroCycloneScore: 0 },
    },
    suctionPointWaterSource:
    {
        [ESuctionPoint.CANAL]:                    { mediaFilterScore: 0.64, discFilterScore: 0.96, screenFilterScore: 0.32, hydroCycloneScore: 0 },
        [ESuctionPoint.LAKE]:                     { mediaFilterScore: 0.64, discFilterScore: 0.96, screenFilterScore: 0.32, hydroCycloneScore: 0 },
        [ESuctionPoint.RIVER]:                    { mediaFilterScore: 0.64, discFilterScore: 0.96, screenFilterScore: 0.32, hydroCycloneScore: 0 },
        [ESuctionPoint.RESERVOIR]:                { mediaFilterScore: 0.96, discFilterScore: 0.64, screenFilterScore: 0.32, hydroCycloneScore: 0 },
        [ESuctionPoint.LAGOON]:                   { mediaFilterScore: 0.96, discFilterScore: 0.96, screenFilterScore: 0.32, hydroCycloneScore: 0 },
        [ESuctionPoint.WELL]:                     { mediaFilterScore: 0.96, discFilterScore: 0.64, screenFilterScore: 0.96, hydroCycloneScore: 0.96 },
        [ESuctionPoint.OPERATIONAL_POOL]:         { mediaFilterScore: 0.64, discFilterScore: 0.96, screenFilterScore: 0.32, hydroCycloneScore: 0 },
        [ESuctionPoint.WATER_STORAGE_TANK]:       { mediaFilterScore: 0.64, discFilterScore: 0.96, screenFilterScore: 0.32, hydroCycloneScore: 0 },
        [ESuctionPoint.SEDIMENTATION_STRUCTURE]:  { mediaFilterScore: 0.64, discFilterScore: 0.96, screenFilterScore: 0.32, hydroCycloneScore: 0 },
        [ESuctionPoint.TREATED_WASTEWATER]:       { mediaFilterScore: 0.96, discFilterScore: 0.64, screenFilterScore: 0.32, hydroCycloneScore: 0 },
        [ESuctionPoint.FARM_EFFLUENCE]:           { mediaFilterScore: 0.96, discFilterScore: 0.64, screenFilterScore: 0.32, hydroCycloneScore: 0 },
        [ESuctionPoint.INDUSTRIAL_RECYCLE_WATER]: { mediaFilterScore: 0.96, discFilterScore: 0.64, screenFilterScore: 0.32, hydroCycloneScore: 0 },
    }
}

export const waterQualityScore: {
    Sand:                   { [key: number]: IFiltrationTechnologyScore },
    SiltClay:               { [key: number]: IFiltrationTechnologyScore },
    TSS:                    { [key: number]: IFiltrationTechnologyScore },
    PresenceOrganicMatters: { [key: number]: IFiltrationTechnologyScore },
    isFeMnExist:            { [key: number]: IFiltrationTechnologyScore },
} = {
    Sand: {
        [LOW]:    { mediaFilterScore: 0.05, discFilterScore: 0.15, screenFilterScore: 0.1, hydroCycloneScore: 0 },
        [MEDIUM]: { mediaFilterScore: 0.05, discFilterScore: 0.15, screenFilterScore: 0.1, hydroCycloneScore: 0.15 },
        [HIGH]:   { mediaFilterScore: 0.05, discFilterScore: 0.1, screenFilterScore: 0.15, hydroCycloneScore: 0.15 },
    },
    SiltClay: {
        [LOW]:    { mediaFilterScore: 0.05, discFilterScore: 0.15, screenFilterScore: 0.1, hydroCycloneScore: 0 },
        [MEDIUM]: { mediaFilterScore: 0.1, discFilterScore: 0.15, screenFilterScore: 0.05, hydroCycloneScore: 0 },
        [HIGH]:   { mediaFilterScore: 0.15, discFilterScore: 0.1, screenFilterScore: 0.05, hydroCycloneScore: 0 },
    },
    TSS: {
        [LOW]:    { mediaFilterScore: 0.1, discFilterScore: 0.3, screenFilterScore: 0.2, hydroCycloneScore: 0 },
        [MEDIUM]: { mediaFilterScore: 0.2, discFilterScore: 0.3, screenFilterScore: 0.1, hydroCycloneScore: 0 },
        [HIGH]:   { mediaFilterScore: 0.3, discFilterScore: 0.2, screenFilterScore: 0.1, hydroCycloneScore: 0 },
    },
    PresenceOrganicMatters: {
        [YES]: { mediaFilterScore: 0.15, discFilterScore: 0.1, screenFilterScore: 0.05, hydroCycloneScore: 0 },
        [NO]:  { mediaFilterScore: 0, discFilterScore: 0, screenFilterScore: 0, hydroCycloneScore: 0 },
    },
    isFeMnExist: {
        [YES]: { mediaFilterScore: 0, discFilterScore: 3, screenFilterScore: 2, hydroCycloneScore: 0 },
        [NO]:  { mediaFilterScore: 0, discFilterScore: 0, screenFilterScore: 0, hydroCycloneScore: 0 },
    }
}
