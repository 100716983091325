import { EDiscFilterModels, EFiltrationTechnologyOptions, EMediaFilterModels, EScreenFilterModels, EWaterQuality } from "../../enums";
import { EModelTagLineMassage } from "../../enums/resultsText";
import { IModelData, IResultsKey } from "../../interfaces/resultsData";

const { AVERAGE, POOR, IRON_REMOVAL } = EWaterQuality;
const { SANDSTORM_20, SANDSTORM_24, SANDSTORM_30, SANDSTORM_36, SANDSTORM_48, } = EMediaFilterModels;
const { SPINKLIN_2, SPINKLIN_3, APOLLO_ANGLE, APOLLO_TWINS, ALPHA_DISC_SINGLE_3, ALPHA_DISC_SINGLE_XL_3, ALPHA_DISC_SINGLE_XL_4, ALPHA_DISC_DUAL_XL, ALPHA_DISC_TRIO_XL } = EDiscFilterModels;
const { VERTICAL_2, VERTICAL_3, VERTICAL_3_JUMBO, VERTICAL_4, VERTICAL_4_JUMBO, VERTICAL_6, HORIZONTAL_4, HORIZONTAL_4_JUMBO, HORIZONTAL_6, HORIZONTAL_6_JUMBO, HORIZONTAL_8, HORIZONTAL_10 } = EScreenFilterModels;
const { DISC_FILTERS, MEDIA_FILTER, SCREEN_FILTERS } = EFiltrationTechnologyOptions;
const { ALWAYS_ON_GUARD, SUPERIOR_PROTECTION, LEAN_AND_MEAN, BIGGER_TOUGHER_BETTER } = EModelTagLineMassage;

export const getStringKey = (objKey: IResultsKey): string => {
    const { technology, model, units, inletOutletDiameter } = objKey;
    
    return`technology:${technology},model:${model},units:${units},inletOutletDiameter:${inletOutletDiameter}`;    
}

export const ModelsData: { [key: string]: IModelData } = {

    [`technology:${MEDIA_FILTER},model:${SANDSTORM_20},units:2,inletOutletDiameter:3`]:
    {
        serialNumber: 200203,
        maxFlowRate: { [AVERAGE]: 24, [POOR]: 20, [IRON_REMOVAL]: 12 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 16, withSilica: 9 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_20},units:3,inletOutletDiameter:3`]:
    {
        serialNumber: 200303,
        maxFlowRate: { [AVERAGE]: 36, [POOR]: 30, [IRON_REMOVAL]: 18 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 16, withSilica: 9 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_20},units:4,inletOutletDiameter:4`]:
    {
        serialNumber: 200404,
        maxFlowRate: { [AVERAGE]: 48, [POOR]: 40, [IRON_REMOVAL]: 24 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 16, withSilica: 9 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_20},units:5,inletOutletDiameter:4`]:
    {
        serialNumber: 200504,
        maxFlowRate: { [AVERAGE]: 60, [POOR]: 50, [IRON_REMOVAL]: 30 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 16, withSilica: 9 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_24},units:2,inletOutletDiameter:4`]:
    {
        serialNumber: 240204,
        maxFlowRate: { [AVERAGE]: 34, [POOR]: 28, [IRON_REMOVAL]: 16 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 22, withSilica: 12 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_24},units:3,inletOutletDiameter:4`]:
    {
        serialNumber: 240304,
        maxFlowRate: { [AVERAGE]: 51, [POOR]: 42, [IRON_REMOVAL]: 24 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 22, withSilica: 12 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_24},units:4,inletOutletDiameter:4`]:
    {
        serialNumber: 240404,
        maxFlowRate: { [AVERAGE]: 68, [POOR]: 56, [IRON_REMOVAL]: 32 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 22, withSilica: 12 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_24},units:5,inletOutletDiameter:4`]:
    {
        serialNumber: 240504,
        maxFlowRate: { [AVERAGE]: 85, [POOR]: 70, [IRON_REMOVAL]: 40 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 22, withSilica: 12 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_24},units:6,inletOutletDiameter:6`]:
    {
        serialNumber: 240606,
        maxFlowRate: { [AVERAGE]: 102, [POOR]: 84, [IRON_REMOVAL]: 48 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 22, withSilica: 12 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_24},units:7,inletOutletDiameter:6`]:
    {
        serialNumber: 240706,
        maxFlowRate: { [AVERAGE]: 119, [POOR]: 98, [IRON_REMOVAL]: 56 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 22, withSilica: 12 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_24},units:8,inletOutletDiameter:6`]:
    {
        serialNumber: 240806,
        maxFlowRate: { [AVERAGE]: 136, [POOR]: 112, [IRON_REMOVAL]: 64 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 22, withSilica: 12 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_24},units:9,inletOutletDiameter:6`]:
    {
        serialNumber: 240906,
        maxFlowRate: { [AVERAGE]: 153, [POOR]: 126, [IRON_REMOVAL]: 72 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 22, withSilica: 12 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_24},units:10,inletOutletDiameter:6`]:
    {
        serialNumber: 241006,
        maxFlowRate: { [AVERAGE]: 170, [POOR]: 140, [IRON_REMOVAL]: 80 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 22, withSilica: 12 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_30},units:2,inletOutletDiameter:6`]:
    {
        serialNumber: 300206,
        maxFlowRate: { [AVERAGE]: 54, [POOR]: 44, [IRON_REMOVAL]: 26 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 36, withSilica: 20 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_30},units:3,inletOutletDiameter:6`]:
    {
        serialNumber: 300306,
        maxFlowRate: { [AVERAGE]: 81, [POOR]: 66, [IRON_REMOVAL]: 39 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 36, withSilica: 20 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_30},units:4,inletOutletDiameter:6`]:
    {
        serialNumber: 300406,
        maxFlowRate: { [AVERAGE]: 108, [POOR]: 88, [IRON_REMOVAL]: 52 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 36, withSilica: 20 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_30},units:5,inletOutletDiameter:6`]:
    {
        serialNumber: 300506,
        maxFlowRate: { [AVERAGE]: 135, [POOR]: 110, [IRON_REMOVAL]: 65 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 36, withSilica: 20 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_30},units:6,inletOutletDiameter:8`]:
    {
        serialNumber: 300608,
        maxFlowRate: { [AVERAGE]: 162, [POOR]: 132, [IRON_REMOVAL]: 78 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 36, withSilica: 20 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_30},units:7,inletOutletDiameter:8`]:
    {
        serialNumber: 300708,
        maxFlowRate: { [AVERAGE]: 198, [POOR]: 154, [IRON_REMOVAL]: 91 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 36, withSilica: 20 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_30},units:8,inletOutletDiameter:8`]:
    {
        serialNumber: 300808,
        maxFlowRate: { [AVERAGE]: 216, [POOR]: 176, [IRON_REMOVAL]: 104 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 36, withSilica: 20 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_36},units:2,inletOutletDiameter:6`]:
    {
        serialNumber: 360206,
        maxFlowRate: { [AVERAGE]: 80, [POOR]: 64, [IRON_REMOVAL]: 38 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 50, withSilica: 28 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_36},units:3,inletOutletDiameter:6`]:
    {
        serialNumber: 360306,
        maxFlowRate: { [AVERAGE]: 120, [POOR]: 96, [IRON_REMOVAL]: 57 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 50, withSilica: 28 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_36},units:4,inletOutletDiameter:6`]:
    {
        serialNumber: 360406,
        maxFlowRate: { [AVERAGE]: 160, [POOR]: 128, [IRON_REMOVAL]: 76 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 50, withSilica: 28 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_36},units:5,inletOutletDiameter:8`]:
    {
        serialNumber: 360508,
        maxFlowRate: { [AVERAGE]: 200, [POOR]: 160, [IRON_REMOVAL]: 95 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 50, withSilica: 28 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_36},units:6,inletOutletDiameter:8`]:
    {
        serialNumber: 360608,
        maxFlowRate: { [AVERAGE]: 240, [POOR]: 192, [IRON_REMOVAL]: 114 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 50, withSilica: 28 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_36},units:7,inletOutletDiameter:8`]:
    {
        serialNumber: 360708,
        maxFlowRate: { [AVERAGE]: 280, [POOR]: 224, [IRON_REMOVAL]: 133 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 50, withSilica: 28 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_36},units:8,inletOutletDiameter:8`]:
    {
        serialNumber: 360808,
        maxFlowRate: { [AVERAGE]: 320, [POOR]: 256, [IRON_REMOVAL]: 152 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 50, withSilica: 28 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_48},units:3,inletOutletDiameter:8`]:
    {
        serialNumber: 480308,
        maxFlowRate: { [AVERAGE]: 204, [POOR]: 168, [IRON_REMOVAL]: 99 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 80, withSilica: 43 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_48},units:4,inletOutletDiameter:8`]:
    {
        serialNumber: 480408,
        maxFlowRate: { [AVERAGE]: 272, [POOR]: 224, [IRON_REMOVAL]: 132 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 80, withSilica: 43 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_48},units:5,inletOutletDiameter:10`]:
    {
        serialNumber: 480510,
        maxFlowRate: { [AVERAGE]: 340, [POOR]: 280, [IRON_REMOVAL]: 165 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 80, withSilica: 43 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_48},units:6,inletOutletDiameter:10`]:
    {
        serialNumber: 480610,
        maxFlowRate: { [AVERAGE]: 408, [POOR]: 336, [IRON_REMOVAL]: 198 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 80, withSilica: 43 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_48},units:7,inletOutletDiameter:10`]:
    {
        serialNumber: 480710,
        maxFlowRate: { [AVERAGE]: 476, [POOR]: 392, [IRON_REMOVAL]: 231 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 80, withSilica: 43 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_48},units:7,inletOutletDiameter:10`]:
    {
        serialNumber: 480810,
        maxFlowRate: { [AVERAGE]: 544, [POOR]: 448, [IRON_REMOVAL]: 264 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 80, withSilica: 43 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_48},units:9,inletOutletDiameter:12`]:
    {
        serialNumber: 480912,
        maxFlowRate: { [AVERAGE]: 612, [POOR]: 504, [IRON_REMOVAL]: 297 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 80, withSilica: 43 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_48},units:10,inletOutletDiameter:12`]:
    {
        serialNumber: 481012,
        maxFlowRate: { [AVERAGE]: 680, [POOR]: 560, [IRON_REMOVAL]: 330 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 80, withSilica: 43 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_48},units:11,inletOutletDiameter:12`]:
    {
        serialNumber: 481112,
        maxFlowRate: { [AVERAGE]: 748, [POOR]: 616, [IRON_REMOVAL]: 363 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 80, withSilica: 43 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${MEDIA_FILTER},model:${SANDSTORM_48},units:12,inletOutletDiameter:12`]:
    {
        serialNumber: 481212,
        maxFlowRate: { [AVERAGE]: 816, [POOR]: 672, [IRON_REMOVAL]: 396 },
        maxPressure: 8,
        minBackFlushPressure: 2,
        backFlushingFlowRateMediaFilter: { withBasalt: 80, withSilica: 43 },
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Media-filters/',
        modelPdfLink: '',
        modelTagline: ALWAYS_ON_GUARD
    },
    [`technology:${DISC_FILTERS},model:${SPINKLIN_2},units:2,inletOutletDiameter:3`]:
    {
        serialNumber: 223,
        maxFlowRate: { [AVERAGE]: 30, [POOR]: 24, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.8,
        backFlushingFlowRate: 10,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${SPINKLIN_2},units:3,inletOutletDiameter:4`]:
    {
        serialNumber: 324,
        maxFlowRate: { [AVERAGE]: 45, [POOR]: 36, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.8,
        backFlushingFlowRate: 10,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${SPINKLIN_2},units:4,inletOutletDiameter:4`]:
    {
        serialNumber: 424,
        maxFlowRate: { [AVERAGE]: 60, [POOR]: 48, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.8,
        backFlushingFlowRate: 10,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${SPINKLIN_2},units:5,inletOutletDiameter:4`]:
    {
        serialNumber: 524,
        maxFlowRate: { [AVERAGE]: 75, [POOR]: 60, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.8,
        backFlushingFlowRate: 10,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${SPINKLIN_2},units:6,inletOutletDiameter:4`]:
    {
        serialNumber: 624,
        maxFlowRate: { [AVERAGE]: 90, [POOR]: 72, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.8,
        backFlushingFlowRate: 10,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${SPINKLIN_2},units:7,inletOutletDiameter:4`]:
    {
        serialNumber: 724,
        maxFlowRate: { [AVERAGE]: 105, [POOR]: 84, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.8,
        backFlushingFlowRate: 10,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${SPINKLIN_3},units:3,inletOutletDiameter:6`]:
    {
        serialNumber: 336,
        maxFlowRate: { [AVERAGE]: 90, [POOR]: 72, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.8,
        backFlushingFlowRate: 20,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${SPINKLIN_3},units:4,inletOutletDiameter:6`]:
    {
        serialNumber: 436,
        maxFlowRate: { [AVERAGE]: 120, [POOR]: 96, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.8,
        backFlushingFlowRate: 20,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${SPINKLIN_3},units:5,inletOutletDiameter:6`]:
    {
        serialNumber: 536,
        maxFlowRate: { [AVERAGE]: 150, [POOR]: 120, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.8,
        backFlushingFlowRate: 20,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${SPINKLIN_3},units:6,inletOutletDiameter:6`]:
    {
        serialNumber: 636,
        maxFlowRate: { [AVERAGE]: 180, [POOR]: 144, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.8,
        backFlushingFlowRate: 20,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${SPINKLIN_3},units:7,inletOutletDiameter:8`]:
    {
        serialNumber: 736,
        maxFlowRate: { [AVERAGE]: 210, [POOR]: 168, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.8,
        backFlushingFlowRate: 20,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${SPINKLIN_3},units:8,inletOutletDiameter:8`]:
    {
        serialNumber: 838,
        maxFlowRate: { [AVERAGE]: 240, [POOR]: 192, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.8,
        backFlushingFlowRate: 20,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${APOLLO_ANGLE},units:3,inletOutletDiameter:6`]:
    {
        serialNumber: 336,
        maxFlowRate: { [AVERAGE]: 105, [POOR]: 90, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.1,
        backFlushingFlowRate: 24,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${APOLLO_ANGLE},units:4,inletOutletDiameter:6`]:
    {
        serialNumber: 436,
        maxFlowRate: { [AVERAGE]: 140, [POOR]: 120, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.1,
        backFlushingFlowRate: 24,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${APOLLO_ANGLE},units:5,inletOutletDiameter:6`]:
    {
        serialNumber: 536,
        maxFlowRate: { [AVERAGE]: 175, [POOR]: 150, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.1,
        backFlushingFlowRate: 24,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${APOLLO_ANGLE},units:6,inletOutletDiameter:8`]:
    {
        serialNumber: 638,
        maxFlowRate: { [AVERAGE]: 210, [POOR]: 180, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.1,
        backFlushingFlowRate: 24,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${APOLLO_ANGLE},units:7,inletOutletDiameter:8`]:
    {
        serialNumber: 738,
        maxFlowRate: { [AVERAGE]: 245, [POOR]: 210, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.1,
        backFlushingFlowRate: 24,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${APOLLO_ANGLE},units:7,inletOutletDiameter:8`]:
    {
        serialNumber: 738,
        maxFlowRate: { [AVERAGE]: 245, [POOR]: 210, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.1,
        backFlushingFlowRate: 24,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${APOLLO_TWINS},units:3,inletOutletDiameter:8`]:
    {
        serialNumber: 348,
        maxFlowRate: { [AVERAGE]: 210, [POOR]: 180, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.1,
        backFlushingFlowRate: 48,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${APOLLO_TWINS},units:4,inletOutletDiameter:10`]:
    {
        serialNumber: 4410,
        maxFlowRate: { [AVERAGE]: 280, [POOR]: 240, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.1,
        backFlushingFlowRate: 48,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${APOLLO_TWINS},units:5,inletOutletDiameter:10`]:
    {
        serialNumber: 5410,
        maxFlowRate: { [AVERAGE]: 350, [POOR]: 300, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.1,
        backFlushingFlowRate: 48,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${APOLLO_TWINS},units:6,inletOutletDiameter:12`]:
    {
        serialNumber: 6412,
        maxFlowRate: { [AVERAGE]: 420, [POOR]: 360, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.1,
        backFlushingFlowRate: 48,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${APOLLO_TWINS},units:7,inletOutletDiameter:12`]:
    {
        serialNumber: 7412,
        maxFlowRate: { [AVERAGE]: 490, [POOR]: 420, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.1,
        backFlushingFlowRate: 48,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${APOLLO_TWINS},units:8,inletOutletDiameter:12`]:
    {
        serialNumber: 8412,
        maxFlowRate: { [AVERAGE]: 560, [POOR]: 480, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2.1,
        backFlushingFlowRate: 48,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters/',
        modelPdfLink: '',
        modelTagline: SUPERIOR_PROTECTION
    },
    [`technology:${DISC_FILTERS},model:${ALPHA_DISC_SINGLE_3},units:1,inletOutletDiameter:3`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 40, [POOR]: 35, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 1.5,
        backFlushingFlowRate: 10,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters-alphadisc/',
        modelPdfLink: '',
        modelTagline: LEAN_AND_MEAN
    },
    [`technology:${DISC_FILTERS},model:${ALPHA_DISC_SINGLE_XL_3},units:1,inletOutletDiameter:3`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 60, [POOR]: 50, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 1.5,
        backFlushingFlowRate: 20,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters-alphadisc/',
        modelPdfLink: '',
        modelTagline: LEAN_AND_MEAN
    },
    [`technology:${DISC_FILTERS},model:${ALPHA_DISC_SINGLE_XL_4},units:1,inletOutletDiameter:4`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 90, [POOR]: 80, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 1.5,
        backFlushingFlowRate: 20,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters-alphadisc/',
        modelPdfLink: '',
        modelTagline: LEAN_AND_MEAN
    },
    [`technology:${DISC_FILTERS},model:${ALPHA_DISC_DUAL_XL},units:1,inletOutletDiameter:6`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 180, [POOR]: 160, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 1.5,
        backFlushingFlowRate: 20,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters-alphadisc/',
        modelPdfLink: '',
        modelTagline: LEAN_AND_MEAN
    },
    [`technology:${DISC_FILTERS},model:${ALPHA_DISC_TRIO_XL},units:1,inletOutletDiameter:8`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 270, [POOR]: 240, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 1.5,
        backFlushingFlowRate: 20,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/disc-filters-alphadisc/',
        modelPdfLink: '',
        modelTagline: LEAN_AND_MEAN
    },
    [`technology:${SCREEN_FILTERS},model:${VERTICAL_2},units:1,inletOutletDiameter:2`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 25, [POOR]: 15, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2,
        backFlushingFlowRate: 12,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Screen-filters/',
        modelPdfLink: '',
        modelTagline: BIGGER_TOUGHER_BETTER
    },
    [`technology:${SCREEN_FILTERS},model:${VERTICAL_3},units:1,inletOutletDiameter:3`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 35, [POOR]: 25, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2,
        backFlushingFlowRate: 12,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Screen-filters/',
        modelPdfLink: '',
        modelTagline: BIGGER_TOUGHER_BETTER
    },
    [`technology:${SCREEN_FILTERS},model:${VERTICAL_3_JUMBO},units:1,inletOutletDiameter:3`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 50, [POOR]: 40, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2,
        backFlushingFlowRate: 18,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Screen-filters/',
        modelPdfLink: '',
        modelTagline: BIGGER_TOUGHER_BETTER
    },
    [`technology:${SCREEN_FILTERS},model:${VERTICAL_4},units:1,inletOutletDiameter:4`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 75, [POOR]: 65, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2,
        backFlushingFlowRate: 18,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Screen-filters/',
        modelPdfLink: '',
        modelTagline: BIGGER_TOUGHER_BETTER
    },
    [`technology:${SCREEN_FILTERS},model:${VERTICAL_4_JUMBO},units:1,inletOutletDiameter:4`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 80, [POOR]: 70, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2,
        backFlushingFlowRate: 12,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Screen-filters/',
        modelPdfLink: '',
        modelTagline: BIGGER_TOUGHER_BETTER
    },
    [`technology:${SCREEN_FILTERS},model:${VERTICAL_6},units:1,inletOutletDiameter:6`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 100, [POOR]: 85, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2,
        backFlushingFlowRate: 12,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Screen-filters/',
        modelPdfLink: '',
        modelTagline: BIGGER_TOUGHER_BETTER
    },
    [`technology:${SCREEN_FILTERS},model:${HORIZONTAL_4},units:1,inletOutletDiameter:4`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 80, [POOR]: 80, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2,
        backFlushingFlowRate: 24,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Screen-filters/',
        modelPdfLink: '',
        modelTagline: BIGGER_TOUGHER_BETTER
    },
    [`technology:${SCREEN_FILTERS},model:${HORIZONTAL_4_JUMBO},units:1,inletOutletDiameter:4`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 100, [POOR]: 100, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2,
        backFlushingFlowRate: 36,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Screen-filters/',
        modelPdfLink: '',
        modelTagline: BIGGER_TOUGHER_BETTER
    },
    [`technology:${SCREEN_FILTERS},model:${HORIZONTAL_6},units:1,inletOutletDiameter:6`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 120, [POOR]: 120, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2,
        backFlushingFlowRate: 24,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Screen-filters/',
        modelPdfLink: '',
        modelTagline: BIGGER_TOUGHER_BETTER
    },
    [`technology:${SCREEN_FILTERS},model:${HORIZONTAL_6_JUMBO},units:1,inletOutletDiameter:6`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 150, [POOR]: 150, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2,
        backFlushingFlowRate: 36,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Screen-filters/',
        modelPdfLink: '',
        modelTagline: BIGGER_TOUGHER_BETTER
    },
    [`technology:${SCREEN_FILTERS},model:${HORIZONTAL_8},units:1,inletOutletDiameter:8`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 250, [POOR]: 200, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2,
        backFlushingFlowRate: 36,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Screen-filters/',
        modelPdfLink: '',
        modelTagline: BIGGER_TOUGHER_BETTER
    },
    [`technology:${SCREEN_FILTERS},model:${HORIZONTAL_10},units:1,inletOutletDiameter:10`]:
    {
        serialNumber: -1,
        maxFlowRate: { [AVERAGE]: 300, [POOR]: 250, [IRON_REMOVAL]: 0 },
        maxPressure: 10,
        minBackFlushPressure: 2,
        backFlushingFlowRate: 36,
        modelNetafimWebsiteLink: 'https://www.netafim.com/en/products-and-solutions/product-offering/filters/Screen-filters/',
        modelPdfLink: '',
        modelTagline: BIGGER_TOUGHER_BETTER
    },
}




