import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import QuizStep1 from "../../components/pages/QuizStep1";
import QuizStep2 from "../../components/pages/QuizStep2";
import QuizStep3 from "../../components/pages/QuizStep3";
import Registration from "../../components/pages/Registration";
import Results from "../../components/pages/Results";
import { FOOTER_NOTE_3, LOWER_HEADER_4, PAGE_SUBTITLE_2, PAGE_SUBTITLE_3, PAGE_SUBTITLE_4, PAGE_TITLE_1, PAGE_TITLE_2, PAGE_TITLE_3, PAGE_TITLE_4, PROGRESS_BAR_STEP_TITLE_1, PROGRESS_BAR_STEP_TITLE_2, PROGRESS_BAR_STEP_TITLE_3 } from "../../constats/languages/languagesKeys";


export interface quizStep {
    id: string;
    component: JSX.Element;
    titleKey: number;
    required: boolean;
    hiddenProgress: boolean;
    quizStep: boolean;
    nextDisabled: boolean;
    h1Key?: number;
    h2Key?: number;
    hasPopup?: boolean;
    popupTextKey?: number;
}

const INITIAL_STEPS: quizStep[] = [
    {
        id: "Q1",
        component: <QuizStep1 />,
        titleKey: PROGRESS_BAR_STEP_TITLE_1,
        required: true,
        hiddenProgress: false,
        quizStep: true,
        nextDisabled: true,
        h1Key: PAGE_TITLE_1,
    },
    {
        id: "Q2",
        component: <QuizStep2 />,
        titleKey: PROGRESS_BAR_STEP_TITLE_2,
        required: true,
        hiddenProgress: false,
        quizStep: true,
        nextDisabled: true,
        h1Key: PAGE_TITLE_2,
        h2Key: PAGE_SUBTITLE_2
    },
    {
        id: "Q3",
        component: <QuizStep3 />,
        titleKey: PROGRESS_BAR_STEP_TITLE_3,
        required: false,
        hiddenProgress: false,
        quizStep: true,
        nextDisabled: false,
        h1Key: PAGE_TITLE_3,
        h2Key: PAGE_SUBTITLE_3,
        hasPopup: true,
        popupTextKey: FOOTER_NOTE_3
    },
    {
        id: "REG",
        component: <Registration />,
        titleKey: -1,
        required: true,
        hiddenProgress: true,
        quizStep: false,
        nextDisabled: false,
        h1Key: PAGE_TITLE_4,
        h2Key: PAGE_SUBTITLE_4
    },
    {
        id: "RES",
        component: <Results />,
        titleKey: LOWER_HEADER_4,
        nextDisabled: false,
        required: true,
        hiddenProgress: false,
        quizStep: false,
        h1Key: LOWER_HEADER_4,
    }
];

export interface quizStepsState {
    steps: quizStep[];
    currentStep: number;
}

const initialState: quizStepsState = { steps: INITIAL_STEPS, currentStep: 0 };

const cartSlice = createSlice({
    name: "quizSteps",
    initialState: initialState,
    reducers: {
        nextStep(state, action: PayloadAction<{ isJumpToResult?: boolean }>) {
            if (state.currentStep < state.steps.length - 1)
                state.currentStep++;
            if (action.payload.isJumpToResult) {
                state.currentStep++;
            }
        },
        prevStep(state) {
            if (state.currentStep > 0)
                state.currentStep--;
        },
        setNextDisabled: (state, action: PayloadAction<{ data: boolean }>) => {
            const { data } = action.payload;
            const { currentStep } = state;
            let tempState: any = state;
            tempState.steps[currentStep].nextDisabled = data;
            state = tempState
        },
        resetSteps(state) {
            return { ...initialState };
        },
        goToStep(state, action: PayloadAction<{ newStep: number }>) {
            const { newStep } = action.payload;
            if (state.steps.length > newStep)
                state.currentStep = newStep;
        },
    },
});

export const { nextStep, prevStep, setNextDisabled, resetSteps, goToStep } = cartSlice.actions;

export default cartSlice.reducer;
