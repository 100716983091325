import { IUnitModelAndDiameter } from "../constats/languages/constText";
import { EWaterQuality } from "../enums";
import { IRecommendedModels } from "../interfaces/calculations";
import { IModelData } from "../interfaces/resultsData";
import { IUnits } from "../interfaces/settings";
import { IResultCardData } from "../store/slices/resultPdfSlice";
import { getfiltretionTechnologyKey, getTechnologyFilterTextKey, getUnitModelAndDiameterStr } from "./languagesHelperService";
import { ALERT_MAX_PRESSURE, ALERT_MIN_PRESSURE, CONTACT_NETAFIM, FOOTER_CONTACT_NETAFIM, MODEL_SHEET, RESULTS_CARD_TEXT_10, RESULTS_CARD_TEXT_11, RESULTS_CARD_TEXT_12, RESULTS_CARD_TEXT_2, RESULTS_CARD_TEXT_3, RESULTS_CARD_TEXT_4, RESULTS_CARD_TEXT_5, RESULTS_CARD_TEXT_6, RESULTS_CARD_TEXT_7, RESULTS_CARD_TEXT_8, RESULTS_CARD_TEXT_9 } from "../constats/languages/languagesKeys";
import { CONTACT_NETAFIM_LINK } from "../constats/links";

export const getResultCardData = (technology: number, recommendedModel: IRecommendedModels | undefined, language: { [model: number]: string }, unitsParams: IUnits, modelDetails: IModelData | null | undefined, waterQuality: EWaterQuality | undefined): IResultCardData => {
    const { unit, modelDdiameter, learnMoreLinkMessageKeyMobile, learnMoreLinkMessageKeyDesktop }: IUnitModelAndDiameter = recommendedModel ? getUnitModelAndDiameterStr(recommendedModel.technology, recommendedModel.model) : { unit: '', modelDdiameter: '', learnMoreLinkMessageKeyMobile: -1, learnMoreLinkMessageKeyDesktop: -1 };
    const filtretionTechnology: number = getfiltretionTechnologyKey(technology);
    const technologyFilter: number = getTechnologyFilterTextKey(technology);
    const { isPressureMaxNotification, isPressureMinNotification } = recommendedModel ? recommendedModel : { isPressureMaxNotification: false, isPressureMinNotification: false };
    let result: IResultCardData = { technologyTitle: language[filtretionTechnology], isContactNetafim: !(recommendedModel && modelDetails), isBackFlushingFlowRateMediaFilter: !!(modelDetails?.backFlushingFlowRateMediaFilter) };

    if (recommendedModel && modelDetails) {
        result.title = `${unit} ${modelDetails?.serialNumber !== -1 ? modelDetails?.serialNumber : ''} ${language[technologyFilter ? technologyFilter : -1]}`;
        result.subTitle = `${unit} ${modelDdiameter} ${language[RESULTS_CARD_TEXT_2]} ${recommendedModel.units} ${recommendedModel.units > 1 ? language[RESULTS_CARD_TEXT_4] : language[RESULTS_CARD_TEXT_3]} ${recommendedModel.inletOutletDiameter}" ${language[RESULTS_CARD_TEXT_5]}`;
    }
    result.contactNetafim = `${language[CONTACT_NETAFIM]}`;
    result.contactNetafimLink = `${language[FOOTER_CONTACT_NETAFIM]}`;
    result.contactNetafimLinkSrc = CONTACT_NETAFIM_LINK;

    if (modelDetails) {
        result.systemFlowRate = `${language[RESULTS_CARD_TEXT_7]}: `;
        result.systemFlowRateValue = `${(modelDetails.maxFlowRate[waterQuality!] * unitsParams.flowrateFactor).toFixed(2)} ${unitsParams.flowrateLabel}`;
        result.maxPressure = `${language[RESULTS_CARD_TEXT_8]}: `;
        result.maxPressureValue = `${(modelDetails.maxPressure * unitsParams.pressureFactor).toFixed(2)} ${unitsParams.pressureLabel}`;
        result.backFlushingFlowRate = `${language[RESULTS_CARD_TEXT_9]}: `;
        if (modelDetails?.backFlushingFlowRate) {
            result.backFlushingFlowRateValue = `${(modelDetails?.backFlushingFlowRate! * unitsParams.flowrateFactor).toFixed(2)} ${unitsParams.flowrateLabel}`;
        }
        else if (modelDetails?.backFlushingFlowRateMediaFilter) {
            result.backFlushingFlowRateBasalt = `${language[RESULTS_CARD_TEXT_10]}: `;
            result.backFlushingFlowRateBasaltValue = `${(modelDetails?.backFlushingFlowRateMediaFilter?.withBasalt * unitsParams.flowrateFactor).toFixed(2)} ${unitsParams.flowrateLabel}`;
            result.backFlushingFlowRateSilica = `${language[RESULTS_CARD_TEXT_11]}: `;
            result.backFlushingFlowRateSilicaValue = `${(modelDetails?.backFlushingFlowRateMediaFilter?.withSilica * unitsParams.flowrateFactor).toFixed(2)} ${unitsParams.flowrateLabel}`;
        }
        result.minPressure = `${language[RESULTS_CARD_TEXT_12]}: `;
        result.minPressureValue = `${(modelDetails.minBackFlushPressure * unitsParams.pressureFactor).toFixed(2)} ${unitsParams.pressureLabel}`

        if (isPressureMaxNotification || isPressureMinNotification) {
            result.pressureAlert = `${language[isPressureMaxNotification ? ALERT_MAX_PRESSURE : ALERT_MIN_PRESSURE]}`;
        }

        result.tagLine = language[modelDetails?.modelTagline!]
        result.learnMore = `${language[learnMoreLinkMessageKeyDesktop]}`;
        result.learnMoreLink = modelDetails?.modelNetafimWebsiteLink;
        result.drawingMore = `${language[MODEL_SHEET]}`;
        result.drawingMoreLink = modelDetails?.modelPdfLink;

    }
    return result;
}