import { ERangeOptions } from "../../enums";
import { IMySelectOption } from "../../interfaces/selectQuestion";
import { ANSWER3_1_1, ANSWER3_1_2, ANSWER3_1_3, ANSWER3_2_1, ANSWER3_2_2, ANSWER3_2_3, ANSWER3_3_1, ANSWER3_3_2, ANSWER3_3_3 } from "../languages/languagesKeys";

const { HIGH, MEDIUM, LOW } = ERangeOptions;

export const sandArr: IMySelectOption[] = [
    { field: 'sand', value: LOW, titleKey: ANSWER3_1_1 },
    { field: 'sand', value: MEDIUM, titleKey: ANSWER3_1_2 },
    { field: 'sand', value: HIGH, titleKey: ANSWER3_1_3 },
]

export const siltClayArr: IMySelectOption[] = [
    { field: 'siltClay', value: LOW, titleKey: ANSWER3_2_1 },
    { field: 'siltClay', value: MEDIUM, titleKey: ANSWER3_2_2 },
    { field: 'siltClay', value: HIGH, titleKey: ANSWER3_2_3 },
]

export const tSSArr: IMySelectOption[] = [
    { field: 'tSS', value: LOW, titleKey: ANSWER3_3_1 },
    { field: 'tSS', value: MEDIUM, titleKey: ANSWER3_3_2 },
    { field: 'tSS', value: HIGH, titleKey: ANSWER3_3_3 },
]
