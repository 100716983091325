// Header / Footer / Settings
export const LANGUAGE_CONVERSION_TITLE: number = 100
export const UNITS_CONVERSION_TITLE: number = 108
export const UNITS_CONVERSION_METRIC: number = 109
export const UNITS_CONVERSION_US: number = 110
export const RESTART: number = 111

// Welcome page
export const WELCOME_TITLE_LINE_1: number = 200
export const WELCOME_CONTENT_P_1: number = 201
export const WELCOME_CONTENT_P_2: number = 202

export const COOKIES_LABEL: number = 203
export const POPUP_ACCEPT: number = 204
export const WELCOME_BUTTON: number = 205

// Quiz Step 1
export const PROGRESS_BAR_STEP_TITLE_1: number = 300
export const PROGRESS_BAR_NEXT_STEP_TITLE_1: number = 301
export const PAGE_TITLE_1: number = 302
export const QUESTION1_1: number = 303
export const QUESTION1_2: number = 304
export const QUESTION1_2_ERROR_M: number = 305
export const QUESTION1_2_ERROR_US: number = 3051
export const QUESTION1_2_ERROR_LETTERS: number = 3052
export const QUESTION1_3: number = 306
export const ANSWER1_3_1: number = 307
export const ANSWER1_3_2: number = 308
export const ANSWER1_3_3: number = 309
export const ANSWER1_3_4: number = 310
export const ANSWER1_3_5: number = 311
export const ANSWER1_3_6: number = 312
export const ANSWER1_3_7: number = 313
export const ANSWER1_3_8: number = 314

// Quiz Step 2
export const PROGRESS_BAR_STEP_TITLE_2: number = 400
export const PROGRESS_BAR_NEXT_STEP_TITLE_2: number = 401
export const PAGE_TITLE_2: number = 402
export const PAGE_SUBTITLE_2: number = 403
export const QUESTION2_1: number = 404
export const ANSWER2_1_1: number = 405
export const ANSWER2_1_2: number = 406
export const ANSWER2_1_3: number = 407
export const ANSWER2_1_4: number = 408
export const ANSWER2_1_5: number = 409
export const ANSWER2_1_6: number = 410
export const ANSWER2_1_7: number = 411
export const ANSWER2_1_8: number = 412
export const ANSWER2_1_9: number = 413
export const ANSWER2_1_10: number = 414

export const QUESTION2_2: number = 415
export const ANSWER2_2_1: number = 416
export const ANSWER2_2_2: number = 417
export const ANSWER2_2_3: number = 418
export const ANSWER2_2_4: number = 419
export const ANSWER2_2_5: number = 420
export const ANSWER2_2_6: number = 421
export const ANSWER2_2_7: number = 422
export const ANSWER2_2_8: number = 423
export const ANSWER2_2_9: number = 424
export const ANSWER2_2_10: number = 425
export const ANSWER2_2_11: number = 426
export const ANSWER2_2_12: number = 427

// Quiz Step 3
export const PROGRESS_BAR_STEP_TITLE_3: number = 500
export const PROGRESS_BAR_NEXT_STEP_TITLE_3: number = 501
export const PAGE_TITLE_3: number = 502
export const PAGE_SUBTITLE_3: number = 503
export const QUESTION3_1: number = 504
export const ANSWER3_1_1: number = 505
export const ANSWER3_1_2: number = 506
export const ANSWER3_1_3: number = 507
export const QUESTION3_2: number = 508
export const ANSWER3_2_1: number = 509
export const ANSWER3_2_2: number = 510
export const ANSWER3_2_3: number = 511
export const QUESTION3_3: number = 512
export const ANSWER3_3_1: number = 513
export const ANSWER3_3_2: number = 514
export const ANSWER3_3_3: number = 515
export const QUESTION3_4: number = 516
export const ANSWER3_4_1: number = 517
export const ANSWER3_4_2: number = 518
export const QUESTION3_5: number = 519
export const IRON_DISCLAIMER: number = 520
export const ANSWER3_5_1: number = 521
export const ANSWER3_5_2: number = 522

export const PAGE_FOOTER_1: number = 315
export const BACK_BUTTON: number = 428
export const PAGE_FOOTER_2_2: number = 429
export const FOOTER_NOTE_3: number = 523
export const PAGE_FOOTER_3_1: number = 524
export const PAGE_FOOTER_3_2: number = 525
export const PAGE_FOOTER_3_3: number = 526
export const PAGE_FOOTER_4: number = 616
export const PAGE_FOOTER_5_1: number = 736

// Registration
export const PAGE_TITLE_4: number = 600
export const PAGE_SUBTITLE_4: number = 601
export const FIELD_1: number = 602
export const FIELD_2: number = 603
export const FIELD_3: number = 604
export const MANDATORY_FIELD_ERROR: number = 605
export const PATTERN_ERROR: number = 606
export const FIELD_4: number = 607
export const OPTION_4_1: number = 608
export const OPTION_4_2: number = 609
export const OPTION_4_3: number = 610
export const OPTION_4_4: number = 611
export const OPTION_4_5: number = 612
export const FIELD_5: number = 613
export const CHECKBOX_1_TEXT_1: number = 614
export const CHECKBOX_1_TERMS: number = 6141
export const CHECKBOX_1_TEXT_2: number = 6142
export const CHECKBOX_1_POLICY: number = 6143
export const CHECKBOX_1_TEXT_3: number = 6144
export const CHECKBOX_2: number = 615

// Results
export const LOWER_HEADER_4: number = 700
export const CONDITIONS_TITLE: number = 7001
export const CONDITIONS_WATER_SOURCE: number = 7002
export const CONDITIONS_SUCTION_POINT: number = 7003
export const CONDITIONS_SAND: number = 7004
export const CONDITIONS_SILT_CLAY: number = 7005
export const CONDITIONS_TSS: number = 7006
export const CONDITIONS_ORGANIC_MATTER: number = 7007
export const CONDITIONS_FE_MN: number = 7008
export const CONDITIONS_DROPDOWN: number = 7009

export const RESULTS_RANK_1: number = 701
export const RESULTS_RANK_1_SUB: number = 7011
export const RESULTS_RANK_OR: number = 702
export const RESULTS_RANK_2: number = 703
export const RESULTS_OPTION: number = 7031
export const RESULTS_RANK_2_SUB: number = 7032
export const DISC_FILTERS_TITLE: number = 704
export const SCREEN_FILTERS_TITLE: number = 705
export const MEDIA_FILTER_TITLE: number = 706
export const CONTACT_NETAFIM: number = 707

export const RESULTS_CARD_TEXT_1: number = 708
export const DISC_FILTER_TEXT: number = 7081
export const SCREEN_FILTER_TEXT: number = 7082
export const MEDIA_FILTER_TEXT: number = 7083
export const RESULTS_CARD_TEXT_2: number = 709
export const RESULTS_CARD_TEXT_3: number = 710
export const RESULTS_CARD_TEXT_4: number = 711
export const RESULTS_CARD_TEXT_5: number = 712
export const RESULTS_CARD_TEXT_6: number = 713
export const RESULTS_CARD_TEXT_7: number = 714
export const RESULTS_CARD_TEXT_8: number = 715
export const RESULTS_CARD_TEXT_9: number = 716
export const RESULTS_CARD_TEXT_10: number = 717
export const RESULTS_CARD_TEXT_11: number = 718
export const RESULTS_CARD_TEXT_12: number = 719

export const ALERT_MAX_PRESSURE: number = 720
export const ALERT_MIN_PRESSURE: number = 721

export const MODEL_TAGLINE_SANDSTORM: number = 722
export const MODEL_TAGLINE_SPINKLIN_APOLLO: number = 723
export const MODEL_TAGLINE_ALPHA: number = 724
export const MODEL_TAGLINE_SCREENGUARD: number = 725

export const MODEL_LINK_SANDSTORM_DESKTOP: number = 726
export const MODEL_LINK_SPINKLIN_DESKTOP: number = 7261
export const MODEL_LINK_APOLLO_DESKTOP: number = 7262
export const MODEL_LINK_ALPHA_DESKTOP: number = 7263
export const MODEL_LINK_SCREENGUARD_DESKTOP: number = 7264
export const MODEL_LINK_SANDSTORM: number = 7265
export const MODEL_LINK_SPINKLIN: number = 7266
export const MODEL_LINK_APOLLO: number = 7267
export const MODEL_LINK_ALPHA: number = 7268
export const MODEL_LINK_SCREENGUARD: number = 7269
export const MODEL_SHEET: number = 727

export const HYDROCYCLON_TITLE: number = 728
export const HYDROCYCLON_TEXT: number = 729
export const SEDIMENTATION_NOTE_TEXT: number = 7291
export const SEDIMENTATION_NOTE_LINK: number = 7292

export const GOOD_TO_KNOW_TITLE: number = 730
export const GOOD_TO_KNOW_TEXT_1: number = 731
export const GOOD_TO_KNOW_TEXT_2: number = 732
export const GOOD_TO_KNOW_TEXT_3: number = 733
export const DISCLAIMER_TITLE: number = 734
export const DISCLAIMER_TEXT: number = 735
export const FOOTER_CONTACT_NETAFIM: number = 737
