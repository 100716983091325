import { Page, Text, View, StyleSheet, Image, Link, Font } from "@react-pdf/renderer";
import font from "../../assets/fonts/Roboto-Regular.ttf"
import fontMedium from "../../assets/fonts/Roboto-Medium.ttf"
import learnMoreIcon from "../../assets/images/icons/learn-more.jpg"
import drawingIcon from "../../assets/images/icons/drawing-icon.jpg"
import pressureAlertIcon from "../../assets/images/icons/pressure-alert.jpg"

Font.register({ family: "Roboto-Regular", src: font });
Font.register({ family: "Roboto-Medium", src: fontMedium });

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Roboto-Regular',
    display: "flex",
    fontSize: "16px",
    margin: "0 0 8px 0 ",
    fontWeight: "bold",
  },
  justifyLeftGreen: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "132px",
    height: "24px",
    border: "1px solid #00aa46",
  },
  technologyTitleGreen: {
    fontFamily: 'Roboto-Regular',
    display: "flex",
    fontSize: "14px",
    color: "#00aa46",
  },
  justifyLeftYellow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "132px",
    height: "24px",
    border: "1px solid #f5be00",
  },
  technologyTitleYellow: {
    fontFamily: 'Roboto-Regular',
    display: "flex",
    fontSize: "14px",
    color: "#f5be00",
  },
  subTitle: {
    fontFamily: 'Roboto-Regular',
    display: "flex",
    fontSize: "12px",
    margin: "8px 0 4px",
  },

  resultCardContainer: {
    margin: " 12px 0",
    flexDirection: "column",
    display: "flex",
  },

  detailsContainer: {
    display: "flex",
    width: "550px",
    flexDirection: "row",
    margin: "8px 0 0",
  },
  leftDetails: {
    display: "flex",
    flexDirection: "column",
  },
  rightDetails: {
    display: "flex",
    flexDirection: "column",
    margin: "0 0 0 35px"
  },

  lineContainer: {
    display: "flex",
    flexDirection: "row",
    margin: " 5px 0",
  },

  text: {
    fontFamily: 'Roboto-Regular',
    display: "flex",
    color: "#2E2E2E",
    fontSize: "12px",
  },
  boldValue: {
    fontFamily: 'Roboto-Medium',
    display: "flex",
    fontWeight: "bold",
    color: "#2E2E2E",
    fontSize: "12px",
  },
  tagLine: {
    display: "flex",
    fontWeight: "bold",
    color: "#2E2E2E",
    fontSize: "12px",
    margin: "12px 0 0",
    fontFamily: 'Roboto-Medium',
  },

  innerLineContainer: {
    display: "flex",
    flexDirection: "row",
    margin: " 5px 20px",
  },

  col: {
    display: "flex",
    flexDirection: "column",
    margin: " 5px 0",
  },
  rightLinkContainer: {
    display: "flex",
    flexDirection: "row",
    marginRight: "16px",
    padding: "0 0 5px 0",
    borderBottom: "1.5px solid #0A7FFF"
  },
  leftLinkContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "0 0 5px 0",
    borderBottom: "1.5px solid #0A7FFF"
  },
  link: {
    fontFamily: 'Roboto-Regular',
    display: "flex",
    color: "#00487A",
    fontSize: "14px",
    textDecoration: "none"
  },
  alertContainer: {
    display: "flex",
    width: "530px",
    flexDirection: "row",
    margin: "8px 0 0",
    alignItems: "center",
  },
});

const ResultPdfCard = ({ resultCardData, titleColor }) => {
  const {
    technologyTitle,
    isContactNetafim,
    title,
    subTitle,
    contactNetafim,
    contactNetafimLink,
    contactNetafimLinkSrc,
    systemFlowRate,
    systemFlowRateValue,
    maxPressure,
    maxPressureValue,
    backFlushingFlowRate,
    isBackFlushingFlowRateMediaFilter,
    backFlushingFlowRateValue,
    backFlushingFlowRateBasalt,
    backFlushingFlowRateBasaltValue,
    backFlushingFlowRateSilica,
    backFlushingFlowRateSilicaValue,
    minPressure,
    minPressureValue,
    tagLine,
    learnMore,
    learnMoreLink,
    drawingMore,
    drawingMoreLink,
    pressureAlert,
  } = resultCardData;
  const myDoc = (
    <View style={styles.resultCardContainer}>
      {isContactNetafim ? (
        technologyTitle &&
        <View>
          <View style={styles[`justifyLeft${titleColor}`]}>
            <Text style={styles[`technologyTitle${titleColor}`]}>{technologyTitle}</Text>
          </View>
          <Text style={styles.subTitle}>{contactNetafim}</Text>
          <View style={styles.detailsContainer}>
            <View style={styles.leftLinkContainer}>
              <Link style={styles.link} src={contactNetafimLinkSrc}>
                {contactNetafimLink}
              </Link>
              <Image style={{ width: "11.7px", height: "16px", background: "blue", marginLeft: "8px" }} src={drawingIcon}></Image>
            </View>
          </View>
        </View>
      ) : (
        <View>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.subTitle}>{subTitle}</Text>
          <View style={styles.detailsContainer}>
            <View style={styles.leftDetails}>
              <View style={styles.lineContainer}>
                <Text style={styles.text}>&bull; {systemFlowRate}</Text>
                <Text style={styles.boldValue}>{systemFlowRateValue}</Text>
              </View>
              <View style={styles.lineContainer}>
                <Text style={styles.text}>&bull; {maxPressure}</Text>
                <Text style={styles.boldValue}>{maxPressureValue}</Text>
              </View>
              {isBackFlushingFlowRateMediaFilter ? (
                <View style={styles.col}>
                  <Text style={styles.text}>&bull; {backFlushingFlowRate}</Text>
                  <View style={styles.innerLineContainer}>
                    <Text style={styles.text}>&bull; {backFlushingFlowRateBasalt}</Text>
                    <Text style={styles.boldValue}>
                      {backFlushingFlowRateBasaltValue}
                    </Text>
                  </View>
                  <View style={styles.innerLineContainer}>
                    <Text style={styles.text}>&bull; {backFlushingFlowRateSilica}</Text>
                    <Text style={styles.boldValue}>
                      {backFlushingFlowRateSilicaValue}
                    </Text>
                  </View>
                </View>
              ) : (
                <View style={styles.lineContainer}>
                  <Text style={styles.text}>&bull; {backFlushingFlowRate}</Text>
                  <Text style={styles.boldValue}>{backFlushingFlowRateValue}</Text>
                </View>
              )}
              <View style={styles.lineContainer}>
                <Text style={styles.text}>&bull; {minPressure}</Text>
                <Text style={styles.boldValue}>{minPressureValue}</Text>
              </View>
            </View>
          </View>
          { pressureAlert &&
            <View style={styles.alertContainer}>
              <Image style={{ width: "18px", height: "17px", background: "blue", marginRight: "4px" }} src={pressureAlertIcon}></Image>
              <Text style={styles.text} >
                {pressureAlert}
              </Text>
            </View>}
          <Text style={styles.tagLine}>{tagLine}</Text>
          <View style={styles.detailsContainer}>
            <View style={styles.rightLinkContainer}>
              <Link style={styles.link} src={learnMoreLink}>
                {learnMore}
              </Link>
              <Image style={{ width: "18px", height: "20px", background: "blue", marginLeft: "8px" }} src={learnMoreIcon}></Image>
            </View>
            <View style={styles.leftLinkContainer}>
              <Link style={styles.link} src={drawingMoreLink}>
                {drawingMore}
              </Link>
              <Image style={{ width: "11.7px", height: "16px", background: "blue", marginLeft: "8px" }} src={drawingIcon}></Image>
            </View>
          </View>
        </View>
      )}
    </View>
  );

  return myDoc;
};

export default ResultPdfCard;
