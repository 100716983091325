import { EDiscFilterModels, EFiltrationTechnologyOptions, EMediaFilterModels, EScreenFilterModels } from "../../enums";

const { SANDSTORM_20, SANDSTORM_24, SANDSTORM_30, SANDSTORM_36, SANDSTORM_48, } = EMediaFilterModels;
const { SPINKLIN_2, SPINKLIN_3, APOLLO_ANGLE, APOLLO_TWINS, ALPHA_DISC_SINGLE_3, ALPHA_DISC_SINGLE_XL_3, ALPHA_DISC_SINGLE_XL_4, ALPHA_DISC_DUAL_XL, ALPHA_DISC_TRIO_XL } = EDiscFilterModels;
const { VERTICAL_2, VERTICAL_3, VERTICAL_3_JUMBO, VERTICAL_4, VERTICAL_4_JUMBO, VERTICAL_6, HORIZONTAL_4, HORIZONTAL_4_JUMBO, HORIZONTAL_6, HORIZONTAL_6_JUMBO, HORIZONTAL_8, HORIZONTAL_10 } = EScreenFilterModels;
const { DISC_FILTERS, MEDIA_FILTER, SCREEN_FILTERS } = EFiltrationTechnologyOptions;

export interface IPressureRange {
    maxPressure:number,
    minBackFlushPressure:number
}

const mediaFiltersUnitModel: { [model:number]: IPressureRange } = 
{
    [SANDSTORM_20]: { maxPressure: 8, minBackFlushPressure: 2 },
    [SANDSTORM_24]: { maxPressure: 8, minBackFlushPressure: 2 },
    [SANDSTORM_30]: { maxPressure: 8, minBackFlushPressure: 2 },
    [SANDSTORM_36]: { maxPressure: 8, minBackFlushPressure: 2 },
    [SANDSTORM_48]: { maxPressure: 8, minBackFlushPressure: 2 },
};

const discFiltersUnitModel: { [model:number]: IPressureRange } = 
{
    [SPINKLIN_2]:              { maxPressure: 10, minBackFlushPressure: 2.8 },
    [SPINKLIN_3]:              { maxPressure: 10, minBackFlushPressure: 2.8 },
    [APOLLO_ANGLE]:            { maxPressure: 10, minBackFlushPressure: 2.1 },
    [APOLLO_TWINS]:            { maxPressure: 10, minBackFlushPressure: 2.1 },
    [ALPHA_DISC_SINGLE_3]:     { maxPressure: 10, minBackFlushPressure: 1.5 },
    [ALPHA_DISC_SINGLE_XL_3]:  { maxPressure: 10, minBackFlushPressure: 1.5 },
    [ALPHA_DISC_SINGLE_XL_4]:  { maxPressure: 10, minBackFlushPressure: 1.5 },
    [ALPHA_DISC_DUAL_XL]:      { maxPressure: 10, minBackFlushPressure: 1.5 },
    [ALPHA_DISC_TRIO_XL]:      { maxPressure: 10, minBackFlushPressure: 1.5 },
};

const screenFiltersUnitModel: { [model:number]: IPressureRange } = 
{
    [VERTICAL_2]:         { maxPressure: 10, minBackFlushPressure: 2 },
    [VERTICAL_3]:         { maxPressure: 10, minBackFlushPressure: 2 },
    [VERTICAL_3_JUMBO]:   { maxPressure: 10, minBackFlushPressure: 2 },
    [VERTICAL_4]:         { maxPressure: 10, minBackFlushPressure: 2 },
    [VERTICAL_4_JUMBO]:   { maxPressure: 10, minBackFlushPressure: 2 },
    [VERTICAL_6]:         { maxPressure: 10, minBackFlushPressure: 2 },
    [HORIZONTAL_4]:       { maxPressure: 10, minBackFlushPressure: 2 },
    [HORIZONTAL_4_JUMBO]: { maxPressure: 10, minBackFlushPressure: 2 },
    [HORIZONTAL_6]:       { maxPressure: 10, minBackFlushPressure: 2 },
    [HORIZONTAL_6_JUMBO]: { maxPressure: 10, minBackFlushPressure: 2 },
    [HORIZONTAL_8]:       { maxPressure: 10, minBackFlushPressure: 2 },
    [HORIZONTAL_10]:      { maxPressure: 10, minBackFlushPressure: 2 },
};

export const systemPressureRanges: { [filtrationTechnologyOptions:number]:{ [model:number]: IPressureRange } } = {    
    [MEDIA_FILTER]:   mediaFiltersUnitModel,
    [DISC_FILTERS]:   discFiltersUnitModel,
    [SCREEN_FILTERS]: screenFiltersUnitModel
}






