import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../assets/styles/components/generic/sidebar.module.scss"
import { RootState } from "../../store";
import { quizStep, nextStep } from "../../store/slices/quizStepsSlice";
import SidebarItem from "./SidebarItem";

const Sidebar = () => {

    const steps: quizStep[] = useSelector((state: RootState) => state.quizStepsReducer.steps);
    const currentStep: number = useSelector((state: RootState) => state.quizStepsReducer.currentStep);
    const { language } = useSelector((state: RootState) => state.settingsReducer);

    return (
        <div className={styles["sidebar"]}>
            {steps.map((item, index) => {
                let itemState: "done" | "current" | "remain" = "current";

                if (index > currentStep) itemState = "remain";
                if (index < currentStep) itemState = "done"

                return !item.hiddenProgress ? (
                    <div key={item.id} className={styles["sidebar-item-container"]}>
                        <SidebarItem state={itemState} title={language[item.titleKey]} />
                        {index < steps.length - 1 && <span className={styles["dash-line"]}></span>}
                    </div>
                ) : <div key={item.id}></div>
            })}
        </div>
    )

}

export default Sidebar;